import InterventionShow from "./InterventionShow";
import React from "react";
import {IonButton, IonContent, IonIcon, IonModal} from "@ionic/react";
import {closeOutline, closeSharp} from "ionicons/icons";

interface InterveventionShowModalProps {
    id: string
    isOpen: boolean;
    handleClose(): void;
}

export default function InterventionShowModal({ id, isOpen, handleClose }: InterveventionShowModalProps) {
    return (
        <IonModal isOpen={isOpen} onWillDismiss={handleClose} style={{ '--backdrop-opacity': .4 }} keepContentsMounted={false}>
            <div className="ion-padding" style={{ paddingBottom: 0 }}>
                <IonButton fill="clear" size={"small"} onClick={handleClose}>
                    <IonIcon slot="start" md={closeSharp} ios={closeOutline} />
                    Fermer
                </IonButton>
            </div>

            <InterventionShow id={id} />
        </IonModal>
    )
}