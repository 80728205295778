import {PaginatedResponse} from "../../models/common.model";
import {ListSearchResult} from "../../models/kanban/list.model";
import {CardSearchResult, CardStatus} from "../../models/kanban/card.model";
import {compact, keyBy} from "lodash";
import {useIntl} from "react-intl";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {searchKanbanLists} from "../../calls/Kanban/searchKanbanLists";
import {kanbanKeys} from "./kanbanKeys";
import {IntlFormatters} from "@formatjs/intl/src/types";
import {useMemo} from "react";
import slugify from "slugify";
import DossierSendMairieAlert from "./DossierSendMairieAlert";
import TimeoutBEAlert from "./TimeoutBEAlert";
import {useSearchKanbanCards} from "./useSearchKanbanCards";
import {Intervention} from "../../models/intervention.model";

interface UseBoardDataProps {
    boardId: number;
    cardElementType: string;
    filters: UseBoardDataFilters;
}

interface UseBoardDataFilters {
    startCreateDate?: string; // "2023-05-01 00:00:00",
    endCreateDate?: string; // "2023-05-25 23:59:59"
}

export function useBoardData({ boardId, cardElementType, filters }: UseBoardDataProps) {
    const intl = useIntl();

    const { isLoading: listsAreLoading, isError: listsErrored, data: listsData } = useQuery(['board', boardId, 'lists'], ({queryKey}) => {
        return searchKanbanLists({ boardId });
    }, {
        select(data) {
            return (data as PaginatedResponse<ListSearchResult>).items;
        }
    });

    const queryClient = useQueryClient();

    const { isLoading: cardsAreLoading, isError: cardsErrored, data: cardsData } = useSearchKanbanCards({
        boardId,
        cardElementType,
        filters
    });

    const lists = listsData || [];
    const cards = cardsData || [];

    const lanes = useMemo(() => {
        return lists.map(
            list => {
                const {filters: rawFilters} = list;
                const filteredCards = filterCards(rawFilters, cards);

                const mappedCards = (() => {
                    switch (cardElementType) {
                        case "propal":
                        case "photovoltaique":
                        case "compta":
                            return mapProposalCards(filteredCards, intl.formatNumber);

                        case "interventionOfSav":
                            return mapInterventionOfSavCards(filteredCards);
                    }
                })();

                const id = slugify(list.title, {
                    replacement: '_',  // replace spaces with replacement character, defaults to `-`
                    strict: true,     // strip special characters except replacement, defaults to `false`
                }).toUpperCase();

                return {
                    id,
                    title: list.title,
                    label: mappedCards!.length,
                    cards: mappedCards,
                }
            }
        )
    }, [lists, cards]);

    const listConfigRecord = useMemo(() => {
        const values = lists.map(l => {
            const id = slugify(l.title, {
                replacement: '_',  // replace spaces with replacement character, defaults to `-`
                strict: true,     // strip special characters except replacement, defaults to `false`
            }).toUpperCase();

            return ({
                id,
                readOnly: l.readOnly,
                color: l.color,
            })
        });

        return keyBy(values,'id');
    },[lists, cards]);

    return {
        isLoading: listsAreLoading || cardsAreLoading,
        isError: listsErrored || cardsErrored,
        listConfigRecord,
        data: {
            lanes,
        },
        refresh() {
            queryClient.invalidateQueries({ queryKey: kanbanKeys.boardCards(boardId, cardElementType, filters) })
        }
    }
}

const geFilterPredicate = (rawFilters: string | null): (c: CardSearchResult) => boolean => {
    if (!rawFilters) {
        return (c) => c.status === null || c.status === CardStatus.WAITING_VT;
    }

    const conditions =  rawFilters.split('|').map(subFilters => subFilters.split(':'));
    const isPvFilters = conditions.length > 1; // seul le tableau PV contient la notation en Pipe |

    if (isPvFilters) {
        const [statusFilter, interventionStatusFilter] = conditions;

        // Le pipe est considéré comme un ET pour le moment
        return (c) =>
            c.status === statusFilter[2]
            && (c as any).interventionStatus === interventionStatusFilter[2]
        ;
    }

    const [field, operator, value] = rawFilters.split(':');

    if (operator === '=') {
        return (c) => c[field as 'status'] === value;
    }

    if (operator === 'in') {
        return (c) => value.split(',').indexOf(c[field as 'status']) !== -1
    }

    return (c) => c.status === null;
}

const filterCards = (rawFilters: string | null, cards: any[]) => {
    const predicate = geFilterPredicate(rawFilters);
    return cards.filter(predicate);
}

const mapProposalCards = (cards: CardSearchResult[], formatNumber: IntlFormatters["formatNumber"]) => {
    return cards.map(
        item => {
            const { id, customerName, customerAddress, customerZip, customerTown, productTypology, status, statusUpdatedAt } = item;

            const bg = bgColorByProductTypology[productTypology];

            const mapped = {
                id: item.id,
                title: item.title,
                description: compact([customerName, customerAddress, compact([customerZip, customerTown]).join(', ')]).join('\n'),
                metadata: {
                    proposalId: item.id,
                    productTypology,
                },
                label: `${formatNumber(Number(item.totalTTC), { currency: 'EUR', style: "currency" })} TTC`,
                alert: ({
                    TIMEOUT_BE: TimeoutBEAlert,
                    CASE_SENT_MAIRIE: DossierSendMairieAlert,
                } as any)[status],
                alertProps: {
                    cardId: id,
                    status,
                    statusUpdatedAt,
                },
                error: status === CardStatus.TIMEOUT_BE,
                tags: [
                    {
                        bgcolor: bg || '#F0F0F0',
                        color: bg ? 'white' : 'black',
                        title: productTypologyLabel[productTypology] || 'N/A'
                    },
                ]
            };

            if (item.foireId) {
                mapped.tags.push({
                    bgcolor: '#0079BF',
                    color: 'white',
                    title: item.foire,
                })
            }

            return mapped;
        }
    )
}

const mapInterventionOfSavCards = (cards: Intervention[]) => {
    return cards.map(
        item => {
            const {
                id,
                customer: { name: customerName, id: customerId },
                proposal: { id: proposalId, originId, origin, productTypology },
                place: { address: customerAddress, zipCode: customerZip, town: customerTown },
                status,
                notes,
            } = item;

            const bg = bgColorByProductTypology[productTypology!];

            const mapped = {
                id: item.id,
                title: customerName,
                notes,
                description: compact([customerName, customerAddress, compact([customerZip, customerTown]).join(', ')]).join('\n'),
                metadata: {
                    proposalId,
                },
                tags: [
                    {
                        bgcolor: bg || '#F0F0F0',
                        color: bg ? 'white' : 'black',
                        title: productTypologyLabel[productTypology!] || 'N/A'
                    },
                ]
            };

            // if (originId) {
            //     mapped.tags.push({
            //         bgcolor: '#0079BF',
            //         color: 'white',
            //         title: originId || '',
            //     })
            // }

            return mapped;
        }
    )
}

const bgColorByProductTypology: Record<string, string> = {
    PAC_AIR_AIR: '#469CDE',
    PAC_AIR_EAU: '#D32D7C',
    PHOTOVOLTAIC: '#9EC043',
    BALLON_THERMO: '#EBBE40',
    AUTRE_MATERIEL: '#E59735',
}

const productTypologyLabel: Record<string, string> = {
    PAC_AIR_AIR: "Pompe à chaleur R/R",
    PAC_AIR_EAU: "Pompe à chaleur R/O",
    PHOTOVOLTAIC: "Photovoltaïque",
    BALLON_THERMO: "Ballon thermo",
}