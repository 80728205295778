import {useMutation} from "@tanstack/react-query";
import {useRef} from "react";
import IonLoadingButton from "../Common/Lab/IonLoadingButton";
import {IonIcon} from "@ionic/react";
import {linkOutline, linkSharp} from "ionicons/icons";
import * as blobUtils from 'blob-util';
import {uploadDocument, UploadPayload} from "../../calls/Documents/uploadDocument";

interface AddAttachmentButtonProps {
    reference: string;
    modulepart: 'proposal' | 'invoice';
    onUploaded?(): void;
}

export default function AddAttachmentButton({ reference, modulepart, onUploaded }: AddAttachmentButtonProps) {
    const { mutateAsync: upload, isLoading } = useMutation((payload: UploadPayload) => {
        return uploadDocument(payload);
    });

    const fileInputRef = useRef();

    const handleClick = () => {
        (fileInputRef.current as any)?.click();
    };

    const handleFileChange = async (e: any) => {
        const file = e.target.files[0];

        // handle the selected file
        if (!file) {
            return;
        }

        const filename = file.name;
        const filecontent = await blobUtils.blobToBase64String(file);

        await upload({
            ref: reference,
            filename,
            filecontent,
            modulepart,
            overwriteifexists: 0,
        });

        onUploaded && onUploaded();
    };

    return (
        <>
            <IonLoadingButton fill="outline" color={"primary"} onClick={() => handleClick()} loading={isLoading}>
                <IonIcon slot="start" md={linkSharp} ios={linkOutline}/>
                Joindre
            </IonLoadingButton>

            <input
                ref={fileInputRef as any}
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
            />
        </>
    )
}