import {useInterventionsQuery} from "../Intervention/useInterventionsQuery";
import {InterventionsListContext} from "../Intervention/useInterventionsListContext";
import InterventionTable from "../Intervention/InterventionTable";
import {IonCard, IonCardContent, IonCardHeader, IonCardTitle} from "@ionic/react";

export default function InterventionsCard({ id }: { id: string }) {
    const query = useInterventionsQuery({
        customerId: String(id),
        // type: InterventionType.VISITE_TECHNIQUE
    });

    return (
        <IonCard style={{ background: 'white' }}>
            <IonCardHeader>
                <IonCardTitle>Interventions</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                {
                    (query.isLoading) && (
                        <>Chargement ...</>
                    )
                }

                {
                    (query.isError) && (
                        <>Pas de VTs trouvées pour ce tiers</>
                    )
                }

                {
                    (!query.isLoading && !query.isError) && (
                        <InterventionsListContext.Provider value={{ query }}>
                            <InterventionTable sx={{ minHeight: 250 }} showMode={'modal'} />
                        </InterventionsListContext.Provider>
                    )
                }
            </IonCardContent>
        </IonCard>
    )
}