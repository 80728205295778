import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";

type ModulePart = 'proposal' | 'order' | 'invoice' | 'supplier_invoice' | 'shipment' | 'project';

export type UploadPayload = {
    ref: string;
    filename: string; // ex. "mynewfile.txt",
    subdir?: string; // Marcel Pierre (26) ou autre (ex. img/website)
    filecontent: string; // base64
    modulepart: ModulePart;
    overwriteifexists: "0" | "1" | 1 | 0;
}

type Payload = UploadPayload & { fileencoding: "base64" }

export async function uploadDocument(basePayload: UploadPayload) {
    const payload: Payload = {
        ...basePayload,
        fileencoding: "base64",
    }

    const { data } = await http.post<string>(Endpoint.documentUpload, payload);

    return data;
}