import {useMutation} from "@tanstack/react-query";
import {generateReports} from "../../calls/Interventions/generateReports";
import {saveAs} from "file-saver";
import IonLoadingButton from "../Common/Lab/IonLoadingButton";
import {IonIcon} from "@ionic/react";
import {cloudDownloadOutline, cloudDownloadSharp} from "ionicons/icons";
import React from "react";

export function DownloadReportButton({interventionId, reference}: { interventionId: string, reference: string }) {
    const {mutateAsync, isLoading} = useMutation(() => generateReports(interventionId));

    const handleDownload = async () => {
        const blob = await mutateAsync();
        saveAs(blob, `Rapport VT ${reference}`);
    }

    return (
        <IonLoadingButton
            loading={isLoading}
            fill="solid"
            color="primary"
            onClick={handleDownload}
        >
            <IonIcon slot="start" ios={cloudDownloadOutline} md={cloudDownloadSharp}></IonIcon>
            Rapport VT
        </IonLoadingButton>
    )
}