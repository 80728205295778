import {FormProvider, useForm} from "react-hook-form";
import PaymentForm from "./PaymentForm";
import React, {useEffect, useRef} from "react";
import {IonButton, IonCol, IonContent, IonHeader, IonModal, IonRow, IonTitle, IonToolbar} from "@ionic/react";
import {PaymentMethod} from "../../models/payment.model";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {addPayment, AddPaymentDto} from "../../calls/Payments/addPayment";
import IonLoadingButton from "../Common/Lab/IonLoadingButton";
import currency from "currency.js";
import {DateTime} from "luxon";

interface AddPaymentModel {
    amount: number;
    datepaye: string;
    paymentid: PaymentMethod;
    accountid?: number;
    num_payment?: string;
    comment?: string;
    chqemetteur?: string;
    chqbank?: string;
}

export default function PaymentFormModal({ invoiceId, remainToPay }: { invoiceId: string, remainToPay: string }) {
    const methods = useForm<AddPaymentModel>({
        defaultValues: {
            datepaye: DateTime.now().toISO(),
            amount: 0,
        }
    });

    useEffect(() =>  {
        methods.setValue('amount', +remainToPay);
    }, [remainToPay]);

    const modal = useRef<HTMLIonModalElement>(null);

    const { mutateAsync, isLoading, isError, error } = useMutation((dto: AddPaymentDto) => {
        return addPayment(dto);
    });

    const dismiss = () => {
        methods.reset();
        modal.current?.dismiss();
    }

    const onSubmit = async ({ amount: baseAmount, datepaye: baseDatePayee, ...data }: AddPaymentModel) => {
        // Convert amount
        const amount = currency(baseAmount).toString();

        // convert date
        const datepaye = DateTime.fromISO(baseDatePayee).toSeconds();

        const dto: AddPaymentDto = {
            datepaye: Math.floor(datepaye),
            arrayofamounts: {
                [invoiceId]: {
                    amount,
                    multicurrency_amount: ""
                }
            },
            ...data,
            accountid: 1,
            closepaidinvoices: 'yes'
        }

        const id = await mutateAsync(dto);

        modal.current?.dismiss(id, 'confirm');

        methods.reset();
    }

    const qc = useQueryClient();

    return (
        <IonModal ref={modal} backdropDismiss={false} keepContentsMounted={false} trigger="open-modal" onWillDismiss={async () => {
            await qc.invalidateQueries({ queryKey: ['invoice', invoiceId] });
            await qc.invalidateQueries({ queryKey: ['invoice', invoiceId, 'payments'] });
        }} style={{ '--width': '30%', '--min-height': 400, '--backdrop-opacity': .4 }}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Saisir règlement</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <FormProvider {...methods}>
                    <PaymentForm />
                </FormProvider>
            </IonContent>

            <IonRow className={"ion-margin-top"}>
                <IonCol>
                    <IonButton expand="block" color="light" onClick={dismiss}>
                        Annuler
                    </IonButton>
                </IonCol>

                <IonCol>
                    <IonLoadingButton
                        expand="block"
                        loading={isLoading}
                        onClick={methods.handleSubmit(onSubmit)}
                    >
                        Enregistrer
                    </IonLoadingButton>
                </IonCol>
            </IonRow>
        </IonModal>
    );
}
