import React, {createContext, useContext} from "react";
import {Paper} from "@mui/material";
import {TreeItem, TreeView} from "@mui/lab";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {useProductCategoriesQuery} from "./useProductCategoriesQuery";
import {sortBy} from "lodash";

type ProductCategoryPaperProps = {
    onCategorySelected?(categoryId: string): void;
    onReset?(): void;
}

const PaperContext = createContext<ProductCategoryPaperProps>({});

function usePaperContext() {
    return useContext(PaperContext);
}

export default function ProductCategoryPaper(props: ProductCategoryPaperProps) {
    const { data: categories, isLoading, isError } = useProductCategoriesQuery();

    if (isLoading) {
        return <></>;
    }

    const rawNodes: TreeNode[] = categories!.map(c => ({
        id: c.id,
        label: c.label,
        parentId: c.fk_parent,
        items: [],
    }));

    const sortedNodes: TreeNode[] = sortBy(rawNodes, 'label');

    const nodes = buildTreeView(sortedNodes, 0);

    return (
        <PaperContext.Provider value={props}>
            <Paper sx={{ minHeight: '85vh', background: 'white', padding: 2  }}>
                <TreeView
                    aria-label="product category navigator"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                >
                    {nodes.map(renderTree)}
                </TreeView>
            </Paper>
        </PaperContext.Provider>
    )
}

const renderTree = (tree: TreeNode) => {
    return (
        <CategoryTreeItem key={tree.id} node={tree} />
    )
}

function CategoryTreeItem({ node: tree }: { node: TreeNode }) {
    const { onCategorySelected: cb } = usePaperContext();

    return (
        <TreeItem
            key={tree.id}
            nodeId={tree.id}
            label={tree.label}
            onClick={() => cb && cb(tree.id)}
        >
            {tree.items && tree.items.map(renderTree)}
        </TreeItem>
    )
}

interface TreeNode {
    id: string;
    label: string;
    parentId: number;
    items: TreeNode[];
}

/**
 * Basé sur https://www.ibenic.com/using-a-recursive-function-to-format-flat-arrays/
 * et un peu https://stackoverflow.com/questions/2572721/recursive-treeview-in-asp-net
 * @param nodes
 * @param parentId
 */
function buildTreeView(nodes: TreeNode[], parentId = 0 ) {
    const branch: TreeNode[] = [];
    for (const node of nodes) {
        if (node.parentId === parentId) {
            const items = buildTreeView(nodes, parseInt(node.id));

            if (items) {
                node.items = [];

                for (const item of items) {
                    node.items.push(item);
                }
            }

            branch.push(node);
        }
    }

    return branch;
}