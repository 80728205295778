import {IonCard, IonCardContent, IonCardHeader, IonCardTitle} from "@ionic/react";
import AffaireTable from "../Affaire/AffaireTable";
import {AffaireListContext} from "../Affaire/useAffaireListContext";
import {useAffaireQuery} from "../Affaire/useAffaireQuery";
import React from "react";
import {useQueryClient} from "@tanstack/react-query";


export default function AffaireCard({ id }: { id: string }) {
    const query = useAffaireQuery({ thirdparty_ids: id }, { page: 0, limit: 10, sortorder: 'DESC' }, true);

    // Get QueryClient from the context
    const qc = useQueryClient();

    const refetchIntervention = () => {
        qc.invalidateQueries({ queryKey: ['interventions', { payload: { customerId: String(id), } }] });
    }

    return (
        <IonCard style={{ background: 'white' }}>
            <IonCardHeader>
                <IonCardTitle>Affaires</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                {
                    (query.isLoading) && (
                        <>Chargement ...</>
                    )
                }

                {
                    (query.isError) && (
                        <>Pas d'affaires trouvés pour ce tiers</>
                    )
                }

                {
                    (!query.isLoading && !query.isError) && (
                        <AffaireListContext.Provider value={{ query }}>
                            <AffaireTable
                                prependInterventionCol={true}
                                minHeight={250}
                                showMode={'modal'}
                                onInterventionScheduled={() => refetchIntervention()}
                            />
                        </AffaireListContext.Provider>
                    )
                }
            </IonCardContent>
        </IonCard>
    )
}