import {useMutation} from "@tanstack/react-query";
import {InterventionType} from "../../models/intervention.model";
import {CreateVisiteTechinqueDto, createVisiteTechnique} from "../../calls/Interventions/VT/createVisiteTechnique";
import {createPose, CreatePoseDto} from "../../calls/Interventions/Pose/createPose";
import {createSav} from "../../calls/Interventions/Sav/createSav";
import {createRdvCom} from "../../calls/Interventions/RdvCom/createRdvCom";
import {createEntretien} from "../../calls/Interventions/Entretien/createEntretien";

export function useCreateNewIntervention(type: InterventionType) {
    const handler = getHandlerByType(type);

    return useMutation((dto: CreateVisiteTechinqueDto & CreatePoseDto) => {
        return handler(dto);
    });
}

function getHandlerByType(type: InterventionType) {
    switch (type) {
        case InterventionType.VISITE_TECHNIQUE:
            return createVisiteTechnique;

        case InterventionType.POSE:
            return createPose;


        case InterventionType.SAV:
            return createSav;

        case InterventionType.ENTRETIEN:
            return createEntretien;

        case InterventionType.RDVCOM:
            return createRdvCom;

        default:
            throw Error(`Oups no handler found for intervention type ${type}`)
    }
}