import {useQuery} from "@tanstack/react-query";
import {searchKanbanCards} from "../../calls/Kanban/searchKanbanCards";
import {PaginatedResponse, PaginationResultModel} from "../../models/common.model";
import {Intervention, InterventionType} from "../../models/intervention.model";
import {CardSearchResult} from "../../models/kanban/card.model";
import {kanbanKeys} from "./kanbanKeys";
import {searchInterventions} from "../../calls/Interventions/searchInterventions";
import {CardElementType} from "./types";
import {searchPhotovoltaiqueKanbanCards} from "../../calls/Kanban/searchPhotovolataiqueKanbanCards";
import {searchComptaKanbanCards} from "../../calls/Kanban/searchComptaKanbanCards";

interface UseSearchKanbanCardsProps {
    boardId: number;
    cardElementType: string;
    filters: {
        startCreateDate?: string; // "2023-05-01 00:00:00",
        endCreateDate?: string; // "2023-05-25 23:59:59"
    }
}

export function useSearchKanbanCards({ boardId, cardElementType, filters }: UseSearchKanbanCardsProps) {
    const selector = getCardSelector(cardElementType);
    const queryFn = getQueryFn(cardElementType as 'propal');

    return useQuery(kanbanKeys.boardCards(boardId, cardElementType, filters), () => {
        return queryFn(filters) as any;
    }, {
        select: selector as any,
    });
}

function getCardSelector(cardElementType: string) {
    switch (cardElementType) {
        case "propal":
            return (data: PaginatedResponse<CardSearchResult>) => {
                return data.items;
            }

        case "interventionOfSav":
            return (data: PaginationResultModel<Intervention>) => {
                return data.docs;
            };

        default:
            return (data: PaginatedResponse<CardSearchResult>) => {
                return data.items;
            }
    }
}

function getQueryFn(cardElementType: CardElementType) {
    switch (cardElementType) {
        case "propal":
            return searchKanbanCards;

        case "interventionOfSav":
            return (filters: UseSearchKanbanCardsProps["filters"]) => searchInterventions({
                ...filters,
                type: InterventionType.SAV,
            });

        case "photovoltaique":
            return searchPhotovoltaiqueKanbanCards;

        case "compta":
            return searchComptaKanbanCards;

        default:
            return searchKanbanCards;
    }
}