import React, {useState} from 'react';
import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonChip,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonSkeletonText,
    IonToolbar
} from "@ionic/react";
import styled from '@emotion/styled';
import {useQuery} from "@tanstack/react-query";
import {getLastModifiedProposals} from "../../calls/Stats/getLastModifiedProposals";
import {ProposalStatus, proposalUI} from "../../models/proposal.model";
import {getLastModifiedInterventions} from "../../calls/Stats/getLastModifiedInterventions";
import {interventionUI} from "../../models/intervention.model";
import {DateTime} from "luxon";
import {getProposalStats} from "../../calls/Stats/getProposalStats";
import {useInvoiceFilterForm} from "../Invoices/useInvoiceFilterForm";
import {useSearchInvoicesQuery} from "../Invoices/useSearchInvoicesQuery";
import {idateTime} from "../../utils";
import {FormProvider} from 'react-hook-form';
import {useOpenFilterDrawer} from "../Common/FilterDrawer/store";
import {filterOutline, filterSharp} from "ionicons/icons";
import InvoicesCountRow from "../Invoices/InvoicesCountRow";
import FilterDrawer from "../Common/FilterDrawer";
import SearchInvoice from "../Invoices/SearchInvoice";
import PaymentList from "../Payment/PaymentList";
import InvoicesStats from "../Reports/InvoicesStats";

export default function DashboardPage () {
    return (
        <>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonItem lines={"none"}>
                            <IonLabel>
                                <h1>Dashboard</h1>
                            </IonLabel>
                        </IonItem>
                    </IonToolbar>
                </IonHeader>

                <InvoiceCountWrapper />

                <PaymentList />

                <InvoicesStats />
            </IonContent>
        </>
    );
};

function InvoiceCountWrapper() {
    const { filters: { startDate, endDate, ...filters }, applyFilters, resetFilters, methods } = useInvoiceFilterForm();

    const period = {
        ...(startDate && {
            startDate: DateTime.fromISO(startDate),
        }),
        ...(endDate && {
            endDate: DateTime.fromISO(endDate),
        })
    }

    const query = useSearchInvoicesQuery({
        ...filters,
        ...(period.startDate && {
            startDate: idateTime(period.startDate),
        }),
        ...(period.endDate && {
            endDate: idateTime(period.endDate),
        })
    }, { page: 0, limit: 500 });

    const totalCount = query?.data?.pagination?.itemsCount || 0;
    const totalHT = query?.data?.totalAmountHT || 0;
    const totalTTC = query?.data?.totalAmountTTC || 0;
    const totalPaid = query?.data?.totalPaid || 0;
    const totalRemainToPay = query?.data?.totalRemainToPay || 0;

    return (
        <FormProvider {...methods}>
            <IonToolbar>
                <IonItem lines={"none"}>
                    <IonLabel>
                        <h1>Factures</h1>
                        <h3>
                            Liste des factures sur la période du {period.startDate ? period.startDate?.toFormat('dd/MM/yyyy') : '-'} au du {period.endDate ? period.endDate?.toFormat('dd/MM/yyyy') : '-'}
                        </h3>
                    </IonLabel>
                    <IonButtons slot={"end"}>
                        <FiltersButton/>
                    </IonButtons>
                </IonItem>
            </IonToolbar>

            <InvoicesCountRow
                totalCount={totalCount}
                totalHT={totalHT}
                totalTTC={totalTTC}
                totalPaid={totalPaid}
                totalRemainToPay={totalRemainToPay}
            />

            <FilterDrawer
                onApply={() => {
                    // resetPage();
                    applyFilters()
                }}
                onReset={() => resetFilters()}
            >
                <SearchInvoice/>
            </FilterDrawer>
        </FormProvider>
    )
}

function FiltersButton() {
    const openFilterDrawer = useOpenFilterDrawer();

    return (
        <IonButton fill="outline" color={"primary"} size={"default"} onClick={() => openFilterDrawer()}>
            <IonIcon slot="start" md={filterSharp} ios={filterOutline}/>
            Filtres
        </IonButton>
    )
}

const WhiteCard = styled(IonCard)`
    --background: white;

    & ion-list {
        background: white;
    }
    
    & ion-item {
        --background: white;
    }
    
    & ion-skeleton-text {
        --border-radius: 9999px;
    }
`;

const NoteServiceCardContent = styled(IonCardContent)`
    --background: white;
`;

function ProposalStatsCard() {
    const { data: stats, isLoading } = useQuery(['proposalStats'], getProposalStats);

    const statuses = [
        ProposalStatus.DRAFT,
        ProposalStatus.VALIDATED,
        ProposalStatus.SIGNED,
        ProposalStatus.NOT_SIGNED,
        ProposalStatus.BILLED,
    ];

    return (
        <WhiteCard>
            <IonCardHeader>
                <IonCardSubtitle>Statistiques</IonCardSubtitle>
                <IonCardTitle>Statistiques</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
                <IonList>
                    {
                        isLoading ? (
                            [1, 2, 3, 4, 5].map(
                                (v, index) => (
                                    <IonItem key={'stat-skeleton' + index}>
                                        <IonLabel>
                                            <h2>
                                                <IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>
                                            </h2>
                                        </IonLabel>
                                    </IonItem>
                                )
                            )
                        ) : (
                            statuses.map(
                                (status, index) => {
                                    const label = proposalUI.statusTranslation[status] || 'default';
                                    const statusColor = proposalUI.statusColorMapping[status] || 'default';

                                    return (
                                        <IonItem key={`stats-${index}`} lines={"none"}>
                                            <IonChip slot={"start"} color={statusColor}>{stats![status] || 0}</IonChip>
                                            <IonLabel>{label}</IonLabel>
                                        </IonItem>
                                    )
                                }
                            )
                        )
                    }
                </IonList>
            </IonCardContent>
        </WhiteCard>
    )
}

function LastModifiedProposalsCard() {
    const { data: lastProposalItems, isLoading } = useQuery(['lastModifiedProposals'], getLastModifiedProposals);

    const [max, setMax] = useState(15);
    const items = (lastProposalItems || []).slice(0, max);

    return (
        <WhiteCard>
            <IonCardHeader>
                <IonCardSubtitle>Avancements</IonCardSubtitle>
                <IonCardTitle>Affaires</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
                <IonList>
                    {
                        isLoading ? (
                                [1, 2, 3, 4].map(
                                    (v, index) => (
                                        <IonItem key={'lastproposals-skeleton' + index}>
                                            <IonLabel>
                                                <h3>
                                                    <IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>
                                                </h3>
                                                <p>
                                                    <IonSkeletonText animated={true} style={{ 'width': '60%' }}></IonSkeletonText>
                                                </p>
                                            </IonLabel>
                                        </IonItem>
                                    )
                                )
                        ) : (
                            items.map(
                                (a, index) => {
                                    const status = a.fk_statut as ProposalStatus;
                                    const label = proposalUI.statusTranslation[status] || 'N/A';
                                    const statusColor = proposalUI.statusColorMapping[status] || 'default';

                                    const fromFormat= 'yyyy-MM-dd HH:mm:ss';
                                    const toFormat= 'dd/MM/yyyy HH:mm';
                                    const date = a.createdAt ? DateTime.fromFormat(a.createdAt, fromFormat).toFormat(toFormat) : '-- -- ----';

                                    return (
                                        <IonItem button detail={true} key={`avancements-${index}`} lines={"none"} routerLink={`/page/Affaires/${a.proposalId}`} routerDirection={"forward"}>
                                            <IonLabel>
                                                <h2>{a.customerName}</h2>
                                                <p>Créé le {date}</p>
                                            </IonLabel>
                                            <IonChip slot={"end"} color="primary">
                                                {a.ref}
                                            </IonChip>
                                            <IonChip slot={"end"} color={statusColor}>{label}</IonChip>
                                        </IonItem>
                                    )
                                }
                            )
                        )
                    }
                </IonList>

                <IonButton expand="block" fill="clear" disabled={max > 15} onClick={() => setMax(50)}>
                    Voir tout
                </IonButton>
            </IonCardContent>
        </WhiteCard>
    )
}

function LastModifiedInterventionsCard() {
    const { data: lastInterventionsItems, isLoading } = useQuery(['lastModifiedInterventions'], getLastModifiedInterventions);

    return (
        <WhiteCard>
            <IonCardHeader>
                <IonCardSubtitle>Avancements</IonCardSubtitle>
                <IonCardTitle>Interventions</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
                <IonList>
                    {
                        isLoading ? (
                            [1, 2, 3, 4].map(
                                (v, index) => (
                                    <IonItem key={'lastinterventions-skeleton' + index}>
                                        <IonLabel>
                                            <h3>
                                                <IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>
                                            </h3>
                                            <p>
                                                <IonSkeletonText animated={true} style={{ 'width': '60%' }}></IonSkeletonText>
                                            </p>
                                        </IonLabel>
                                    </IonItem>
                                )
                            )
                        ) : (
                            (lastInterventionsItems || []).map(
                                (i, index) => {
                                    const type = i.type;
                                    const label = interventionUI.typeTranslation[type] || 'default';
                                    const typeColor = interventionUI.typeColorMapping[type] || 'default';

                                    const fromFormat= 'yyyy-MM-dd';
                                    const toFormat= 'dd/MM/yyyy';
                                    const date = i.startDate ? DateTime.fromFormat(i.startDate, fromFormat).toFormat(toFormat) : '-- -- ----';

                                    return (
                                        <IonItem key={`interventions-${index}`} lines={"none"}>
                                            <IonLabel>
                                                <h2>{i.name}</h2>
                                                <p>{date}</p>
                                            </IonLabel>
                                            <IonChip slot={"end"} color={typeColor}>{label}</IonChip>
                                        </IonItem>
                                    )
                                }
                            )
                        )
                    }
                </IonList>
            </IonCardContent>
        </WhiteCard>
    )
}

const statistiques = [
    { value: 24, status: 'Brouillon', statusColor: 'medium' },
    { value: 24, status: 'Validation Technique', statusColor: 'primary' },
    { value: 24, status: 'Validation Mairie', statusColor: 'secondary' },
    { value: 24, status: 'Validation Bureau d\'étude', statusColor: 'tertiary' },
    { value: 24, status: 'Validation Bureau commercial', statusColor: 'success' },
]