import {DateTime} from "luxon";

/**
 * Prend un luxon DateTime et renvoi le retour
 * @return    string Date in a string YYYY-MM-DD HH:MM:SS
 * @param d
 */
export function idateTime(d: DateTime)
{
    return d.toFormat('yyyy-MM-dd HH:mm:ss');
}