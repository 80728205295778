import React, {MouseEventHandler, PropsWithChildren} from "react";
import {JSX} from "@ionic/core/components";
import {IonButton, IonSpinner} from "@ionic/react";

type IonLoadingButtonProps = Pick<JSX.IonButton, "disabled" | "strong" | "color" | "size" | "fill" | "mode" | "expand" | "onIonBlur" | "onIonFocus" | "type" | "routerAnimation" | "href" | "rel" | "target" | "buttonType" | "download" | "shape"> & {
    loading: boolean;
    icon?: React.ReactNode;
    onClick?: MouseEventHandler | undefined;
    style?: React.CSSProperties;
};

/**
 * Custom component pour gérer les états de loading
 * @param loading
 * @param startIcon
 * @param children
 * @param props
 * @constructor
 */
export default function IonLoadingButton({ loading, icon, children, ...props }: PropsWithChildren<IonLoadingButtonProps>) {
    return (
        <IonButton {...props} disabled={loading || props.disabled}>
            {loading ? <IonSpinner slot="start" name="dots" style={{ marginRight: 6 }}></IonSpinner> : icon}
            {children}
        </IonButton>
    )
}
