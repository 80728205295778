import {FormControl, FormLabel} from "@mui/material"
import {Controller} from "react-hook-form"
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

export default function ThirdPartyTypeControl({ name = 'type' }: { name?: string }) {
    return (
        <Controller
            name={"type"}
            defaultValue={2}
            render={
                ({ field: { value, onChange, }, fieldState: { error} }) => {
                    return (
                        <FormControl>
                            <FormLabel id="customer-type">Prospect / Client</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="customer-type"
                                value={value}
                                onChange={onChange}
                            >
                                <FormControlLabel value={2} control={<Radio />} label="Prospect" />
                                <FormControlLabel value={1} control={<Radio />} label="Client" />
                                <FormControlLabel value={0} control={<Radio />} label="Autre" />
                            </RadioGroup>
                        </FormControl>
                    )
                }
            }
        />
    )
}