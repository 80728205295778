import {useMutation, useQuery} from "@tanstack/react-query";
import {listTemplates, TemplateModel} from "../../calls/Templates/listTemplates";
import React, {useMemo, useState} from "react";
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonLabel,
    IonSpinner,
    useIonToast
} from "@ionic/react";
import {Autocomplete, styled, TextField} from "@mui/material";
import {useThirdPartyDetail} from "./useThirdPartyDetail";
import {Proposal} from "../../models/proposal.model";
import {listThirdPartyFiles} from "../../calls/ThirdParties/listThirdPartyFiles";
import {useAffaireImmutableQuery} from "../Affaire/useAffaireImmutableQuery";
import {useProposalDetail} from "../AffaireDetail/useProposalDetail";
import {generateProposalCustomDocument} from "../../calls/Templates/generateProposalCustomDocument";
import {uploadDocumentToEcm} from "../../calls/Documents/uploadDocumentToEcm";
import IonLoadingButton from "../Common/Lab/IonLoadingButton";
import * as blobUtils from "blob-util";

export function TemplatesCard({ thirdPartyId }: { thirdPartyId: string }) {
    const { data: templates, isLoading, error } = useQuery(['customTemplates'], listTemplates, {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 1000 * 60 * 60 * 24,
    });

    const { data: customer } = useThirdPartyDetail(thirdPartyId);
    const subdir = useMemo(() => `${customer!.name} (${customer!.id})`, [customer]); // ex. Marcel Pierre (26)

    // useQuery pour refetch les documents
    const { refetch: refetchDocuments } = useQuery(['3rdParty', thirdPartyId, 'files'], () => listThirdPartyFiles(thirdPartyId), {
        enabled: !!thirdPartyId
    });

    // useAffaireQuery pour avoir un autocomplete avec la liste des proposals du client
    const { data, ...proposalQuery } = useAffaireImmutableQuery({ thirdparty_ids: thirdPartyId }, { page: 0, limit: 10, sortorder: 'DESC' }, !!thirdPartyId);
    const proposals = data?.items || [];
    const isProposalsLoading = proposalQuery.isLoading && proposalQuery.isFetching;

    const [selectedTemplate, setSelectedTemplate] = useState<TemplateModel | null>(null);
    const [selectedProposal, setSelectedProposal] = useState<Proposal | null>(null);

    if (isLoading) {
        return (
            <>
                <IonLabel>
                    Chargement des modèles de documents. Veuillez patienter.
                </IonLabel>
                <IonSpinner name="dots" />
            </>
        )
    }

    return (
        <StyledIonCard>
            <IonCardHeader>
                <IonCardTitle>Modèle documents</IonCardTitle>
                <IonCardSubtitle>Courriers client et autres documents</IonCardSubtitle>
            </IonCardHeader>

            <IonCardContent>
                <Autocomplete
                    sx={{
                        mt: .7
                    }}
                    onChange={(event: any, value: any) => setSelectedProposal(value)}
                    options = {proposals || []}
                    getOptionLabel={(o: any) => o.ref}
                    noOptionsText={"Aucune affaire"}
                    loading={isProposalsLoading}
                    renderInput = {(params) => {
                        return(
                            <TextField {...params} placeholder="Sélectionner une affaire" size="small" variant="outlined" />
                        )
                    }}
                />

                <Autocomplete
                    sx={{
                        mt: .7
                    }}
                    onChange={(event: any, value: any) => setSelectedTemplate(value)}
                    options = {templates || []}
                    getOptionLabel={(o: any) => o.name}
                    noOptionsText={"Aucun document"}
                    loading={isLoading}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.name}
                            </li>
                        );
                    }}
                    renderInput = {(params) => {
                        return(
                            <TextField {...params} placeholder="Sélectionner modèle" size="small" variant="outlined" />
                        )
                    }}
                />
            </IonCardContent>

            <GenerateButton
                proposalId={selectedProposal?.id || ''}
                selectedTemplateId={selectedTemplate?.id || ''}
                templateName={selectedTemplate?.name || ''}
                subdir={subdir}
                onGenerated={() => refetchDocuments()}
            />
        </StyledIonCard>
    )
}

interface GenerateButtonProps {
    proposalId: string;
    selectedTemplateId: string;
    templateName: string;

    subdir: string;

    onGenerated(): void;
}

function GenerateButton({ proposalId, selectedTemplateId, templateName, subdir, onGenerated }: GenerateButtonProps) {
    const { isLoading: proposalLoading, data: proposal, isFetching } = useProposalDetail(proposalId);
    const { mutateAsync: generate, isLoading: generating } = useMutation((documentId: string) => generateProposalCustomDocument(proposalId, documentId));

    const isDisabled = !proposalId || !selectedTemplateId || (proposalLoading && isFetching);

    const [present] = useIonToast();

    const handleGenerate = async () => {
        if (!selectedTemplateId) {
            return;
        }

        try {
            const blob = await generate(selectedTemplateId);

            // On success du download en base64 on upload le contenu et on est bon
            const filename = `${proposal!.ref} ${templateName}`.trim();

            const filecontent = await blobUtils.blobToBase64String(blob);

            await uploadDocumentToEcm({
                filename,
                subdir,
                filecontent,
                overwriteifexists: 1, // On overwrite !
            });

            present({
                message: 'Document généré avec succès.',
                duration: 1500,
                position: 'bottom'
            });

            onGenerated();
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <IonLoadingButton
            fill="clear"
            expand={"block"}
            disabled={isDisabled}
            loading={generating}
            onClick={() => handleGenerate()}
        >
            Générer document
        </IonLoadingButton>
    )
}

const StyledIonCard = styled(IonCard)`
    --background: white;

    & ion-item, & ion-list {
        background: white;
        --background: white;
    }
`;