import {IonButton, IonButtons, IonIcon, IonItem, IonLabel, IonToolbar} from "@ionic/react";
import {addOutline, addSharp, filterOutline, filterSharp} from "ionicons/icons";
import AffaireTable from "./AffaireTable";
import {AffaireListContext} from "./useAffaireListContext";
import {useAffaireQuery} from "./useAffaireQuery";
import {useAffaireFilterForm} from "./useAffaireFilterForm";
import { FormProvider } from "react-hook-form";
import FilterDrawer from "../Common/FilterDrawer";
import SearchAffaire from "./SearchAffaire";
import {useOpenFilterDrawer} from "../Common/FilterDrawer/store";
import {usePowerTablePaginationHelper} from "../Common/Table/usePowerTablePaginationHelper";
import { Paper } from "@mui/material";
import PowerTablePagination from "../Common/Table/PowerTablePagination";
import React from "react";

export default function AffaireList() {
    const { filters, applyFilters, resetFilters, methods } = useAffaireFilterForm();
    const { page, rowsPerPage, onPageChange, onRowsPerPageChange, resetPage } = usePowerTablePaginationHelper();

    const query = useAffaireQuery(filters, { page, limit: rowsPerPage }, !!filters);

    const itemsCount = query?.data?.pagination?.itemsCount || 0;

    const contextValue = {
        query,
    };

    return (
        <FormProvider {...methods}>
            <AffaireListContext.Provider value={contextValue}>
                <IonToolbar>
                    <IonItem lines={"none"}>
                        <IonLabel>
                            <h1>Affaires</h1>
                            <h3>Consulter les devis et commandes client</h3>
                        </IonLabel>

                        <IonButtons slot={"end"}>
                            <FiltersButton />
                            <NewButton />
                        </IonButtons>
                    </IonItem>
                </IonToolbar>

                <Paper sx={{ width: '100%', mt: 1 }} elevation={2}>
                    <AffaireTable />

                    <PowerTablePagination
                        page={page}
                        rowsPerPage={rowsPerPage}
                        totalRowsCount={itemsCount}
                        onPageChange={onPageChange}
                        onRowsPerPageChange={onRowsPerPageChange}
                    />
                </Paper>

                <FilterDrawer
                    loading={query?.isLoading && query?.isFetching}
                    onApply={() => {
                        resetPage();
                        applyFilters();
                    }}
                    onReset={() => resetFilters()}
                >
                    <SearchAffaire />
                </FilterDrawer>
            </AffaireListContext.Provider>
        </FormProvider>
    )
}

function NewButton() {
    return (
        <IonButton fill={"solid"} color={"primary"} size={"default"} routerLink={"/page/NewContract"} routerDirection={"root"}>
            <IonIcon slot="start" md={addSharp} ios={addOutline} />
            Nouvelle
        </IonButton>
    )
}

function FiltersButton() {
    const openFilterDrawer = useOpenFilterDrawer();

    return (
        <IonButton fill="outline" color={"primary"} size={"default"} onClick={() => openFilterDrawer()}>
            <IonIcon slot="start" md={filterSharp} ios={filterOutline}/>
            Filtres
        </IonButton>
    )
}