// https://dribbble.com/shots/20174752-Kirrivan-CRM-Lead-Details
// https://dribbble.com/shots/20128537-Kirrivan-CRM-Lead-Preview

import {useThirdPartyDetail} from "./useThirdPartyDetail";
import {IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonRow} from "@ionic/react";
import InterventionsCard from "./InterventionsCard";
import PaymentCard from "./PaymentCard";
import Page from "../../pages/Page";
import ThirdPartyItem from "./ThirdPartyItem";
import AffaireCard from "./AffaireCard";
import DocumentCard from "./DocumentCard";
import {TemplatesCard} from "./TemplatesCard";
import ThirdPartyContactItem from "./ThirdPartyContactItem";

export default function ThirdPartyShow({ id }: { id: string }) {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    /// let { id } = useParams<{ id: string }>();

    const { isError, isFetched, isLoading, data: thirdParty } = useThirdPartyDetail(id);

    if (isError) {
        return <>Error</>;
    }

    if (isLoading && !isError) {
        return <></>;
    }

    return (
        <Page>
            <IonContent className="ion-padding">
                <IonRow>
                    <IonCol size={'8'}>
                        {/*<IonToolbar>*/}
                        {/*    <IonButtons slot="start">*/}
                        {/*        <IonBackButton />*/}
                        {/*    </IonButtons>*/}
                        {/*</IonToolbar>*/}

                        <IonCard style={{ background: 'white' }}>
                            <IonCardContent>
                                <IonGrid style={{ padding: 0 }}>
                                    <IonRow>
                                        <IonCol>
                                            <ThirdPartyItem id={id} />
                                        </IonCol>
                                        <IonCol>
                                            <ThirdPartyContactItem id={id} />
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonCardContent>
                        </IonCard>

                        <AffaireCard id={id} />

                        <InterventionsCard id={id} />
                    </IonCol>

                    <IonCol size={'4'}>
                        <PaymentCard id={id} />

                        <TemplatesCard thirdPartyId={id} />

                        <DocumentCard id={id} />
                    </IonCol>
                </IonRow>
            </IonContent>
        </Page>
    )
}

