import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {IntlProvider} from "react-intl";

import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {createTheme, ThemeProvider} from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: '#F19F39',
            contrastText: '#FFFFFF',
        },
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 0,
        }
    }
})

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <IntlProvider locale={'fr'}>
                <QueryClientProvider client={queryClient}>
                    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={'fr'}>
                        <App />
                    </LocalizationProvider>
                </QueryClientProvider>
            </IntlProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
