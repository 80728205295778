import {useQuery} from "@tanstack/react-query";
import {searchAffaire} from "../../calls/Affaire/searchAffaire";
import {Pagination} from "../../calls/types";

export function useAffaireImmutableQuery(payload: any = {}, pagination: Pagination = { sortorder: 'DESC', limit: 500 }, enabled: boolean = false) {
    const queryKey = ['affaire', 'immutable', { payload, pagination }];

    return useQuery(queryKey, () => searchAffaire(payload, pagination), {
        enabled,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 1000 * 60 * 60 * 24,
    });
}