import {interventionHttp} from "../../http";
import {CardStatus} from "../../models/kanban/card.model";
import {Endpoint} from "../endpoint.enum";
import {Intervention} from "../../models/intervention.model";

export async function setInterventionOfSavCardStatus(cardId: string, status: CardStatus) {
    const url = Endpoint.setInterventionOfSavCardStatus
        .replace(':id', cardId)
        .replace(':status', status)
    ;

    const { data } = await interventionHttp.patch<Intervention>(url, {});

    return data;
}