import {Pagination} from "../types";
import {http} from "../../http";
import {PaginatedResponse} from "../../models/common.model";
import {BoardSearchResult} from "../../models/kanban/board.model";
import {Endpoint} from "../endpoint.enum";

export async function listBoardsByConnectedUser(pagination: Pagination = { sortorder: 'DESC', limit: 500 }) {
    const { data } = await http.post<PaginatedResponse<BoardSearchResult>>(Endpoint.listBoardsByConnectedUser, {});

    return data;
}