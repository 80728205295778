import React from "react";
import {useThirdPartyDetail} from "./useThirdPartyDetail";
import {IonItem, IonLabel} from "@ionic/react";

export default function ThirdPartyContactItem({ id }: { id: string }) {
    const { isError, isFetched, isLoading, data: thirdParty } = useThirdPartyDetail(id);

    if (isLoading) {
        return <></>;
    }

    const phone1 = thirdParty?.array_options?.options_personal_mobile_phone || "";
    const phone2 = thirdParty?.array_options?.options_business_mobile_phone || "";

    return (
        <>
            <IonItem lines={"none"} style={{ '--background': 'white' }}>
                <IonLabel>
                    <h2>Informations de contact</h2>
                    <p>
                        Email: {thirdParty?.email || "-"}
                    </p>

                    {
                        thirdParty?.phone && (
                            <p>
                                Tél: {thirdParty?.phone}
                            </p>
                        )
                    }

                    {
                        phone1 && (
                            <p>
                                Téléphone Mr: {phone1}
                            </p>
                        )
                    }

                    {
                        phone2 && (
                            <p>
                                Téléphone Mme: {phone2}
                            </p>
                        )
                    }
                </IonLabel>
            </IonItem>
        </>
    )
}