import {Controller, useFormContext} from "react-hook-form";
import React from "react";
import HybridDatePicker from "../Common/Lab/HybridDatePicker";
import {DateTime} from "luxon";
import {idateTime} from "../../utils";
import UserAutocomplete from "../ThirdParty/UserAutocomplete";
import {useHistory} from "react-router";
import {useCreateNewFoire} from "./useCreateNewFoire";
import {FoireFormModel} from "./type";
import {useUser} from "../Auth/auth.store";
import {Box, FormControl, Stack} from "@mui/material";
import FormDefaultActions from "../Common/Form/FormDefaultActions";
import GenericTextFieldController from "../Common/Form/GenericTextFieldController";
import FormLabel from "@mui/material/FormLabel";


export default function EventForm() {
    const user = useUser();

    const { handleSubmit } = useFormContext<FoireFormModel>();

    const history = useHistory();

    const { mutateAsync, isLoading, isSuccess, isError, data } = useCreateNewFoire();

    const onSubmit = async (data: FoireFormModel) => {
        const { responsables, vendeurs, rabatteurs, vttistes } = data;

        const responsableIds = (responsables || []).map(r => r.id).join(',');
        const vendeurIds = (vendeurs || []).map(v => v.id).join(',');
        const rabatteurIds = (rabatteurs || []).map(r => r.id).join(',');
        const vttisteIds = (vttistes || []).map(r => r.id).join(',');

        const salesForceIds = [
            ...(responsables || []),
            ...(vendeurs || []),
            ...(rabatteurs || []),
            ...(vttistes || []),
        ].map(f => Number(f.id));

        await mutateAsync({
            ...data,
            userownerid: +(user.id),
            userassigned: salesForceIds,
            array_options: {
                responsable_ids: responsableIds,
                vendeur_ids: vendeurIds,
                rabatteur_ids: rabatteurIds,
                vttiste_ids: vttisteIds,
            }
        });

        // redirecton vers la liste des foires
        history.push('/page/Agenda/Foires');
    }

    return(
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box p={2}>
                <Stack direction={"column"} spacing={2}>
                    <GenericTextFieldController
                        name={"label"}
                        label={"Libellé"}
                        rules={{ required: 'Libellé foire obligatoire' }}
                    />

                    <GenericTextFieldController
                        name={"location"}
                        label={"Lieu"}
                    />

                    <Controller
                        name={"datep"}
                        rules={{ required: 'Date début foire obligatoire' }}
                        render={({ field: {  value, onChange}, fieldState: { error } }) => {
                            return (
                                <FormControl variant={"outlined"} fullWidth>
                                    <FormLabel>Date de début</FormLabel>
                                    <HybridDatePicker
                                        value={value || null}
                                        InputProps={{
                                            size: 'small',
                                            error: !!error,
                                            placeholder: 'Date début foire',
                                            sx: { mt: .7 }
                                        }}
                                        onChange={
                                            (v: DateTime | null) => {
                                                onChange(v ? idateTime(v) : null)
                                            }
                                        }
                                    />
                                </FormControl>
                            )
                        }}
                    />

                    <Controller
                        name={"datef"}
                        render={({ field: { value, onChange }, fieldState: { error} }) => {
                            return (
                                <FormControl variant="outlined" fullWidth>
                                    <FormLabel>Date de fin</FormLabel>
                                    <HybridDatePicker
                                        value={value || null}
                                        InputProps={{
                                            size: 'small',
                                            error: !!error,
                                            placeholder: 'Date de fin foire',
                                            sx: { mt: .7 }
                                        }}
                                        onChange={
                                            (v: DateTime | null) => {
                                                onChange(v ? idateTime(v) : null)
                                            }
                                        }
                                    />
                                </FormControl>
                            )
                        }}
                    />

                    <Controller
                        name={"responsables"}
                        render={
                            ({field}) => {
                                return(
                                    <UserAutocomplete
                                        multiple={true}
                                        label={"Responsables"}
                                        placeholder={"Sélectionner responsables"}
                                        onChange={field.onChange}
                                    />
                                )
                            }
                        }
                    />

                    <Controller
                        name={"vendeurs"}
                        render={
                            ({field }) => {
                                return(
                                    <UserAutocomplete
                                        multiple={true}
                                        label={"Vendeurs"}
                                        placeholder={"Sélectionner vendeurs"}
                                        onChange={field.onChange}
                                    />
                                )
                            }
                        }
                    />

                    <Controller
                        name={"rabatteurs"}
                        render={
                            ({field }) => {
                                return(
                                    <UserAutocomplete
                                        multiple={true}
                                        label={"Rabatteurs"}
                                        placeholder={"Sélectionner rabatteurs"}
                                        onChange={field.onChange}
                                    />
                                )
                            }
                        }
                    />

                    <Controller
                        name={"vttistes"}
                        render={
                            ({field }) => {
                                return(
                                    <UserAutocomplete
                                        multiple={true}
                                        label={"VTTistes"}
                                        placeholder={"Sélectionner VTTistes"}
                                        onChange={field.onChange}
                                    />
                                )
                            }
                        }
                    />

                    <GenericTextFieldController
                        name={"note"}
                        label={"Notes"}
                        textFieldProps={{
                            rows: 4,
                            multiline: true,
                            placeholder: "Vos notes ici",
                        }}
                    />
                </Stack>
            </Box>

            <Box p={2} mt={1}>
                <FormDefaultActions
                    saveButtonText="Enregistrer"
                    loading={isLoading}
                    onCancel={() => history.push("/page/Agenda/Foires")}
                />
            </Box>
        </form>
    )
}
