import React from 'react';
import {FormProvider, useForm} from "react-hook-form";
import ProposalLineForm, {ProposalLineFormModel} from "./ProposalLineForm";
import {IonButton, IonCol, IonContent, IonFooter, IonHeader, IonPage, IonRow, IonTitle, IonToolbar} from "@ionic/react";
import {ProposalLine} from "../../models/proposal.model";
import {useMutation} from "@tanstack/react-query";
import {ProposalLineDto} from "../../calls/Proposals/addProposalLine";
import {editProposalLine} from "../../calls/Proposals/editProposalLine";

type LineModalProps = {
    proposalId: string,
    line: ProposalLine,
    onDismiss?(data: ProposalLineFormModel | null, role: 'confirm' | 'cancel'): void,
}

export default function EditProposalLineModal ({ proposalId, line, onDismiss }: LineModalProps) {
    const methods = useForm<ProposalLineFormModel>({
        defaultValues: {
            label: line.label || line.libelle,
            desc: line.desc,
            qty: Number(line.qty || 1),
            subprice: Number(line.subprice || 0),
            remise_percent: 0,
            product_type: '0',
        }
    });

    const { mutateAsync: updateLine, isLoading } = useMutation((dto: ProposalLineDto) => editProposalLine(proposalId, line.id, dto));

    const onSubmit = async (data: ProposalLineFormModel) => {
        try {
            const dto = {
                label: data.label,
                desc: data.desc,
                qty: data.qty,
                subprice: data.subprice,
                remise_percent: data.remise_percent,
                tva_tx: data.tva_tx,
                product_type: data.product_type,
            };

            await updateLine(dto);

            onDismiss && onDismiss(data, "confirm");
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Modifier ligne</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <FormProvider {...methods} >
                    <ProposalLineForm />
                </FormProvider>
            </IonContent>

            <IonFooter>
                <IonRow>
                    <IonCol offset="6">
                        <IonButton
                            color="light"
                            expand="block"
                            onClick={() => onDismiss && onDismiss(null, "cancel")}
                        >
                            Annuler
                        </IonButton>
                    </IonCol>

                    <IonCol>
                        <IonButton
                            expand="block"
                            color="primary"
                            onClick={methods.handleSubmit(onSubmit)}
                        >
                            Enregistrer
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonFooter>
        </IonPage>
    );
};