import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, {useMemo, useState} from "react";
import ThirdPartyAutocomplete from "../ThirdParty/ThirdPartyAutocomplete";
import {ThirdParty} from "../../models/third-party.model";
import {Proposal} from "../../models/proposal.model";
import {Controller, FormProvider, useController, useForm, useFormContext, useWatch} from "react-hook-form";
import {useAffaireQuery} from "../Affaire/useAffaireQuery";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {useIsFetching, useMutation} from "@tanstack/react-query";
import {
    useBuildCreateInterventionBaseDto,
    useBuildCreateInterventionBaseDtoNoThirdParty
} from "./useBuildCreateInterventionBaseDto";
import {InterventionFormModel} from "./InterventionForm";
import {DateTime} from "luxon";
import {InterventionType} from "../../models/intervention.model";
import UserAutocomplete from "../ThirdParty/UserAutocomplete";
import {MenuItem, Select, Stack, TextField} from "@mui/material";
import {IonCheckbox, IonInput, IonItem, IonLabel, IonList, IonListHeader} from "@ionic/react";
import {DatePicker} from "@mui/x-date-pickers";
import {InstructionCode, instructions} from "./instructions";
import {LoadingButton} from "@mui/lab";
import {compact} from "lodash";
import {CreateVisiteTechinqueDto} from "../../calls/Interventions/VT/createVisiteTechnique";
import {CreatePoseDto} from "../../calls/Interventions/Pose/createPose";
import {useCreateNewIntervention} from "./useCreateNewIntervention";
import {generateReports} from "../../calls/Interventions/generateReports";
import {CreateSavDto} from "../../calls/Interventions/Sav/createSav";
import AddressAutocomplete from "../Common/AddressAutocomplete";

interface InterventionWorkflowStepperProps {
    type: InterventionType;
    onCreated?: () => void;
}

interface StepperFormThirdPartyAwareModel extends InterventionFormModel {
    thirdParty: ThirdParty;
    proposal: Proposal;
}

interface StepperFormNoThirdPartyModel extends InterventionFormModel {
    thirdParty: {
        name: string;
        address: AddressOption,
    };
}

export default function InterventionWorkflowStepper({ type, onCreated }: InterventionWorkflowStepperProps) {
    const steps = useSteps(type);

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {
            type,
            date: null,
            theoricalStartDate: DateTime.now().toISO(),
            theoricalEndDate: DateTime.now().plus({ days: 1 }).toISO(),
            duration: 1,
            desiredInterventionDate: DateTime.now().toISO(),
        }
    });

    const buildBaseDto = useBuildCreateInterventionBaseDto();
    const builBaseDto2 = useBuildCreateInterventionBaseDtoNoThirdParty();
    const { mutateAsync, isLoading, isSuccess, isError, data } = useCreateNewIntervention(type);
    const reportGeneration = useMutation((id: string) => generateReports(id));

    // How many queries matching the posts prefix are fetching?
    const isFetchingAffaires = !!useIsFetching({ queryKey: ['affaire'] })

    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const onSubmit = async (data: any) => {
        const { type, thirdParty, proposal, ...restOfData } = data;
        const baseDto = {
            ...(
                type !== InterventionType.RDVCOM
                ? buildBaseDto(thirdParty, proposal)
                : builBaseDto2(thirdParty)
            ),
            technician: restOfData.technician,
            date: restOfData.date,
            notes: restOfData.notes,
            instructions: compact(restOfData.instructions || []),
        };

        let dto = baseDto;

        if (type === InterventionType.VISITE_TECHNIQUE) {
            // Convert duree
            const duration = (data.duration || 0) * 3600; // conversion durée en seconde

            dto = {
                ...baseDto,
                duration,
            } as CreateVisiteTechinqueDto;
        }

        if (type === InterventionType.POSE) {
            dto = {
                ...baseDto,
                theoricalStartDate: data.theoricalStartDate,
                theoricalEndDate: data.theoricalEndDate,
            } as CreatePoseDto;
        }

        if ([InterventionType.ENTRETIEN, InterventionType.SAV].indexOf(type) !== -1) {
            dto = {
                ...baseDto,
                installationYear: restOfData.installationYear,
                poseTechnician: restOfData.poseTechnician,
            } as CreateSavDto;
        }

        const response = await mutateAsync(dto as any);

        if (type === InterventionType.POSE) {
            await reportGeneration.mutateAsync((response as any).id);
        }

        methods.reset();
        onCreated && onCreated();
    };

    return (
        <Box sx={{ p: 2, maxWidth: 600 }}>
            <FormProvider {...methods}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {
                        steps.map(
                            (step, index) => {
                                const { Label, Content } = step;

                                return (
                                    <Step key={`intervention-stepper-key-${index}`}>
                                        <StepLabel>
                                            <Label />
                                        </StepLabel>

                                        <StepContent>
                                            <Content />

                                            <Box sx={{ mb: 2 }}>
                                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ width: "100%" }}>
                                                    {
                                                        index > 0 && (
                                                            <Button
                                                                size={"small"}
                                                                onClick={handleBack}
                                                                sx={{ mt: 1, mr: 1 }}
                                                            >
                                                                Précédent
                                                            </Button>
                                                        )
                                                    }

                                                    {
                                                        index < steps.length - 1 ? (
                                                            <Button
                                                                autoFocus
                                                                variant="contained"
                                                                size={"small"}
                                                                disabled={isFetchingAffaires || !methods.formState.isValid}
                                                                onClick={handleNext}
                                                                sx={{ mt: 1, mr: 1 }}
                                                            >
                                                                Suivant
                                                            </Button>
                                                        ) : (
                                                            <LoadingButton
                                                                loading={isLoading || reportGeneration.isLoading}
                                                                autoFocus
                                                                variant="contained"
                                                                size={"small"}
                                                                disabled={isFetchingAffaires || !methods.formState.isValid}
                                                                onClick={methods.handleSubmit(onSubmit)}
                                                                sx={{ mt: 1, mr: 1 }}
                                                            >
                                                                Programmer
                                                            </LoadingButton>
                                                        )
                                                    }
                                                </Stack>
                                            </Box>
                                        </StepContent>
                                    </Step>
                                )
                            }
                        )
                    }
                </Stepper>
            </FormProvider>
        </Box>
    )
}

interface StepDef {
    Label: () => JSX.Element;
    Content: () => JSX.Element;
}

const CustomerSelection = {
    Label() {
        const t = useWatch({ name: 'thirdParty', defaultValue: null });
        return <>{t ? t.name : "Sélectionner le client"}</>
    },
    Content() {
        return (
            <>
                <ThirdPartyAutocomplete />
            </>
        )
    }
}

interface AddressOption {
    country: string;
    city: string;
    postalCode: string;
    route: string;
    street: string;
    lat: number;
    lng: number;
}

function InterventionAddressAutocomplete() {
    const label = "Adresse";
    const name = 'thirdParty.address';
    const labelId = `${name}-address`;
    const id = `${name}-select`;

    const {
        field: { value, onChange },
    } = useController({
        name,
        defaultValue: null,
        rules: {
            required: true,
        }
    });

    return (
        <FormControl fullWidth>
            <FormLabel htmlFor={id}>{label}</FormLabel>
            <AddressAutocomplete
                onChange={onChange}
            />
        </FormControl>
    )
}

const NewCustomerSelection = {
    Label() {
        return <>Informations RDV</>
    },
    Content() {
        return (
            <Stack spacing={1}>
                <Controller
                    name={"thirdParty.civility"}
                    defaultValue="MME"
                    render={({ field: { value, onChange }, fieldState: { error } }) => {
                        return (
                            <FormControl fullWidth error={!!error}>
                                <FormLabel>Civilité</FormLabel>
                                <Select
                                    labelId="civility-simple-select-label"
                                    id="civility-simple-select"
                                    value={value}
                                    onChange={onChange}
                                    size={"small"}
                                >
                                    <MenuItem value={"MME"}>Madame</MenuItem>
                                    <MenuItem value={"M"}>Monsieur</MenuItem>
                                    <MenuItem value={"MLLE"}>Mademoiselle</MenuItem>
                                </Select>
                            </FormControl>
                        )
                    }}
                />

                <Controller
                    name={"thirdParty.name"}
                    defaultValue=""
                    rules={{
                        required: true
                    }}
                    render={({ field: { value, onChange }, fieldState: { error } }) => {
                        return (
                            <FormControl fullWidth error={!!error}>
                                <FormLabel>Nom du client</FormLabel>
                                <TextField
                                    size="small"
                                    error={!!error}
                                    onChange={onChange}
                                    value={value}
                                    fullWidth
                                    variant="outlined"
                                    placeholder={"Nom du client"}
                                />
                            </FormControl>
                        )
                    }}
                />

                <InterventionAddressAutocomplete />

                <Controller
                    name={"date"}
                    render={({ field: { value, onChange } , fieldState:{error}}) => {
                        return (
                            <FormControl>
                                <FormLabel>Date</FormLabel>
                                <DatePicker
                                    value={value}
                                    onChange={
                                        (v: DateTime | null) => {
                                            onChange(v ? v?.toISO() : null);
                                        }
                                    }
                                    renderInput={(params) => <TextField {...params} size={"small"} />}
                                />
                            </FormControl>
                        )
                    }}
                />

                <Controller
                    name={"technician"}
                    render={
                        ({field}) => {
                            return(
                                <UserAutocomplete
                                    onChange={(t) => field.onChange({ id: t.id, name: t.label })}
                                    label={"Assigner à"}
                                    placeholder={"Assigner à"}
                                />
                            )
                        }
                    }
                />

                <Controller
                    name={"notes"}
                    defaultValue=""
                    render={({ field: { value, onChange }, fieldState: { error } }) => {
                        return (
                            <FormControl fullWidth error={!!error}>
                                <FormLabel>Notes / Commentaires</FormLabel>
                                <TextField
                                    id="notes-multiline-flexible"
                                    placeholder={"Vos notes ou commentaires ici"}
                                    multiline
                                    value={value}
                                    onChange={onChange}
                                    rows={4}
                                    maxRows={6}
                                />
                            </FormControl>
                        )
                    }}
                />
            </Stack>
        )
    }
}

const AffaireSelection = {
    Label() {
        const d = useWatch({ name: 'proposal', defaultValue: null });

        const productLabel = (d?.lines || []).filter((l: any) => l.product_type === '0')[0]?.product_label || '';

        return (
            <>
                {
                    d ? (
                        <>
                            <Typography variant="body2">
                                {d.ref}
                            </Typography>

                            <Typography variant="caption">
                                {productLabel}
                            </Typography>
                        </>
                    ) : (
                        "Sélectionner le dossier"
                    )
                }
            </>
        )
    },
    Content() {
        const { getValues } = useFormContext();
        const thirdParty = getValues().thirdParty;
        const { isLoading, data } = useAffaireQuery({ thirdparty_ids: thirdParty?.id }, { page: 0, limit: 10, sortorder: 'DESC' }, !!thirdParty);

        if (isLoading) {
            return (<>Chargement des affaires du client ...</>)
        }

        const items = data?.items || [];

        return (
            <Controller
                name={"proposal"}
                rules={{
                    required: true
                }}
                defaultValue={null}
                render={({ field: { value, onChange }, fieldState: { error } }) => {
                    return (
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="proposal-radio-buttons-group-label"
                                defaultValue="female"
                                name="proposal-buttons-group"
                            >
                                {
                                    items.map(
                                        i => {
                                            const productLabel = i.lines.filter(l => l.product_type === '0')[0]?.product_label || '';

                                            return (
                                                <FormControlLabel
                                                    key={i.ref}
                                                    checked={i.id === value?.id}
                                                    onChange={
                                                        (e, checked) => onChange(i)
                                                    }
                                                    control={<Radio />}
                                                    sx={{
                                                        mb: 1
                                                    }}
                                                    label={
                                                        <>
                                                            <Typography variant="body2">
                                                                {i.ref}
                                                            </Typography>

                                                            <Typography variant="caption">
                                                                {productLabel}
                                                            </Typography>
                                                        </>
                                                    }
                                                />
                                            )
                                        }
                                    )
                                }
                            </RadioGroup>
                        </FormControl>
                    )
                }}
            />
        )
    }
}

const InterventionCreation = {
    Label() {
        return <>Programmation Intervention</>
    },
    Content() {
        // const types = [
        //     { label: 'Visite Technique', type: InterventionType.VISITE_TECHNIQUE },
        //     { label: 'Pose', type: InterventionType.POSE },
        //     { label: 'SAV', type: InterventionType.SAV },
        //     { label: 'entretien', type: InterventionType.ENTRETIEN },
        // ];

        const type = useWatch({ name: 'type' });

        const { setValue } = useFormContext();

        return (
            <Stack direction={"column"} spacing={1}>
                {/*<Controller*/}
                {/*    name={"type"}*/}
                {/*    defaultValue=""*/}
                {/*    render={({ field: { value, onChange }, fieldState: { error } }) => {*/}
                {/*        return (*/}
                {/*            <FormControl>*/}
                {/*                <FormLabel id="intervention-type-row-radio-buttons-group-label">Type</FormLabel>*/}
                {/*                <RadioGroup*/}
                {/*                    row*/}
                {/*                    aria-labelledby="intervention-type-radio-buttons-group-label"*/}
                {/*                    name="intervention-type-radio-buttons-group"*/}
                {/*                    value={value}*/}
                {/*                    onChange={(e, type) => onChange(type)}*/}
                {/*                >*/}
                {/*                    {*/}
                {/*                        types.map(*/}
                {/*                            t => (*/}
                {/*                                <FormControlLabel*/}
                {/*                                    key={t.label}*/}
                {/*                                    value={t.type}*/}
                {/*                                    control={<Radio />}*/}
                {/*                                    label={t.label}*/}
                {/*                                />*/}
                {/*                            )*/}
                {/*                        )*/}
                {/*                    }*/}
                {/*                </RadioGroup>*/}
                {/*            </FormControl>*/}
                {/*        )*/}
                {/*    }}*/}
                {/*/>*/}

                <Controller
                    name={"technician"}
                    render={
                        ({field}) => {
                            return(
                                <UserAutocomplete
                                    onChange={(t) => {
                                        field.onChange({ id: t.id, name: t.label });
                                        setValue("date", DateTime.now().toISO()); // update de la date on assignation
                                    }}
                                    label={"Assigner à"}
                                    placeholder={"Assigner à"}
                                />
                            )
                        }
                    }
                />

                {
                    type !== InterventionType.POSE && (
                        <Controller
                            name={"date"}
                            render={({ field: { value, onChange } , fieldState:{error}}) => {
                                return (
                                    <FormControl>
                                        <FormLabel>Date</FormLabel>
                                        <DatePicker
                                            value={value}
                                            onChange={
                                                (v: DateTime | null) => {
                                                    onChange(v ? v?.toISO() : null);
                                                }
                                            }
                                            renderInput={(params) => <TextField {...params} size={"small"} />}
                                        />
                                    </FormControl>
                                )
                            }}
                        />
                    )
                }

                {
                    [InterventionType.SAV, InterventionType.ENTRETIEN].indexOf(type) !== -1 && (
                        <>
                            <Controller
                                name={"installationYear"}
                                defaultValue={null}
                                render={({ field: { value, onChange }, fieldState: { error } }) => {
                                    return (
                                        <FormControl fullWidth error={!!error}>
                                            <FormLabel>Année installation</FormLabel>
                                            <TextField
                                                id="installationYear-multiline-flexible"
                                                placeholder={"ex. 2019"}
                                                size={"small"}
                                                value={value}
                                                onChange={onChange}
                                            />
                                        </FormControl>
                                    )
                                }}
                            />

                            <Controller
                                name={"poseTechnician"}
                                render={
                                    ({field}) => {
                                        return(
                                            <UserAutocomplete
                                                onChange={(t) => field.onChange({ id: t.id, name: t.label })}
                                                label={"Poseur"}
                                                placeholder={"Technicien reponsable de la pose"}
                                            />
                                        )
                                    }
                                }
                            />

                            <Controller
                                name={"notes"}
                                defaultValue=""
                                render={({ field: { value, onChange }, fieldState: { error } }) => {
                                    return (
                                        <FormControl fullWidth error={!!error}>
                                            <FormLabel>Notes / Commentaires</FormLabel>
                                            <TextField
                                                id="notes-multiline-flexible"
                                                placeholder={"Vos notes ou commentaires ici"}
                                                multiline
                                                value={value}
                                                onChange={onChange}
                                                rows={4}
                                                maxRows={6}
                                            />
                                        </FormControl>
                                    )
                                }}
                            />
                        </>
                    )
                }

                {
                    type === InterventionType.VISITE_TECHNIQUE && (
                        <>
                            <Controller
                                name={"observations"}
                                render={
                                    ({field, fieldState: { error} }) => {
                                        return (
                                            <FormControl>
                                                <FormLabel>Observations</FormLabel>
                                                <TextField
                                                    multiline
                                                    maxRows={3}
                                                    value={field.value} onChange={field.onChange}
                                                />
                                            </FormControl>
                                        )
                                    }
                                }
                            />

                            <Controller
                                name={"duree"}
                                defaultValue={1}
                                render={
                                    ({field }) => {
                                        return (
                                            <FormControl>
                                                <FormLabel>Durée (h)</FormLabel>
                                                <TextField
                                                    type={"number"}
                                                    size={"small"}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                />
                                            </FormControl>
                                        )
                                    }
                                }
                            />

                            <Controller
                                name={"desiredInterventionDate"}
                                render={({ field: { value, onChange} , fieldState:{error}}) => {
                                    return (
                                        <FormControl>
                                            <FormLabel>Date installation souhaitée</FormLabel>
                                            <DatePicker
                                                value={value}
                                                onChange={
                                                    (v: DateTime | null) => {
                                                        onChange(v ? v?.toISO() : null);
                                                    }
                                                }
                                                renderInput={(params) => <TextField {...params} size={"small"} />}
                                            />
                                        </FormControl>
                                    )
                                }}
                            />
                        </>
                    )
                }

                {
                    type === InterventionType.POSE && (
                        <>
                            <Controller
                                name={"theoricalStartDate"}
                                render={({ field: { value, onChange } , fieldState:{error}}) => {
                                    return (
                                        <>
                                            <FormControl>
                                                <FormLabel>Date début</FormLabel>
                                                <DatePicker
                                                    value={value}
                                                    onChange={
                                                        (v: DateTime | null) => {
                                                            onChange(v ? v?.toISO() : null);
                                                        }
                                                    }
                                                    renderInput={(params) => <TextField {...params} size={"small"} />}
                                                />
                                            </FormControl>
                                        </>
                                    )
                                }}
                            />

                            <Controller
                                name={"theoricalEndDate"}
                                render={({ field: { value, onChange } , fieldState:{error}}) => {
                                    return (
                                        <FormControl>
                                            <FormLabel>Date fin</FormLabel>
                                            <DatePicker
                                                value={value}
                                                onChange={
                                                    (v: DateTime | null) => {
                                                        onChange(v ? v?.toISO() : null);
                                                    }
                                                }
                                                renderInput={(params) => <TextField {...params} size={"small"} />}
                                            />
                                        </FormControl>
                                    )
                                }}
                            />

                            <IonList>
                                <IonListHeader>
                                    <IonLabel>Instructions</IonLabel>
                                </IonListHeader>
                                {
                                    instructions.map(
                                        (i, index) => {
                                            return (
                                                <Controller
                                                    name={`instructions.${index}`}
                                                    render={({ field: { value, onChange } , fieldState:{error}}) => {
                                                        return (
                                                            <IonItem class="ion-text-wrap" key={`instructions.${index}`}>
                                                                <IonCheckbox
                                                                    slot="start"
                                                                    value={value}
                                                                    onIonChange={({ detail: { checked } }) => {
                                                                        onChange(
                                                                            checked ? i : undefined
                                                                        )
                                                                    }}
                                                                />

                                                                <IonLabel>
                                                                    <h6>{i.description}</h6>
                                                                    <p>{i.actionLabel}</p>
                                                                </IonLabel>

                                                                {
                                                                    (value?.code === InstructionCode.INST_CHQ) && (
                                                                        <Controller
                                                                            name={`instructions.${index}.data.solde`}
                                                                            render={({ field: { value, onChange } , fieldState:{error}}) => {
                                                                                return (
                                                                                    <IonInput
                                                                                        placeholder="Saisir le solde ici"
                                                                                        type={"number"}
                                                                                        value={value}
                                                                                        onIonChange={onChange}
                                                                                    />
                                                                                )
                                                                            }}
                                                                        />
                                                                    )
                                                                }
                                                            </IonItem>
                                                        )
                                                    }}
                                                />
                                            )
                                        }
                                    )
                                }
                            </IonList>
                        </>
                    )
                }
            </Stack>
        )
    }
}

const defaultSteps: StepDef[] = [
    CustomerSelection,
    AffaireSelection,
    InterventionCreation
];


function useSteps(type: InterventionType) {
    return useMemo(() => {
        if (type === InterventionType.RDVCOM) {
            return [
                NewCustomerSelection
            ]
        }

        return defaultSteps;
    }, [type])
}