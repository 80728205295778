import React from "react";
import {Controller} from "react-hook-form";
import UserAutocomplete from "./UserAutocomplete";
import {Stack} from "@mui/material";
import ThirdPartyNameControl from "./Form/Controls/ThirdPartyNameControl";
import ThirdPartyTypeControl from "./Form/Controls/ThirdPartyTypeControl";
import PhoneControl from "./Form/Controls/PhoneControl";
import AddressControl from "./Form/Controls/AddressControl";
import EmailControl from "./Form/Controls/EmailControl";
import TypeControl from "./Form/Controls/TypeControl";

export default function ThirdPartyForm() {
    return (
        <>
            <Stack direction={"column"} spacing={2}>
                <TypeControl />

                <ThirdPartyTypeControl />

                <ThirdPartyNameControl />

                <AddressControl />

                <EmailControl />

                <Stack direction={"row"} spacing={2}>
                    <PhoneControl name={"phone"} label={"Téléphone"} />

                    <PhoneControl name={"personalMobilePhone"} label={"Téléphone 2"} />

                    <PhoneControl name={"businessMobilePhone"} label={"Téléphone 3"} />
                </Stack>

                <Controller
                    name={"commercial"}
                    render={
                        ({field }) => (
                            <UserAutocomplete
                                label={"Commercial"}
                                placeholder={"Sélectionner commercial"}
                                onChange={field.onChange}
                            />
                        )
                    }
                />
            </Stack>
        </>
    )
}
