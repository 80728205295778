export interface CardSearchResult {
    id: string;
    title: string;
    customerName: string;
    customerAddress: string;
    customerTown: string;
    customerZip: string;
    totalHT: string;
    vatAmount: string;
    totalTTC: string;
    origineAffaire: string;
    foireId: string;
    status: CardStatus;
    statusUpdatedAt: string | null;
    foire: string;
    productTypology: string;
    createdAt: string;
    endOfValidity: string;
}

export enum CardStatus {
    // Proposal
    WAITING_VT = 'WAITING_VT',
    VT_PLANIFIEES = 'VT_PLANIFIEES',
    VT_DONE = 'VT_DONE',
    VT_CANCELLED = 'VT_CANCELLED',
    VALIDATED_BE = 'VALIDATED_BE',
    WAITING_MAIRIE = 'WAITING_MAIRIE',
    CASE_SENT_MAIRIE = 'CASE_SENT_MAIRIE',
    EXTENSION_REQUEST_MAIRIE = 'EXTENSION_REQUEST_MAIRIE',
    CONDITIONALLY_APPROVED_MAIRIE = 'CONDITIONALLY_APPROVED_MAIRIE',
    VALIDATED_MAIRIE = 'VALIDATED_MAIRIE',
    DENIED_MAIRIE = 'DENIED_MAIRIE',
    DENIED_BE = 'DENIED_BE',
    WAITING_BANK_AGREEMENT = 'WAITING_BANK_AGREEMENT',
    WAITING_CUSTOMER_AGREEMENT = 'WAITING_CUSTOMER_AGREEMENT',
    WAITING_FINANCING_AGREEMENT = 'WAITING_FINANCING_AGREEMENT',
    WAITING_COMMERCIAL = 'WAITING_COMMERCIAL',
    VALIDATED_COMMERCIAL = 'VALIDATED_COMMERCIAL',
    COMMISSIONABLE_CASE = 'COMMISSIONABLE_CASE',
    COMMISSIONNED_CASE = 'COMMISSIONNED_CASE',
    CANCELLATION_REQUEST = 'CANCELLATION_REQUEST',
    CANCELLED_CASE = 'CANCELLED_CASE',
    INSTALLATION_TO_BE_PLANNED = 'INSTALLATION_TO_BE_PLANNED',
    PLANNED_INSTALLATION = 'PLANNED_INSTALLATION',
    NO_INSTALLATION = 'NO_INSTALLATION',
    UNCOMPLETED_INSTALLATION = 'UNCOMPLETED_INSTALLATION',
    COMPLETED_INSTALLATION = 'COMPLETED_INSTALLATION',
    TIMEOUT_BE = 'TIMEOUT_BE',

    // InterventionOfSav
    SAV_TO_SCHEDULE = 'TO_SCHEDULE',
    SAV_SCHEDULED = 'SCHEDULED',
    SAV_CANCELLED = 'CANCELLED',
    SAV_WAITING_FOR_PARTS = 'WAITING_FOR_PARTS',
    SAV_PARTS_RECEIVED = 'PARTS_RECEIVED',
    SAV_PARTIALLY_COMPLETED = 'PARTIALLY_COMPLETED',
    SAV_COMPLETED = 'COMPLETED',
    SAV_FULLY_COMPLETED = 'FULLY_COMPLETED', // SAV considéré comme totalement terminé par les responsables

    // PV
    CONSUEL_TODO = 'CONSUEL_TODO',
    CONSUEL_SENT = 'CONSUEL_SENT',
    CONSUEL_RECEIVED = 'CONSUEL_RECEIVED',
    CONSUEL_CHANGES_REQUEST = 'CONSUEL_CHANGES_REQUEST',
    MYLIGHT_TODO = 'MYLIGHT_TODO',
    MYLIGHT_DONE = 'MYLIGHT_DONE',
    MYLIGHT_RECEIVED = 'MYLIGHT_RECEIVED',
}

export interface CardCommentSearchResult {
    cardId: string;
    cardType: string;
    comment: string;
    entity: string;
    authorId: string;
    authorFirstname: string;
    authorLastname: string;
    createdAt: string;
}

