import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";

export interface TemplateModel {
    id: string;
    name: string;
    path: string;
    type: string;
}

export async function listTemplates() {
    const { data } = await http.get<TemplateModel[]>(Endpoint.listCustomDocuments);

    return data;
}

