import {FormProvider, useForm} from "react-hook-form";
import Paper from "@mui/material/Paper";
import React from "react";
import EventForm from "./EventForm";
import {Stack, Typography} from "@mui/material";

const CreateFoirePage = () => {
    const methods = useForm();

    return(
        <FormProvider{...methods}>
            <Paper elevation={6} style={{ width: 650 }}>
                <Stack direction={"column"} justifyContent={"start"} p={2}>
                    <Typography variant="h5">
                        Nouvelle Foire
                    </Typography>

                    <Typography variant="caption">
                        Création d'une nouvelle foire
                    </Typography>
                </Stack>

                <EventForm />
            </Paper>
        </FormProvider>
    )
}

export default CreateFoirePage;