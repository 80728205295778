import {Autocomplete, FormControl, FormLabel, TextField} from "@mui/material";
import {useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {searchThirdParties} from "../../calls/ThirdParties/searchThirdParties";
import {Controller} from "react-hook-form";

type ThirdPartyAutocompleteProps = {
    label?: string;
}

export default function ThirdPartyAutocomplete({ label }: ThirdPartyAutocompleteProps) {
    const name = "thirdParty";

    const [term, setTerm] = useState("");

    const { data } = useQuery(["thirdParties", term], () => searchThirdParties({ name: term }), {
         // only fetch search terms longer than 2 characters
         enabled: term.length > 1,
         // refresh cache after 10 seconds (watch the network tab!)
         staleTime: 10 * 1000
     });


    const options = (data?.items || []);

    return (
        <Controller
            name={name}
            rules={{
                required: true
            }}
            defaultValue={null}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                    <FormControl fullWidth>
                        <FormLabel>
                            {label}
                        </FormLabel>
                        <Autocomplete
                            onInputChange={(_, value) => setTerm(value)}
                            options={options}
                            getOptionLabel={o => o.name}
                            isOptionEqualToValue={(a, b) => a.id === b.id}
                            value={value}
                            onChange={(e, o: any) => onChange(o)}
                            noOptionsText={"Aucun tiers trouvé"}
                            renderInput={(params) => {
                                return (
                                    <TextField {...params} placeholder={"Taper ici pour rechercher"} size="small" variant="outlined" />
                                )
                            }}
                        />
                    </FormControl>
                )
            }}
        />
    );
}

