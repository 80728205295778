import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";
import {PaginatedResponse} from "../../models/common.model";

type Pagination = {
    sortfield: string,
    sortorder: 'ASC' | 'DESC',
    limit: number,
}

interface ProposalEvent {
    label: string;
    eventDate: string;
    author: string;
    authorId: string;
}

export async function searchProposalEvents(id: number, pagination: Pagination = {  sortfield: 't.rowid', sortorder: 'DESC', limit: 500 }) {
    const url = Endpoint.proposalEventSearch.replace(':proposalId', id.toString());

    const { data } = await http.post<PaginatedResponse<ProposalEvent>>(url, {}, {
        params: pagination,
    });

    return data;
}