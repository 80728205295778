import {createThirdParty, CreateThirdPartyDto} from "../../../calls/ThirdParties/createThirdParty";
import {createProposal, ProposalHeaderDto, ProposalLineDto} from "../../../calls/Proposals/createProposal";
import {useMutation} from "@tanstack/react-query";
import {useActiveCompanyId} from "../../Company/company.store";
import {validateProposal} from "../../../calls/Proposals/validateProposal";
import {buildDocument} from "../../../calls/Documents/buildDocument";
import {downloadDocument} from "../../../calls/Documents/downloadDocument";
import {uploadDocumentToEcm} from "../../../calls/Documents/uploadDocumentToEcm";
import {addContact} from "../../../calls/Proposals/addContact";
import {ProposalContactType} from "../../../models/proposal.model";
import {createContact, CreateContactDto} from "../../../calls/Contacts/createContact";

type Payload = {
    existingThirdParty?: {
        thirdPartyId: string, // Id d'un tiers existant
        thirdPartyName: string,
        projectId: string, // Id project si utilisation d'un tiers existant
    },
    thirdParty?: Omit<CreateThirdPartyDto, 'entity'>,
    proposals: Array<{
        header: Omit<ProposalHeaderDto, 'socid' | 'fk_project' | 'entity'>,
        lines: ProposalLineDto[],
    }>,
    billingAddress?: Omit<CreateContactDto, 'socid'>,
    docTemplateName: string,
}

export function useCreateNewContractMultipleProposals() {
    const companyId = useActiveCompanyId();

    return useMutation(
        async ({ existingThirdParty, thirdParty, proposals, docTemplateName, billingAddress }: Payload) => {
            // Création du tiers d'abord
            const { thirdpartyId, thirdPartyName, projectId, defaultContactId } = await createThirdPartyOrUseExistingOne({
                existingThirdParty,
                thirdParty: {
                    ...thirdParty!,
                    entity: +companyId,
                }
            });

            for (const proposal of proposals) {
                // Création du proposal lié au tiers
                const proposalId  = await createProposal({
                    ...proposal.header,
                    socid: thirdpartyId,
                    fk_project: projectId,
                    entity: +companyId,
                }, proposal.lines);

                // On valide directe le devis après la création
                const { ref: proposalRef } = await validateProposal(proposalId);

                // Default contact n'est pas là si le client est existant
                if (defaultContactId) {
                    // On va créer le contact pour la facturation
                    const billingContactId = !billingAddress ? defaultContactId : (
                        await createContact({
                            ...billingAddress,
                            socid: thirdpartyId,
                        })
                    );

                    // On va directement attacher les contacts
                    await addContact({
                        id: proposalId,
                        contactid: defaultContactId,
                        type: ProposalContactType.SHIPPING, // Contact par défaut créé = contact pour le chantier
                    });

                    await addContact({
                        id: proposalId,
                        contactid: billingContactId,
                        type: ProposalContactType.BILLING, // contact pour facturation
                    });
                }

                // On va build ensuite le document associé
                await buildDocument({
                    modulepart: 'propal',
                    langcode: 'fr_FR',
                    original_file: proposalRef,
                });

                // On success du build on lance un call pour download le fichier
                const docTemplate = removeExtension(docTemplateName); // rm ext pour éviter 404 à cause du rajouter .pdf

                const { content: filecontent } = await downloadDocument({
                    modulepart: 'propal',
                    original_file: `${proposalRef}/${proposalRef}_${docTemplate}.pdf`,
                    entity: +companyId,
                });

                // On success du download en base64 on upload le contenu et on est bon
                const filename = `${proposalRef}_${docTemplate}.pdf`;
                const subdir = `${thirdPartyName} (${thirdpartyId})`; // ex. Marcel Pierre (26)

                await uploadDocumentToEcm({
                    filename,
                    subdir,
                    filecontent,
                    overwriteifexists: 1, // On overwrite ?
                });
            }
        }
    )
}

async function createThirdPartyOrUseExistingOne({ existingThirdParty, thirdParty }: {
    existingThirdParty?: {
        thirdPartyId: string,
        thirdPartyName: string,
        projectId: string,
    },
    thirdParty?: CreateThirdPartyDto,
}): Promise<{
    thirdpartyId: number,
    thirdPartyName: string,
    projectId: number,
    defaultContactId?: number; // contact par défaut créé
}> {
    if (existingThirdParty) {
        return {
            thirdpartyId: +existingThirdParty.thirdPartyId,
            thirdPartyName: existingThirdParty.thirdPartyName,
            projectId: +existingThirdParty.projectId,
        }
    }

    const { thirdpartyId, projectId, defaultContactId } = await createThirdParty(thirdParty!);

    return { thirdpartyId, thirdPartyName: thirdParty!.name, projectId, defaultContactId };
}

function removeExtension(filename: string) {
    return filename.substring(0, filename.lastIndexOf('.')) || filename;
}