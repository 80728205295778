import {useThirdPartyBottomDrawerState} from "../ThirdParty/useThirdPartyBottomDrawerStore";
import {useMutation, useQuery} from "@tanstack/react-query";
import {listThirdPartyFiles} from "../../calls/ThirdParties/listThirdPartyFiles";
import {IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon} from "@ionic/react";
import {DateTime} from "luxon";
import {defaultStyles, FileIcon} from "react-file-icon";
import IonLoadingButton from "../Common/Lab/IonLoadingButton";
import {linkOutline, linkSharp} from "ionicons/icons";
import {downloadDocument} from "../../calls/Documents/downloadDocument";
import {saveAs} from 'file-saver';
import {useActiveCompanyId} from "../Company/company.store";
import {getThirdParty} from "../../calls/ThirdParties/getThirdParty";
import {uploadDocumentToEcm} from "../../calls/Documents/uploadDocumentToEcm";
import {useRef} from "react";
import * as blobUtils from 'blob-util';
import {
    Avatar,
    CircularProgress,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Stack,
    Tooltip
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {deleteDocument} from "../../calls/Documents/deleteDocument";
import useConfirm from "../Common/Confirmation/useConfirm";

export default function DocumentCard({ id }: { id: string }) {
    const { isLoading, data, isError, refetch } = useQuery(['3rdParty', id, 'files'], () => listThirdPartyFiles(id), {
        enabled: !!id
    });

    return (
        <IonCard style={{ background: 'white' }}>
            <IonCardHeader>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <IonCardTitle>Fichiers</IonCardTitle>
                    <UploadButton thirdPartyId={id} onUploaded={() => refetch()} />
                </Stack>
            </IonCardHeader>
            <IonCardContent>
                {
                    isLoading && (
                        <>Chargement ...</>
                    )
                }

                {
                    (isError || data?.items.length === 0) && (
                        <>Pas de documents trouvés pour ce tiers</>
                    )
                }

                {
                    (!isLoading && !isError) && (
                        <List dense>
                            {
                                data?.items.map(
                                    ({ filename, date, size }) => {
                                        const extension = filename.split(".").pop() as string;

                                        return (
                                            <ListItem
                                                key={filename}
                                                secondaryAction={
                                                    <Stack spacing={2} direction={"row"} alignItems={"center"}>
                                                        <DownloadButton thirdPartyId={id} filename={filename} />

                                                        <DeleteButton thirdPartyId={id} filename={filename} onDeleted={() => refetch()} />
                                                    </Stack>
                                                }
                                            >
                                                <ListItemAvatar>
                                                    <Avatar variant={"rounded"} sx={{ width: 32, height: 32 }}>
                                                        <FileIcon extension={extension} {...(defaultStyles as any)[extension]} />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={<h3><b>{filename}</b></h3>}
                                                    secondary={<DateLabel value={date} />}
                                                />
                                            </ListItem>
                                        )
                                    })
                            }
                        </List>
                    )
                }
            </IonCardContent>
        </IonCard>
    )
}

function DateLabel({ value }: { value: number }) {
    const d = DateTime.fromSeconds(value, { zone: "Europe/Paris" }).setLocale('fr');
    return <>{d.toFormat('dd/MM/yyyy HH:mm:ss')}</>;
}

function DownloadButton({ thirdPartyId, filename }: { thirdPartyId: string, filename: string }) {
    const companyId = useActiveCompanyId();

    const { data: customer } = useThirdPartyDetail(thirdPartyId);
    const { currentThirdPartyId: id, currentThirdPartyName: name } = useThirdPartyBottomDrawerState();

    const customerId = id || thirdPartyId || '';
    const customerName = name || customer?.name || '';

    const folder = `${customerName} (${customerId})`;

    const file = `${folder}/${filename}`;

    const { mutateAsync, isLoading } = useMutation(() => downloadDocument({
        modulepart: 'ecm',
        original_file: file,
        attachment: 1,
        entity: +companyId,
    }));

    const handleDownload = async () => {
        const res = await mutateAsync();
        const base64 = `data:${res["content-type"]};base64,${res.content}`;
        saveAs(dataURItoBlob(base64), res.filename);
    }

    if (isLoading) {
        return (
            <CircularProgress color="inherit" size="1rem" />
        )
    }

    return (
        <Tooltip title="Télécharger">
            <IconButton edge="end" aria-label="download" disabled={!customer} onClick={handleDownload}>
                <FileDownloadIcon />
            </IconButton>
        </Tooltip>
    )
}

function dataURItoBlob(dataURI: any) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    const blob = new Blob([ab], {type: mimeString});
    return blob;
}

function DeleteButton({ thirdPartyId, filename, onDeleted }: { thirdPartyId: string, filename: string, onDeleted(): void }) {
    const companyId = useActiveCompanyId();

    const { data: customer } = useThirdPartyDetail(thirdPartyId);

    const customerId =thirdPartyId || '';
    const customerName = customer?.name || '';

    const folder = `${customerName} (${customerId})`;

    const file = `${folder}/${filename}`;

    const { mutateAsync, isLoading } = useMutation(() => deleteDocument({
        modulepart: 'ecm',
        original_file: file,
        entity: +companyId,
    }));

    const confirm = useConfirm();

    if (isLoading) {
        return (
            <CircularProgress color="inherit" size="1rem" />
        )
    }

    return (
        <Tooltip title="Supprimer le fichier">
            <IconButton edge="end" aria-label="delete" disabled={!customer} onClick={async () => {
                const result = await confirm({
                    title: "Confirmation suppression",
                    message: <>Êtes-vous sûr de vouloir supprimer le document <b>{filename}</b> ?</>
                });

                if (!result) {
                    return;
                }

                await mutateAsync();
                onDeleted();
            }}>
                <DeleteIcon />
            </IconButton>
        </Tooltip>
    )
}

function UploadButton({ thirdPartyId, onUploaded }: { thirdPartyId: string, onUploaded: () => void }) {
    const { mutateAsync: uploadToEcm, isLoading } = useThirdPartyEcm(thirdPartyId);

    const fileInputRef = useRef();

    const handleClick = () => {
        (fileInputRef.current as any)?.click();
    };

    const handleFileChange = async (e: any) => {
        const file = e.target.files[0];

        // handle the selected file
        if (!file) {
            return;
        }

        const filename = file.name;
        const filecontent = await blobUtils.blobToBase64String(file);

        await uploadToEcm({ filename, filecontent });

        onUploaded();
    };

    return (
        <>
            <IonLoadingButton fill="clear" onClick={() => handleClick()} loading={isLoading}>
                <IonIcon slot="start" md={linkSharp} ios={linkOutline}/>
                Joindre un fichier
            </IonLoadingButton>

            <input
                ref={fileInputRef as any}
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
            />
        </>
    )
}

function useThirdPartyEcm(thirdPartyId: string) {
    const { data: thirdparty } = useThirdPartyDetail(thirdPartyId);

    return useMutation(async ({ filename, filecontent }: { filename: string, filecontent: string }) => {
        // On success du download en base64 on upload le contenu et on est bon
        const subdir = `${thirdparty!.name} (${thirdparty!.id})`; // ex. Marcel Pierre (26)

        await uploadDocumentToEcm({
            filename,
            subdir,
            filecontent,
            overwriteifexists: 1, // On overwrite !
        });
    })
}

function useThirdPartyDetail(id: number | string) {
    return useQuery(['thirdParty', id], ({ queryKey }) => {
        return getThirdParty(queryKey[1] as string)
    });
}