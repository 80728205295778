import {Stack, TextField, Typography} from "@mui/material";
import React, {CSSProperties, useState} from "react";
import styled from "@emotion/styled";
// @ts-ignore
import BaseBoard from 'react-trello';
import {BoardSearchResult} from "../../models/kanban/board.model";
import BoardSwitchButton from "./BoardSwitchButton";
import {useBoardFilters, useBoardStore} from "./board.store";
import {useBoardData} from "./useBoardData";
import {CardStatus} from "../../models/kanban/card.model";
import {useSetCardStatus} from "./useSetCardStatus";
import {DatePicker} from "@mui/x-date-pickers";
import {DateTime} from "luxon";
import {idateTime} from "../../utils";
import {useAffaireShowModalStore} from "../Affaire/store";
import {IonButton, IonIcon, IonModal} from "@ionic/react";
import {closeOutline, closeSharp, mapOutline, mapSharp} from "ionicons/icons";
import AffaireShow from "../AffaireDetail/AffaireShow";
import CardCommentDialog from "./CardCommentDialog";
import DefaultCustomCard from "./DefaultCustomCard";
import slugify from "slugify";
import useToggleOpen from "../Common/useToggleOpen";
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import InterventionList from "../Intervention/InterventionList";
import DialogContent from "@mui/material/DialogContent";
import InterventionOfSavCard from "./InterventionOfSavCard";
import {ProductTypology} from "../Intervention/types";
import {sendValidationBureauEtudeMail} from "../../calls/Mailings/sendValidationBureauEtudeMail";
import {sendRefusBureauEtudeMail} from "../../calls/Mailings/sendRefusBureauEtudeMail";
import {sendAnnulationDossierMail} from "../../calls/Mailings/sendAnnulationDossierMail";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface BoardProps {
    id: number;
    title: string;
    cardElementType: 'propal' | 'interventionOfSav';
    availableBoards: BoardSearchResult[];
    className?: string;
    style?: CSSProperties | undefined;
}

export default function Board({ className, style, availableBoards, ...props }: BoardProps) {
    const hasManyBoards = availableBoards.length > 0;

    const boardId = useBoardStore(state => state.currentBoardId);
    const boardSlug = slugify(props.title, {
        replacement: '_',  // replace spaces with replacement character, defaults to `-`
        strict: true,     // strip special characters except replacement, defaults to `false`
    }).toUpperCase();

    const boardFilters = useBoardFilters(state => {
        const {filters} = state;

        return {
            startCreateDate: filters?.startCreateDate && idateTime(filters?.startCreateDate),
            endCreateDate: filters?.endCreateDate && idateTime(filters?.endCreateDate),
        }
    });

    const { isError, isLoading, data, listConfigRecord, refresh } = useBoardData({
        boardId,
        cardElementType: props.cardElementType,
        filters: boardFilters,
    });

    const { mutateAsync: setStatusAsync, isLoading: settingStatus } = useSetCardStatus({
        cardElementType: props.cardElementType,
    });

    const { isOpen, selectedProposalId: selectedCardId, show, hide } = useAffaireShowModalStore();

    const onCardClicked = (cardId: string, metadata: any, laneId: string) => {
        const proposalId = props.cardElementType === 'propal' ? cardId : metadata.proposalId;
        show(proposalId);
    }

    const { cardId, rule, dialogOpen, showDialog, hideDialog } = useCardCommentDialog();

    const handleDialogCloseReason = async (reason: 'cancel' | 'ok') => {
        if (!rule?.targetLaneId) {
            hideDialog();
            return;
        }

        if (reason === 'ok') {
            const newStatus = listsIdToCardStatus[rule.targetLaneId];
            await setStatusAsync({
                cardId: cardId, status: newStatus,
            });
            refresh();
        }

        hideDialog();
    }

    const onCardDragEnd = (cardId: string, sourceLaneId: string, targetLaneId: string, position: number, cardDetails: any) => {
        // vérification de la config pour cancel le drop si jamais la liste source est readOnly
        const config = listConfigRecord[sourceLaneId];

        if (config.readOnly) {
            return false;
        }

        const targeLaneRule = targetLaneRules[targetLaneId];

        if (targeLaneRule) {
            showDialog(cardId, targeLaneRule);
            return false;
        }

        const newStatus = listsIdToCardStatus[targetLaneId];

        let finalStatus: CardStatus | undefined; // utilisé pour les status temp tels que VALIDATED_BE, CASE_SENT_MAIRIE, COMMISSIONNED_CASE

        if (newStatus === CardStatus.VALIDATED_BE) {
            const { metadata: { productTypology } } = cardDetails;

            finalStatus = (productTypology === ProductTypology.PHOTOVOLTAIC)
                ? CardStatus.WAITING_MAIRIE
                : CardStatus.WAITING_COMMERCIAL
            ;
        }

        // Envois des mails si besoin
        if ([CardStatus.VALIDATED_BE, CardStatus.DENIED_BE, CardStatus.CANCELLED_CASE].indexOf(newStatus) !== -1) {
            // notifyStatusByMail(cardId, newStatus);
        }

        // S'il y un statut final cible on update avec le nouveau statut puis 500ms après on update avec le statut final
        if (finalStatus) {
            setStatusAsync({ cardId: cardId, status: newStatus })
                .then(
                    () => {
                        setTimeout(
                            () => {
                                setStatusAsync({ cardId: cardId, status: finalStatus! })
                                    .then(
                                        () => refresh()
                                    );
                            },
                            500
                        )
                    }
                );

            return;
        }

        setStatusAsync({
            cardId: cardId, status: newStatus,
        }).then(() => refresh());
    }

    const needInterventionMap = (boardSlug === 'TABLEAU_PLANIFICATION') || (boardSlug === 'TABLEAU_INSTALLATION') || (boardSlug === 'TABLEAU_SAV');

    return (
        <div
            className={className}
            style={style}
        >
            <Stack direction={"row"} alignItems={"center"} sx={{ width: '100%' }} spacing={1}>
                {
                    hasManyBoards ? (
                        <BoardSwitchButton label={props.title} availableBoards={availableBoards} />
                    ) : (
                        <Typography variant="h5" gutterBottom sx={{ pl: '10px' }}>
                            {props.title}
                        </Typography>
                    )
                }

                <DateRange />

                {
                    needInterventionMap && (
                        <InterventionMapModalButton
                            onClose={() => {
                                refresh();
                            }}
                        />
                    )
                }
            </Stack>


            <div>
                {
                    (isLoading) && (
                        <>Chargement ...</>
                    )
                }

                {
                    (isError) && (
                        <>Aucune liste disponible dans ce tableau</>
                    )
                }

                {
                    (!isLoading && !isError) && (
                        <StyledBoard
                            data={data}
                            components={{
                                Card: props.cardElementType === 'interventionOfSav' ? InterventionOfSavCard : DefaultCustomCard
                            }}
                            laneDraggable={false}
                            hideCardDeleteIcon={true}
                            handleDragEnd={onCardDragEnd}
                            onCardClick={onCardClicked}
                        />
                    )
                }
            </div>

            <IonModal id={'AffaireTableModal'} isOpen={isOpen} onWillDismiss={() => {
                hide();
                refresh();
            }} style={{ '--backdrop-opacity': .4 }} keepContentsMounted={false}>
                <div className="ion-padding" style={{ paddingBottom: 0 }}>
                    <IonButton fill="clear" size={"small"} onClick={() => {
                        hide();
                        refresh();
                    }}>
                        <IonIcon slot="start" md={closeSharp} ios={closeOutline} />
                        Fermer
                    </IonButton>
                </div>

                <AffaireShow id={selectedCardId} />
            </IonModal>

            <CardCommentDialog cardId={cardId} rule={rule} open={dialogOpen} onClose={handleDialogCloseReason} />
        </div>
    )
}

function notifyStatusByMail(proposalId: string, status: CardStatus) {
    switch (status) {
        case CardStatus.VALIDATED_BE:
            return sendValidationBureauEtudeMail(proposalId);

        case CardStatus.DENIED_BE:
            return sendRefusBureauEtudeMail(proposalId);

        case CardStatus.CANCELLED_CASE:
            return sendAnnulationDossierMail(proposalId);
    }
}

function DateRange() {
    const { startCreateDate, endCreateDate } = useBoardFilters(state => state.filters);
    const updateFilters = useBoardFilters(state => state.updateFilters);

    const handleChange = ([start, end]: [DateTime | null, DateTime | null]) => {
        updateFilters({
            startCreateDate: start,
            endCreateDate: end,
        } as any);
    };

    return (
        <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ ml: 8 }}>
            <DatePicker
                label="Du"
                value={startCreateDate}
                onChange={value => handleChange([value, endCreateDate] as any) }
                renderInput={(params) => <TextField {...params} size={"small"} />}
            />

            <DatePicker
                label="Au"
                value={endCreateDate}
                onChange={value => handleChange([startCreateDate, value] as any) }
                renderInput={(params) => <TextField {...params} size={"small"} />}
            />
        </Stack>
    )
}

function InterventionMapModalButton({ onClose }: { onClose?(): void }) {
    const { open, show, hide } = useToggleOpen();

    const handleClose = () => {
        onClose && onClose();
        hide();
    }

    return (
        <React.Fragment>
            <IonButton expand="block" onClick={() => show()} fill="clear">
                <IonIcon slot="start" md={mapSharp} ios={mapOutline}></IonIcon>
                Carte interventions
            </IonButton>

            <Dialog
                open={open}
                maxWidth={'xl'}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }} color="inherit" elevation={0}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <DialogContent sx={{ p: 1, minWidth: '75vw' }}>
                    <InterventionList />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

const StyledBoard = styled(BaseBoard)`
    background-color: transparent!important;

    header {
        align-items: center;
        justify-content: space-between;
    }
`;

const listsIdToCardStatus: Record<string, CardStatus> = {
    VALIDEES_EN_ATTENTE_VT: CardStatus.WAITING_VT,
    VT_PLANIFIEES: CardStatus.VT_PLANIFIEES,
    VT_TERMINEES: CardStatus.VT_DONE,
    VT_ANNULEES: CardStatus.VT_CANCELLED,

    EN_ATTENTE_BUREAU_D_ETUDE: CardStatus.VT_DONE,
    ETUDES_EN_ATTENTE: CardStatus.VT_DONE,
    VALIDATION_BUREAU_D_ETUDE: CardStatus.VALIDATED_BE,
    ETUDES_TERMINEES: CardStatus.VALIDATED_BE,
    EN_ATTENTE_MAIRIE: CardStatus.WAITING_MAIRIE,
    EN_ATTENTE_VALIDATION_MAIRIE: CardStatus.WAITING_MAIRIE,
    DOSSIERS_ENVOYES_MAIRIE: CardStatus.CASE_SENT_MAIRIE,
    DEMANDE_DELAI_SUPP_MAIRIE: CardStatus.EXTENSION_REQUEST_MAIRIE,
    ACCORD_SOUS_RESERVE_MAIRIE: CardStatus.CONDITIONALLY_APPROVED_MAIRIE,
    REFUS_MAIRIE: CardStatus.DENIED_MAIRIE,
    VALIDATION_MAIRIE: CardStatus.VALIDATED_MAIRIE,
    REFUS_BUREAU_D_ETUDE: CardStatus.DENIED_BE,

    ATTENTE_SERVICE_COMMERCIAL: CardStatus.WAITING_COMMERCIAL,
    EN_ATTENTE_ACCORD_BANQUE: CardStatus.WAITING_BANK_AGREEMENT,
    EN_ATTENTE_ACCORD_CLIENT: CardStatus.WAITING_CUSTOMER_AGREEMENT,
    EN_ATTENTE_ACCORD_FINANCEMENT: CardStatus.WAITING_FINANCING_AGREEMENT,
    DOSSIERS_COMMISSIONNABLES: CardStatus.COMMISSIONABLE_CASE,
    DOSSIERS_COMMISSIONNES: CardStatus.COMMISSIONNED_CASE,
    DEMANDE_D_ANNULATION: CardStatus.CANCELLATION_REQUEST,
    DOSSIERS_ANNULES: CardStatus.CANCELLED_CASE,

    INSTALLATIONS_A_PLANIFIER: CardStatus.INSTALLATION_TO_BE_PLANNED,
    INSTALLATIONS_PROGRAMMEES: CardStatus.PLANNED_INSTALLATION,
    INSTALLATIONS_NON_FAITES: CardStatus.NO_INSTALLATION,
    INSTALLATIONS_NON_TERMINEES: CardStatus.UNCOMPLETED_INSTALLATION,
    INSTALLATIONS_TERMINEES: CardStatus.COMPLETED_INSTALLATION,

    ATTENTE_BE_SUP_1SEM: CardStatus.TIMEOUT_BE,

    SAV_A_PLANIFIER: CardStatus.SAV_TO_SCHEDULE,
    SAV_PROGRAMMES: CardStatus.SAV_SCHEDULED,
    SAV_ATTENTE_PIECES: CardStatus.SAV_WAITING_FOR_PARTS,
    SAV_PIECES_RECUES: CardStatus.SAV_PARTS_RECEIVED,
    SAV_TERMINES: CardStatus.SAV_FULLY_COMPLETED,

    CONSUEL_A_FAIRE: CardStatus.CONSUEL_TODO,
    CONSUEL_ENVOYES: CardStatus.CONSUEL_SENT,
    CONSUEL_RECUS: CardStatus.CONSUEL_RECEIVED,
    CONSUEL_DEMANDE_DE_MODIF: CardStatus.CONSUEL_CHANGES_REQUEST,
    MYLIGHT_A_FAIRE: CardStatus.MYLIGHT_TODO,
    MYLIGHT_FAITS: CardStatus.MYLIGHT_DONE,
    MYLIGHT_RECUS: CardStatus.MYLIGHT_RECEIVED,
}

interface TargetLaneRule {
    targetLaneId: string,
    commentRequired: boolean,
}

const targetLaneRules: Record<string, { targetLaneId: string, commentRequired: boolean }> = {
    EN_ATTENTE_VALIDATION_MAIRIE: {
        targetLaneId: 'EN_ATTENTE_VALIDATION_MAIRIE',
        commentRequired: false,
    },
    REFUS_BUREAU_D_ETUDE: {
        targetLaneId: 'REFUS_BUREAU_D_ETUDE',
        commentRequired: true,
    },
    EN_ATTENTE_ACCORD_BANQUE: {
        targetLaneId: 'EN_ATTENTE_ACCORD_BANQUE',
        commentRequired: false,
    },
    EN_ATTENTE_ACCORD_CLIENT: {
        targetLaneId: 'EN_ATTENTE_ACCORD_CLIENT',
        commentRequired: false,
    },
    EN_ATTENTE_ACCORD_FINANCEMENT: {
        targetLaneId: 'EN_ATTENTE_ACCORD_FINANCEMENT',
        commentRequired: false,
    },
    DEMANDE_D_ANNULATION: {
        targetLaneId: 'DEMANDE_D_ANNULATION',
        commentRequired: true
    },
    DOSSIERS_ANNULES: {
        targetLaneId: 'DOSSIERS_ANNULES',
        commentRequired: true
    },
};

function useCardCommentDialog() {
    const [cardId, setCardId] = useState('');
    const [rule, setRule] = useState<TargetLaneRule | null>(null);
    const [open, setOpen] = useState(false);

    const showDialog = (cardId: string, rule: TargetLaneRule) => {
        setCardId(cardId);
        setRule(rule);
        setOpen(true);
    }

    const hideDialog = () => {
        setCardId('');
        setRule(null);
        setOpen(false);
    }

    return {
        cardId,
        rule,
        dialogOpen: open,
        showDialog,
        hideDialog,
    }
}