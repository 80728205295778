import 'leaflet/dist/leaflet.css'
import {MapContainer, Marker, Popup, TileLayer, useMap} from "react-leaflet";
import styled from "@emotion/styled";
import React, {useEffect, useLayoutEffect} from "react";
import L from 'leaflet';
import ReactDOMServer from "react-dom/server";
import {ProductTypology} from "./types";
import {productTypologyUI} from "./product-typology";
import {useInterventionsListContext} from "./useInterventionsListContext";
import {DateTime} from "luxon";
import {compact} from "lodash";
import HelpIcon from "@mui/icons-material/Help";
import {interventionUI} from "../../models/intervention.model";

export default function InterventionsMap() {
    useLayoutEffect(() => {
        window.dispatchEvent(new Event("resize"));
    }, []);

    const markers = useMarkers();

    return (
        <StyledMapContainer center={[44.837789, -0.57918]} zoom={13} scrollWheelZoom={true}>
            <TileLayer
                attribution='&copy; AMDP'
                url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
            />

            {
                markers.map(
                    m => {
                        const [lng, lat] = m.place!.coords! as [number,  number];

                        return (
                            <Marker key={m.id} position={[lat,  lng]} icon={createProductIcon(m.typology!)}>
                                <Popup>
                                    <h6>
                                        {m.customer.name}<br />
                                        <small>Prévue le {m.plannedDate}</small>
                                    </h6>
                                    <p style={{ margin: 0 }}><b>Intervention:</b> {m.interventionText}</p>
                                    <p style={{ margin: 0 }}><b>Adresse:</b> {m.addressText}</p>
                                    <p style={{ margin: 0 }}><b>Technicien:</b> {m.technician?.name || 'N/A'}</p>
                                </Popup>
                            </Marker>
                        )
                    }
                )
            }

            <FitMarkersBounds />
            {/*<Marker position={[44.837789, -0.57918]} icon={createProductIcon(ProductTypology.PAC_AIR_EAU)} />*/}
            {/*<Marker position={[44.832744, -0.633711]} icon={createProductIcon(ProductTypology.PAC_AIR_AIR)} />*/}
            {/*<Marker position={[44.829836, -0.630862]} icon={createProductIcon(ProductTypology.BALLON_THERMO)} />*/}
            {/*<Marker position={[44.850082, -0.589274]} icon={createProductIcon(ProductTypology.PHOTOVOLTAIC)} />*/}
        </StyledMapContainer>
    )
}

function useMarkers() {
    const {query} = useInterventionsListContext();

    const data = query?.data;

    const cards = data?.docs || [];

    return cards.map(
        c => {
            const plannedDate = DateTime.fromISO(c?.date || '',{zone:"Europe/Paris"}).setLocale('fr');
            const addressText = compact([c.place.address,c.place.zipCode, c.place.town]).join(', ');
            const technician = c.technician;
            const interventionText = (interventionUI.typeTranslation as any)[c.type] || 'N/A';
            const typology = c.proposal?.productTypology;

            return {
                id: c.id,
                customer: c.customer,
                plannedDate: plannedDate.toLocaleString(DateTime.DATETIME_MED),
                interventionText,
                addressText,
                technician,
                place: c.place,
                typology,
            }
        }
    )
}

function FitMarkersBounds() {
    const map = useMap();

    const {query} = useInterventionsListContext();

    const data = query?.data;

    const cards = data?.docs || [];

    useEffect(() => {
        if (cards.length === 0) {
            return;
        }

        const allPositions: Array<[number, number]> = cards.map(c => {
            const [lng, lat] = c.place.coords as [number, number];
            return [lat, lng];
        });

        map.flyToBounds(allPositions, { maxZoom: 10 });
    }, [cards])

    return null;
}

/**
 * https://codepen.io/katia-trifonova/pen/zwZgdx
 * https://medium.com/@nikjohn/creating-a-dynamic-jsx-marker-with-react-leaflet-f75fff2ddb9
 * @param typology
 */
function createProductIcon(typology: ProductTypology) {
    const Icon = productTypologyUI.typologyIcon[typology] || (() => <HelpIcon color={"inherit"} />);

    return (
        L.divIcon({
            iconAnchor: [10, 33],
            popupAnchor: [10, -30],
            iconSize: [30, 30],
            className: `custom-icon ${typology}`,
            html: `
            <div class="outer-circle">
                <div class="text">
                    ${ReactDOMServer.renderToString(<Icon />)}
                </div>
            </div>
        `})
    )
}

const StyledMapContainer = styled(MapContainer)`
    height: 100%;
    width: 100%;

    .custom-icon {
        .outer-circle {
            position: relative;
            width: 40px;
            height: 40px;
            border-radius: 40px;
            background: #fff;
            box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.3);
        }

        .text {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            
            svg {
                width: 30px;
                fill: white;
            }
        }
    }
    
    .custom-icon.PAC_AIR_EAU .outer-circle {
        background: var(--ion-color-pac-air-eau);
    }

    .custom-icon.PAC_AIR_AIR .outer-circle {
        background: var(--ion-color-pac-air-air);
    }

    .custom-icon.BALLON_THERMO .outer-circle {
        background: var(--ion-color-ballon-thermodynamique);
    }
    
    .custom-icon.PHOTOVOLTAIC .outer-circle {
        background: var(--ion-color-photovoltaique);
    }
`;