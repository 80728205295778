import {useActiveCompany} from "../Company/company.store";
import {useProposalDetail} from "../AffaireDetail/useProposalDetail";
import {useQuery} from "@tanstack/react-query";
import {getInterventionFiles} from "../../calls/Interventions/getInterventionFiles";
import JSZip from "jszip";
import {saveAs} from "file-saver";
import {FullWhiteCard} from "./FullWhiteCard";
import {
    IonButton,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonGrid,
    IonIcon,
    IonImg,
    IonRow
} from "@ionic/react";
import {cloudDownloadOutline, cloudDownloadSharp} from "ionicons/icons";
import LoadingDots from "../LoadingDots";
import React from "react";

export function InterventionPhotos({id, proposalId, customerName}: { id: string, proposalId: string, customerName: string }) {
    const company = useActiveCompany();

    const {data: proposal} = useProposalDetail(proposalId);

    const {data: files, refetch, isLoading} = useQuery(['intervention', id, 'files'], () => getInterventionFiles(id));
    const isEmpty = (files && files.length === 0) && !isLoading;

    const images = (files || []).filter(f => f.contentType.startsWith('image/'));

    const downloadZip = () => {
        const folderName = `Dossier photos ${customerName} ${proposal?.ref || ''} ${company.name}`;

        const zip = new JSZip();

        images.forEach((image, index) => {
            const src = `${image.base64Content}`;

            zip.file(image.filename, src, {base64: true});
        })

        zip.generateAsync({type: "blob"}).then((content) => {
            saveAs(content, `${folderName}.zip`);
        });
    }

    return (
        <FullWhiteCard>
            <IonCardHeader style={{display: 'flex'}} className={"ion-align-items-center"}>
                <div>
                    <IonCardTitle>Photos VT ({images.length})</IonCardTitle>
                </div>

                <IonButton fill="clear" style={{marginLeft: 'auto'}} color={'primary'} disabled={isLoading || isEmpty}
                           onClick={() => downloadZip()}>
                    <IonIcon slot={"start"} md={cloudDownloadSharp} ios={cloudDownloadOutline}/>
                    Télécharger
                </IonButton>
            </IonCardHeader>

            <IonCardContent>
                {
                    <IonGrid>
                        <IonRow>
                            {
                                isLoading ? (
                                    <LoadingDots/>
                                ) : (
                                    images.map((image, index) => {
                                        const src = `data:${image.contentType};base64,${image.base64Content}`;

                                        return (
                                            <IonCol size="3" key={`image-${index}`}>
                                                <IonImg src={src}/>
                                            </IonCol>
                                        )
                                    })
                                )

                            }
                        </IonRow>
                    </IonGrid>
                }
            </IonCardContent>
        </FullWhiteCard>
    )
}