import {interventionHttp} from "../../../http";
import {Endpoint} from "../../endpoint.enum";
import {CreateInterventionDto, InterventionTechnicianDto} from "../types";

export interface CreateEntretienDto extends CreateInterventionDto {
    installationYear?: string;
    poseTechnician?: InterventionTechnicianDto;
}

export async function createEntretien(dto: CreateEntretienDto) {
    const {data} = await interventionHttp.post<number>(Endpoint.createEntretien, dto);

    return data;
}