import {FormProvider, useForm} from "react-hook-form";
import {useQueryClient} from "@tanstack/react-query";
import {useAddCardComment} from "./useAddCardComment";
import {kanbanKeys} from "./kanbanKeys";
import React from "react";
import {IonContent, IonHeader, IonModal, IonTitle, IonToolbar,} from '@ionic/react';
import CardCommentControl from "./CardCommentControl";
import {Button, Stack} from "@mui/material";

interface CardCommentDialogProps {
    cardId: string;
    rule: {
        targetLaneId: string,
        commentRequired: boolean,
    } | null,
    open: boolean;
    onClose(reason: 'cancel' | 'ok'): void;
}

export default function CardCommentDialog({ cardId, rule, open, onClose }: CardCommentDialogProps) {
    const methods = useForm<{ comment: string }>();

    const queryClient = useQueryClient();
    const { mutateAsync, isLoading: addingComment } = useAddCardComment();

    const handleClose = (reason: 'cancel' | 'ok' = "cancel") => {
        onClose(reason);
    };

    const ok = () => handleClose("ok");
    const cancel = () => handleClose();

    const onSubmit = async ({ comment }: { comment: string }) => {
        await mutateAsync({ cardId: +cardId, comment });
        await queryClient.invalidateQueries({ queryKey: kanbanKeys.cardComments(+cardId) });
        methods.reset({ comment: "" });
        ok();
    }

    const isCommentRequired = rule?.commentRequired;
    const cancelButtonText = isCommentRequired ? 'Annuler' : (!!rule ? 'Pas de commentaires' : 'Annuler');

    return (
        <IonModal id={'CardCommentDialog'} isOpen={open} backdropDismiss={false} style={{ '--backdrop-opacity': .4, '--width': '40%', '--height': '28%' }} keepContentsMounted={false}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Saisir la raison de l'opération</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <FormProvider {...methods}>
                    <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                        <CardCommentControl multiline rows={3} />

                        <Stack spacing={1} direction={"row"} alignItems={"center"} justifyContent={"flex-end"} sx={{ mt: 5 }}>
                            {
                                (rule && !isCommentRequired) && (
                                    <Button type={"button"} variant="text" size={"small"} sx={{ mr: 'auto' }} onClick={cancel}>
                                        Annuler
                                    </Button>
                                )
                            }

                            <Button type={"submit"} variant="contained" size={"small"} disabled={addingComment} autoFocus={isCommentRequired}>
                                Enregistrer
                            </Button>

                            <Button type={"button"} variant="text" onClick={() => isCommentRequired ? cancel() : ok()} autoFocus={!isCommentRequired}>
                                {cancelButtonText}
                            </Button>
                        </Stack>
                    </form>
                </FormProvider>
            </IonContent>
        </IonModal>
    );
}
