import {InterventionsListContext, useInterventionsListContext} from "./useInterventionsListContext";
import {IonButton, IonIcon} from "@ionic/react";
import {filterOutline, filterSharp} from "ionicons/icons";
import {useInterventionsQuery} from "./useInterventionsQuery";
import {FormProvider} from "react-hook-form";
import {useInterventionFilterForm} from "./useInterventionFilterForm";
import {useOpenFilterDrawer} from "../Common/FilterDrawer/store";
import {usePowerTablePaginationHelper} from "../Common/Table/usePowerTablePaginationHelper";
import React, {useState} from "react";
import {
    Avatar,
    Button,
    Card,
    CardHeader,
    CircularProgress,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    styled,
    Tooltip,
    Typography
} from "@mui/material";
import InterventionFilterButton, {InterventionAdvancedSearchFormCollapse} from "./InterventionFilterButton";
import InterventionsMap from "./InterventionsMap";
import {DateTime} from "luxon";
import {compact, isEmpty, isNull, isUndefined, omitBy} from "lodash";
import PlaceIcon from '@mui/icons-material/Place';
import PersonIcon from '@mui/icons-material/Person';
import {productTypologyUI} from "./product-typology";
import HelpIcon from '@mui/icons-material/Help';
import InterventionTypeChip from "./InterventionTypeChip";
import AddIcon from '@mui/icons-material/Add';
import useToggleOpen from "../Common/useToggleOpen";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import InterventionWorkflowStepper from "./InterventionWorkflowStepper";
import {AllInterventionStatus, InterventionType, interventionUI} from "../../models/intervention.model";
import CommentIcon from '@mui/icons-material/Comment';
import IconButton from "@mui/material/IconButton";
import EditCalendarRoundedIcon from '@mui/icons-material/EditCalendarRounded';
import InterventionScheduleFormModal from "./InterventionSceduleFormModal";

export default function InterventionList(){
    const { filters, applyFilters, resetFilters, methods } = useInterventionFilterForm();
    const { page, rowsPerPage, offset, onPageChange, onRowsPerPageChange, resetPage } = usePowerTablePaginationHelper();

    const parsedFilters = omitBy(filters, v => isEmpty(v) || isNull(v) || isUndefined(v));

    const query = useInterventionsQuery({
        ...parsedFilters,
        maxDistance: parsedFilters.maxDistance ? Number(parsedFilters.maxDistance) : undefined,
        offset: offset - 1,
        limit: rowsPerPage
    });

    const itemsCount = query?.data?.totalDocs || 0;

    const contextValue = {
        query,
    };

    return(
        <FormProvider {...methods}>
            <InterventionsListContext.Provider value={contextValue}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8} sx={{ height: '94vh', padding: 0 }}>
                        <InterventionsMap />
                    </Grid>

                    <Grid item xs={12} md={4} sx={{
                        padding: 0,
                        maxHeight: '95vh',
                        overflow: 'scroll',
                    }}>
                        <InterventionsHeader onSubmit={
                            () => {
                                applyFilters();
                            }
                        } />
                        <InterventionCards />
                    </Grid>
                </Grid>
            </InterventionsListContext.Provider>
        </FormProvider>

    )
}

function InterventionsHeader({ onSubmit }: { onSubmit(): void }) {
    const {query} = useInterventionsListContext();

    const data = query?.data;

    const itemsCount = data?.totalDocs || 0;

    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Stack direction="row" alignItems={"center"} justifyContent={"space-between"} sx={{ mb: 2 }}>
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', flexGrow: 1 }}>
                    Interventions ({itemsCount})
                </Typography>

                <Stack direction="row" alignItems={"center"} spacing={1}>
                    <NewButton />

                    <InterventionFilterButton open={open} onClick={() => open ? handleClose() : handleClick()} />
                </Stack>
            </Stack>

            <InterventionAdvancedSearchFormCollapse
                open={open}
                onSubmit={onSubmit}
                onClose={handleClose}
            />
        </>
    )
}

function InterventionCards() {
    const {query} = useInterventionsListContext();

    const data = query?.data;

    const cards = data?.docs || [];

    const isLoading = query?.isLoading;
    const isError = query?.isError;
    const isEmpty = !(isLoading && isError) && (cards.length === 0);

    if (isLoading) {
        return (
            <Stack
                justifyContent="center"
                alignItems="center"
                minHeight="87vh"
                maxHeight="87vh"
            >
                <div style={{ textAlign: 'center' }}>
                    <Typography variant="body1" component="div" sx={{ mb: 1 }}>
                        Chargement en cours ...
                    </Typography>

                    <CircularProgress color="inherit" size="1.5rem" />
                </div>
            </Stack>
        )
    }

    if (isEmpty) {
        return (
            <Stack
                justifyContent="center"
                alignItems="center"
                minHeight="87vh"
                maxHeight="87vh"
            >
                <div style={{ textAlign: 'center' }}>
                    <Typography variant="body1" component="div">
                        Aucune intervention trouvée
                    </Typography>

                    <Typography variant="caption" component="div">
                        Veuillez modifier les filtres ou lancer une nouvelle recherche
                    </Typography>
                </div>
            </Stack>
        )
    }

    return (
        <React.Fragment>
            <Stack direction="column" spacing={.5}>
                {
                    cards.map(
                        c => {
                            const plannedDate = DateTime.fromISO(c?.date || '',{zone:"Europe/Paris"}).setLocale('fr');
                            const addressText = compact([c.place.address,c.place.zipCode, c.place.town]).join(', ');
                            const technician = c.technician;

                            const typology = c.proposal?.productTypology;
                            const Icon = typology ? productTypologyUI.typologyIcon[typology!] : () => <HelpIcon color={"inherit"} />;
                            const typologyLabel = productTypologyLabel[typology || ''] || 'N/A';
                            const typologyColor = typology ? productTypologyUI.typologyColor[typology] : "#F0F0F0";
                            const iconColor = typologyColor || "rgba(0, 0, 0, 0.54)";

                            const notes = c.notes;

                            const toSchedule = c?.status === AllInterventionStatus.TO_SCHEDULE;

                            return (
                                <Card key={c.id} sx={{ width: '100%', border: `solid 2px ${typologyColor}` }} elevation={0}>
                                    <CardHeader
                                        avatar={
                                            <Tooltip title={typologyLabel}>
                                                <Avatar sx={{ bgcolor: 'white' }} variant={"square"}>
                                                    <Icon />
                                                </Avatar>
                                            </Tooltip>
                                        }
                                        title={
                                            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{
                                                '& ion-chip' : {
                                                    margin: 0
                                                }
                                            }}>
                                                <Typography variant="body1" component="div">
                                                    {c.customer.name}
                                                </Typography>

                                                <InterventionTypeChip type={c.type} />
                                            </Stack>
                                        }
                                        subheader={
                                            <Typography gutterBottom variant="body2" component="div">
                                                {
                                                    plannedDate.isValid
                                                        ? `Prévue le ${plannedDate.toLocaleString(DateTime.DATETIME_MED)}`
                                                        : 'Non planifiée'
                                                }
                                            </Typography>
                                        }
                                        sx={{ pb: 0 }}/>

                                    <List dense sx={{ p: 0 }}>
                                        <ListItem>
                                            <ListItemIcon>
                                                <PlaceIcon sx={{ color: iconColor }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={addressText}
                                                secondary={"Adresse"}
                                            />
                                        </ListItem>

                                        <ListItem
                                            secondaryAction={
                                                toSchedule
                                                    ? (
                                                        <ScheduleInterventionButton
                                                            id={c.id}
                                                            refetch={() => query?.refetch()}
                                                        />
                                                    )
                                                    : <></>
                                            }
                                        >
                                            <ListItemIcon>
                                                <PersonIcon sx={{ color: iconColor }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={technician?.name || 'Non assigné'}
                                                secondary={"Technicien"}
                                            />
                                        </ListItem>

                                        {
                                            notes && (
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <CommentIcon sx={{ color: iconColor }} />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={notes}
                                                        secondary={"Notes / Commentaires"}
                                                    />
                                                </ListItem>
                                            )
                                        }
                                    </List>
                                </Card>
                            )
                        }
                    )
                }
            </Stack>
        </React.Fragment>
    )
}

function ScheduleInterventionButton({ id, refetch }: { id: string, refetch: any }) {
    return (
        <>
            <IconButton id={`reschedule-intervention-any-${id}`} edge="end" aria-label="assign">
                <EditCalendarRoundedIcon />
            </IconButton>

            <InterventionScheduleFormModal
                id={id}
                trigger={`reschedule-intervention-any-${id}`}
                onWillDismiss={() => refetch()}
            />
        </>
    )
}

const productTypologyLabel: Record<string, string> = {
    PAC_AIR_AIR: "Pompe à chaleur R/R",
    PAC_AIR_EAU: "Pompe à chaleur R/O",
    PHOTOVOLTAIC: "Photovoltaïque",
    BALLON_THERMO: "Ballon thermo",
}

function NewButton() {
    const { open, show, hide } = useToggleOpen();
    const [type, setType] = useState<InterventionType | null>(null);
    const {query} = useInterventionsListContext();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (value?: InterventionType) => {
        setAnchorEl(null);

        if (!value) {
            return;
        }

        setType(value);
        show();
    };

    const onInterventionCreated = () => {
        query?.refetch();
        hide();
    }

    return (
        <>
            <ButtonLower type={"button"} variant="outlined" color="primary" size={"small"} startIcon={<AddIcon />} onClick={handleClick}>
                Nouvelle
            </ButtonLower>

            <Menu
                id="intervention-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={() => handleClose()}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => handleClose(InterventionType.VISITE_TECHNIQUE)}>Visite Technique</MenuItem>
                <MenuItem onClick={() => handleClose(InterventionType.POSE)}>Pose</MenuItem>
                <MenuItem onClick={() => handleClose(InterventionType.RDVCOM)}>Rdv Commercial</MenuItem>
                <MenuItem onClick={() => handleClose(InterventionType.SAV)}>SAV</MenuItem>
                <MenuItem onClick={() => handleClose(InterventionType.ENTRETIEN)}>Entretien</MenuItem>
            </Menu>

            <Dialog onClose={hide} open={open} sx={{
                '.MuiPaper-root': {
                    minWidth: 600
                }
            }}>

                {
                    type && (
                        <>
                            <DialogTitle>Programmer {interventionUI.typeTranslation[type]}</DialogTitle>

                            <InterventionWorkflowStepper
                                type={type}
                                onCreated={onInterventionCreated}
                            />
                        </>
                    )
                }
            </Dialog>
        </>
    )
}

export function FiltersButton() {
    const openFilterDrawer = useOpenFilterDrawer();

    return (
        <IonButton fill="outline" color={"primary"} size={"default"} onClick={() => openFilterDrawer()}>
            <IonIcon slot="start" md={filterSharp} ios={filterOutline}/>
            Filtres
        </IonButton>
    )
}

const ButtonLower = styled(Button)`
    text-transform: capitalize;
`;