import {CategoryScale, Chart} from "chart.js/auto";
import React from "react";
import {getInvoiceAmountByMonth, GetInvoiceAmountByMonthResult} from "../../calls/Stats/getInvoiceAmountByMonth";
import {getInvoiceAmountByMonthTTC} from "../../calls/Stats/getInvoiceAmountByMonthTTC";
import {getNbInvoiceByMonthWithPrevYear} from "../../calls/Stats/getNbInvoiceByMonthWithPrevYear";
import {zip} from 'lodash';
import {useQuery} from "@tanstack/react-query";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Box, Typography} from "@mui/material";
import {IonCol, IonGrid, IonItem, IonLabel, IonRow, IonToolbar} from "@ionic/react";
import {Bar} from "react-chartjs-2";

Chart.register(CategoryScale);

export default function InvoicesStats() {
    return (
        <IonGrid>
            <IonRow>
                <IonCol>
                    <IonToolbar>
                        <IonItem lines={"none"}>
                            <IonLabel>
                                <h1>Statistiques factures clients</h1>
                            </IonLabel>
                        </IonItem>
                    </IonToolbar>
                </IonCol>

                <IonCol size="12">
                    <IonGrid>
                        <IonRow>
                            <GetNbInvoiceByMonthWithPrevYear />

                            <GetInvoiceAmountByMonth />

                            <GetInvoiceAmountByMonthTTC />
                        </IonRow>
                    </IonGrid>
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}

function GetNbInvoiceByMonthWithPrevYear() {
    const { data: config, isLoading } = useQuery(
        ["stats", 'GetNbInvoiceByMonthWithPrevYear'], () => getNbInvoiceByMonthWithPrevYear(), {
            select(data) {
                return makeChartConfig(data);
            },
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: 1000 * 60 * 60 * 24,
        }
    );

    return (
        <>
            <IonCol size={'5'} style={{ display: 'flex', justifyContent: 'center' }}>
                {
                    config && (
                        <div style={{ width: 500, height: 200 }}>
                            <Bar
                                {...config}
                            />
                        </div>
                    )
                }
            </IonCol>

            {
                config && (
                    <IonCol size={'7'}>
                        <DetailTable {...config} />
                    </IonCol>
                )
            }
        </>
    )
}

function GetInvoiceAmountByMonth() {
    const { data: config, isLoading } = useQuery(
        ["stats", 'GetInvoiceAmountByMonth'], () => getInvoiceAmountByMonth(), {
            select(data) {
                return makeChartConfig(data);
            },
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: 1000 * 60 * 60 * 24,
        }
    );

    return (
        <>
            <IonCol size={'5'} style={{ display: 'flex', justifyContent: 'center' }}>
                {
                    config && (
                        <div style={{ width: 500, height: 200 }}>
                            <Bar
                                {...config}
                            />
                        </div>
                    )
                }
            </IonCol>

            {
                config && (
                    <IonCol size={'7'}>
                        <DetailTable {...config} />
                    </IonCol>
                )
            }
        </>
    )
}


function GetInvoiceAmountByMonthTTC() {
    const { data: config, isLoading } = useQuery(
        ["stats", 'GetInvoiceAmountByMonthTTC'], () => getInvoiceAmountByMonthTTC(), {
            select(data) {
                return makeChartConfig(data);
            },
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: 1000 * 60 * 60 * 24,
        }
    );

    return (
        <>
            <IonCol size={'5'} style={{ display: 'flex', justifyContent: 'center' }}>
                {
                    config && (
                        <div style={{ width: 500, height: 200 }}>
                            <Bar
                                {...config}
                            />
                        </div>
                    )
                }
            </IonCol>

            {
                config && (
                    <IonCol size={'7'}>
                        <DetailTable {...config} />
                    </IonCol>
                )
            }
        </>
    )
}

function DetailTable(config: ReturnType<typeof makeChartConfig>) {
    return (
        <TableContainer component={Paper} elevation={0}>
            <Box p={2}>
                <Typography variant={"subtitle2"}>
                    <b>{config.options.plugins.title.text}</b>
                </Typography>
            </Box>
            <Table size="small" sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell><b>Année</b></TableCell>
                        {
                            config?.data.labels?.map(
                                (label, index) => {
                                    return (
                                        <TableCell key={`${index}-${label}`}>{label}</TableCell>
                                    )
                                }
                            )
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        config?.data.datasets?.map(
                            dataset => {
                                return (
                                    <TableRow key={dataset.label}>
                                        <TableCell><b>{dataset.label}</b></TableCell>
                                        {
                                            dataset.data.map(
                                                (val, index) => {
                                                    return (
                                                        <TableCell key={`${dataset.label}-${index}-${val}`}>
                                                            {val}
                                                        </TableCell>
                                                    )
                                                }
                                            )
                                        }
                                    </TableRow>
                                )
                            }
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

function makeChartConfig(r: GetInvoiceAmountByMonthResult) {
    const items = zip(...r.data);

    const labels = items.shift();

    const datasets = r.Legend.map(
        (legend, index) => {
            const color = r.datacolor[index];
            const rgb = color.join(', ');

            return {
                label: legend.toString(),
                pointStyle: 'circle',
                // fill: true,
                borderWidth: 1,
                borderColor: `rgb(${rgb}, 0.9)`,
                backgroundColor: `rgb(${rgb}, 0.9)`,
                data: items[index],
            }
        }
    );

    return {
        type: 'bar',
        data: {
            labels,
            datasets,
        },
        options: {
            maintainAspectRatio: false,
            aspectRatio: 2.5,
            plugins: {
                title: {
                    display: true,
                    text: r.title
                }
            }
        },
    }
}