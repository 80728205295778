import {useMutation} from "@tanstack/react-query";
import {setProposalCardStatus} from "../../calls/Kanban/setProposalCardStatus";
import {CardStatus} from "../../models/kanban/card.model";
import {setInterventionOfSavCardStatus} from "../../calls/Kanban/setInterventionOfSavCardStatus";

interface UseSetCardStatusProps {
    cardElementType?: 'propal' | 'interventionOfSav';
}

export function useSetCardStatus({ cardElementType = 'propal' }: UseSetCardStatusProps = {}) {
    const mutationFn = getMutationFn(cardElementType) as any;

    return useMutation(({ cardId, status }: { cardId: any, status: CardStatus }) => mutationFn(cardId, status));
}

function getMutationFn(cardElementType: 'propal' | 'interventionOfSav') {
    switch (cardElementType) {
        case "propal":
            return setProposalCardStatus;

        case "interventionOfSav":
            return setInterventionOfSavCardStatus;

        default:
            return setProposalCardStatus;
    }
}