import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonNote,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    useIonModal
} from "@ionic/react";
import {
    addOutline,
    addSharp,
    checkmarkOutline,
    checkmarkSharp,
    logoEuro,
    trashOutline,
    trashSharp
} from "ionicons/icons";
import SearchPacAirEau from "./SearchPacAirEau";
import {Controller, FormProvider, useController, useForm, useFormContext, useWatch} from "react-hook-form";
import {PacAirEauWhiteIcon} from "../../Icons";
import styled from "@emotion/styled";
import {Product} from "../../../models/product.model";
import currency from 'currency.js';
import React, {useEffect} from "react";
import {TVA_55, VatRate} from "../../../valueObjects/VatRate";
import {useProductPricingAlertFunc} from "../hooks/useProductPricingAlert";

export default function PacAirEauItem() {
    const title = 'Pompe à chaleur AIR / EAU';

    const product = useWatch({
        name: "pacAirEau.product",
    });

    return (
        <>
            {
                !product && (
                    <Controller
                        name={"pacAirEau.product"}
                        render={
                            ({ field, fieldState, formState, }) => {
                                return (
                                    <AddButton
                                        title={title}
                                        onProductSelected={field.onChange}
                                    />
                                )
                            }
                        }
                    />
                )
            }

            {
                product && (
                    <Card title={title} />
                )
            }
        </>
    )
}

type AddButtonProps = {
    title: string,
    onProductSelected?(product: any): void,
}

function AddButton({ title, onProductSelected }: AddButtonProps) {
    const [present, dismiss] = useIonModal(Modal, {
        onDismiss: (data: string, role: string) => {
            (onProductSelected && role === 'confirm') && onProductSelected(data);
            dismiss(data, role);
        },
    });

    return (
        <IonRow>
            <IonCol>
                <IonButton color={"pac-air-eau"} fill={"solid"} expand={"block"} onClick={() => present()}>
                    <IonIcon md={addSharp} ios={addOutline} slot="start"/>
                    <IonText>
                        <span>{title}</span>
                    </IonText>
                </IonButton>
            </IonCol>
        </IonRow>
    )
}

type CardProps = {
    title: string,
}

function Card({ title }: CardProps) {
    const {
        field,
    } = useController({ name: 'pacAirEau.product' });

    const {setValue} = useFormContext();

    const [present, dismiss] = useIonModal(Modal, {
        onDismiss: (data: string, role: string) => {
            (field && role === 'confirm') && field.onChange(data);
            dismiss(data, role);
        },
    });

    const product = field.value as Product;

    const tvaTx = useWatch({ name: 'pacAirEau.tvaTx', defaultValue: TVA_55 });
    const totalHT = useWatch({ name: 'pacAirEau.totalHT', defaultValue: product.price });
    const totalTTC = useWatch({ name: 'pacAirEau.totalTTC', defaultValue: Number(product.price) * (1 + (Number(tvaTx) / 100)) });

    const ht = currency(totalHT);
    const baseTva = Number(tvaTx);
    const coeffTva = baseTva / 100;
    const montantTva = ht.multiply(coeffTva);
    const ttc = ht.add(montantTva);

    useEffect(() => {
        const myHt = currency(totalHT);
        const myCoeffTva = tvaTx / 100;
        const myTtc = myHt.multiply(1 + myCoeffTva);
        setValue('pacAirEau.totalTTC', myTtc.value);
    }, [totalHT, tvaTx]);

    useEffect(() => {
        const myTtc = currency(totalTTC);
        const myHt = myTtc.divide(1 + coeffTva);
        setValue('pacAirEau.totalHT', myHt.value);
    }, [totalTTC]);

    const resetProduct = () => {
        field.onChange(undefined);
    }

    // Alert changement de tarif
    const alertProductPriceRange = useProductPricingAlertFunc();
    const onBlur = () => {
        alertProductPriceRange({ product, tvaTx, totalTTC });
    }

    return (
        <IonCard style={{ border: '2px solid var(--ion-color-pac-air-eau)' }}>
            <IonCardHeader style={{ display: 'flex', background: 'var(--ion-color-pac-air-eau)' }} className={"ion-align-items-center"}>
                <PacAirEauWhiteIcon />

                <div style={{ marginLeft: 5 }}>
                    <IonCardSubtitle style={{ color: 'white' }}>{title}</IonCardSubtitle>
                    <IonCardTitle style={{ color: 'white' }}>
                        {product.label}
                    </IonCardTitle>
                </div>

                <IonButton fill="outline" style={{ marginLeft: 'auto' }} color={'light'} onClick={() => present()}>
                    Modifier
                </IonButton>
            </IonCardHeader>

            <IonCardContent>
                <IonGrid>
                    <IonRow>
                        <IonCol size={'6'}>
                            <Controller
                                name={"pacAirEau.actions"}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <IonRadioGroup value={value} onIonChange={({ detail }) => onChange(detail.value)}>
                                            <IonRow>
                                                <IonCol>
                                                    <IonItem>
                                                        <IonLabel>Releve chaudiere</IonLabel>
                                                        <IonRadio slot="start" color={'pac-air-eau'} value="RELEVE" />
                                                    </IonItem>
                                                </IonCol>

                                                <IonCol>
                                                    <IonItem>
                                                        <IonLabel>Suppression chaudiere</IonLabel>
                                                        <IonRadio slot="start" color={'pac-air-eau'} value="SUPPRESSION" />
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                        </IonRadioGroup>
                                    )
                                }}
                            />
                        </IonCol>

                        <IonCol size={'6'}>
                            <Controller
                                name={"pacAirEau.typeAncienneChaudiere"}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <IonRadioGroup value={value} onIonChange={({ detail }) => onChange(detail.value)}>
                                            <IonRow>
                                                <IonCol>
                                                    <IonItem>
                                                        <IonLabel>FIOUL</IonLabel>
                                                        <IonRadio slot="start" color={'pac-air-eau'} value="FIOUL" />
                                                    </IonItem>
                                                </IonCol>

                                                <IonCol>
                                                    <IonItem>
                                                        <IonLabel>GAZ</IonLabel>
                                                        <IonRadio slot="start" color={'pac-air-eau'} value="GAZ" />
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                        </IonRadioGroup>
                                    )
                                }}
                            />
                        </IonCol>
                    </IonRow>


                    <IonRow>
                        <IonCol>
                            <Item>
                                <IonLabel position="floating">Type de logement</IonLabel>
                                <Controller
                                    name={"pacAirEau.typeLogement"}
                                    render={
                                        ({ field }) => <IonInput onIonChange={field.onChange} />
                                    }
                                />
                            </Item>
                        </IonCol>

                        <IonCol>
                            <Item>
                                <IonLabel position="floating">Surface du logement</IonLabel>
                                <Controller
                                    name={"pacAirEau.surfaceLogement"}
                                    render={
                                        ({ field }) => <IonInput onIonChange={field.onChange} type={'number'} />
                                    }
                                />
                            </Item>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <Item>
                                <IonLabel position="floating">Marque et reference ancienne chaudiere</IonLabel>
                                <Controller
                                    name={"pacAirEau.marqueAncienneChaudiere"}
                                    render={
                                        ({ field }) => <IonInput onIonChange={field.onChange} />
                                    }
                                />
                            </Item>
                        </IonCol>
                    </IonRow>

                    <TotalRow>
                        <IonCol>
                            <IonItem lines={"none"}>
                                <IonNote slot={'start'}>HT</IonNote>
                                <Controller
                                    name={"pacAirEau.totalHT"}
                                    render={
                                        ({ field }) => <IonInput placeholder="TOTAL HT" type={'number'} value={field.value} onIonChange={field.onChange} onIonBlur={onBlur} />
                                    }
                                    defaultValue={ht.toString()}
                                />
                                <IonIcon icon={logoEuro} />
                            </IonItem>
                        </IonCol>

                        <IonCol>
                            <IonItem lines={"none"}>
                                <IonNote slot={'start'}>
                                    TVA
                                </IonNote>
                                <Controller
                                    name={"pacAirEau.tvaTx"}
                                    render={
                                        ({ field }) => (
                                            <IonSelect placeholder="TVA" value={field.value} onIonChange={field.onChange} cancelText={'Fermer'} onIonBlur={onBlur}>
                                                {
                                                    VatRate.PacAirEauRates().map(
                                                        r => (
                                                            <IonSelectOption key={r.label} value={r.value}>{r.label}</IonSelectOption>
                                                        )
                                                    )
                                                }
                                            </IonSelect>
                                        )
                                    }
                                    defaultValue={baseTva}
                                />
                                <IonNote slot={'end'}>
                                    % =
                                </IonNote>
                            </IonItem>
                        </IonCol>

                        <IonCol>
                            <IonItem lines={"none"}>
                                <IonInput placeholder="Montant TVA" readonly value={montantTva.toString()} />
                                <IonIcon icon={logoEuro} />
                            </IonItem>
                        </IonCol>

                        <IonCol>
                            <IonItem lines={"none"}>
                                <IonNote slot={'start'}>TTC</IonNote>
                                <Controller
                                    name={"pacAirEau.totalTTC"}
                                    render={
                                        ({ field }) => {
                                            return <IonInput type={"number"} placeholder="TOTAL TTC" value={field.value} onIonChange={field.onChange} onIonBlur={onBlur} />
                                        }
                                    }
                                    defaultValue={ttc.toString()}
                                />
                                <IonIcon icon={logoEuro} />
                            </IonItem>
                        </IonCol>
                    </TotalRow>

                    {/*{*/}
                    {/*    appliedPricing?.alertMessage && (*/}
                    {/*        <IonItem lines={"none"}>*/}
                    {/*            <IonIcon color="danger" md={alertCircleSharp} ios={alertCircleOutline} slot={'start'}/>*/}
                    {/*            <IonText color="danger">*/}
                    {/*                <p><b>{appliedPricing?.alertMessage}</b></p>*/}
                    {/*            </IonText>*/}
                    {/*        </IonItem>*/}
                    {/*    )*/}
                    {/*}*/}

                    <IonRow className={'ion-margin-top'}>
                        <IonCol>
                            <IonButton type={"button"} expand="block" color={"danger"} onClick={() => resetProduct()}>
                                <IonIcon slot={"start"} md={trashSharp} ios={trashOutline} />
                                Supprimer ce produit
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCardContent>
        </IonCard>
    )
}

const TotalRow = styled(IonRow)`
    padding: 0;
    
    ion-col {
        padding: 0;
    }

    ion-col ion-item {
        font-weight: bold;
    }
    
    ion-col:first-of-type ion-item {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    
    ion-col:not(last-child) ion-item {
        --background: #f4cbde;
    }

    ion-col:last-child {
        --background: var(--ion-color-pac-air-eau);
    }

    ion-col:last-child ion-note {
        color: white;
    }
    
    ion-col:last-child ion-item {
        --background: var(--ion-color-pac-air-eau);
    }

    ion-col:last-child ion-item {
        border-radius: 8px;
    }
    
    ion-col ion-input, ion-col ion-select {
        background: white;
        border-radius: 8px;
        margin-top: 6px;
        margin-bottom: 6px;
        --padding-start: 8px!important;
        font-weight: bold;
        font-size: 1.2em;
    }

    ion-icon {
        color: white;
    }
`;

const Item = styled(IonItem)`
    &.item-interactive.ion-focused, &.item-interactive.item-has-focus {
        --highlight-background: var(--ion-color-pac-air-eau);
    }

    &.item-has-focus .label-floating.sc-ion-label-md-h:not(.ion-color) {
        color: var(--ion-color-pac-air-eau);
    }
`;

const Modal = ({ onDismiss, }: { onDismiss: (data?: any | null | undefined | number, role?: string) => void; }) => {
    const methods = useForm();

    const { formState: { isValid } } = methods;

    const handleDismiss = () => {
        const { selectedProduct } = methods.getValues();
        onDismiss(selectedProduct, 'confirm');
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton color="medium" onClick={() => onDismiss(null, 'cancel')}>
                            Annuler
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Recherche pompe à chaleur Air/Eau</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <FormProvider {...methods}>
                    <SearchPacAirEau />
                </FormProvider>
            </IonContent>

            <IonButton onClick={handleDismiss} color={'pac-air-eau'} expand={"block"}>
                <IonIcon md={checkmarkOutline} ios={checkmarkSharp} slot="start" />
                Valider le choix
            </IonButton>
        </IonPage>
    );
};