import {useInvalidateProposalDetail, useProposalDetail} from "./useProposalDetail";
import {ProposalArrayOptions, ProposalLine, ProposalStatus} from "../../models/proposal.model";
import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemText,
    Menu,
    MenuItem,
    Skeleton,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonChip,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonNote,
    IonPopover,
    IonRow,
    IonSkeletonText,
    IonText,
    IonTitle,
    IonToolbar,
    useIonAlert,
    useIonModal
} from "@ionic/react";
import {useMutation, useQueries, useQuery, useQueryClient} from "@tanstack/react-query";
import {getThirdParty} from "../../calls/ThirdParties/getThirdParty";
import {getProduct} from "../../calls/Products/getProduct";
import {getOrder} from "../../calls/Affaire/getOrder";
import {getInvoice} from "../../calls/Affaire/getInvoice";
import {acceptProposal, rejectProposal} from "../../calls/Proposals/acceptOrRejectProposal";
import IonLoadingButton from "../Common/Lab/IonLoadingButton";
import {
    addOutline,
    addSharp,
    arrowForwardOutline,
    arrowForwardSharp,
    checkmarkCircleSharp,
    checkmarkOutline,
    checkmarkSharp,
    downloadOutline,
    downloadSharp,
    expandOutline,
    expandSharp,
    readerOutline,
    readerSharp,
    removeCircleOutline,
    removeCircleSharp,
    settingsOutline,
    settingsSharp,
    trashOutline,
    trashSharp
} from "ionicons/icons";
import {validateOrder} from "../../calls/Affaire/validateOrder";
import {closeOrder} from "../../calls/Affaire/closeOrder";
import {validateInvoice} from "../../calls/Affaire/validateInvoice";
import LoadingDots from "../LoadingDots";
import {DateTime, Duration} from "luxon";
import currency from "currency.js";
import {useInterventionsQuery} from "../Intervention/useInterventionsQuery";
import {InterventionType, interventionUI, VisiteTechnique} from "../../models/intervention.model";
import InterventionFormModal from "../Intervention/InterventionFormModal";
import {listInvoicePayments} from "../../calls/Affaire/listInvoicePayments";
import {PaymentMethodCode} from "../../models/payment.model";
import PaymentFormModal from "../Payment/PaymentFormModal";
import AddNewProposalLineModal from "./AddNewProposalLineModal";
import {ProposalLineFormModel} from "./ProposalLineForm";
import EditProposalLineModal from "./EditProposalLineModal";
import {deleteProposalLine} from "../../calls/Proposals/deleteProposalLine";
import React, {useMemo, useState} from "react";
import {FormattedNumber} from "react-intl";
import Page from "../../pages/Page";
import {useProposalEvents} from "./useProposalEvents";
import {CardStatus} from "../../models/kanban/card.model";
import {useSetCardStatus} from "../Kanban/useSetCardStatus";
import {FormProvider, useForm} from "react-hook-form";
import CardCommentForm from "../Kanban/CardCommentForm";
import {useAddCardComment} from "../Kanban/useAddCardComment";
import {kanbanKeys} from "../Kanban/kanbanKeys";
import {useSearchCardComments} from "../Kanban/useSearchCardComments";
import {compact} from "lodash";
import {ProductTypology} from "../Intervention/types";
import {useInterventionShowModalStore} from "../Intervention/store";
import InterventionShowModal from "../InterventionDetail/InterveventionShowModal";
import {listProposalDocumentTemplates} from "../../calls/Documents/listProposalDocumentTemplates";
import {fetchElementDocuments} from "../../calls/Documents/fetchElementDocuments";
import {buildDocument} from "../../calls/Documents/buildDocument";
import {useActiveCompanyId} from "../Company/company.store";
import useToggleOpen from "../Common/useToggleOpen";
import {downloadDocument} from "../../calls/Documents/downloadDocument";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {filesize} from "filesize";
import AttachmentsCard from "./AttachmentsCard";
import AddAttachmentButton from "../Documents/AddAttachmentButton";

export default function AffaireShow({ id }: { id: string }) {
    const { isError, isFetched, isLoading, data: proposal } = useProposalDetail(id);

    // On part du principe pour le moment qu'un proposal n'est lié qu'à une seule commande
    const orderId = Object.values(proposal?.linkedObjectsIds?.commande || {})[0];

    return (
        <Page>
            <IonContent className="ion-padding">
                {
                    isLoading && (
                        <IonItem lines={"none"} color={"light"}>
                            <IonText color={'medium'} style={{ marginRight: 5 }}>
                                <span>Veuillez patienter</span>
                            </IonText>
                            <LoadingDots />
                        </IonItem>
                    )
                }

                {
                    !isLoading && (
                        <IonRow>
                            <IonCol size={'9'}>
                                <IonToolbar>
                                    <IonButtons slot="start">
                                        <IonBackButton />
                                    </IonButtons>
                                    <IonTitle className={'ion-no-padding ion-align-items-center'}>
                                        {
                                            isLoading
                                                ? (<IonSkeletonText animated={true} style={{ 'width': '80px' }} />)
                                                : <>Devis {proposal?.ref} <ProposalStatusChip status={proposal?.status || 0} /></>
                                        }
                                    </IonTitle>

                                    <ActionsButtons
                                        proposalId={proposal?.id || ''}
                                        proposalRef={proposal?.ref || ''}
                                        orderId={orderId}
                                        status={proposal?.status || 0}
                                    />
                                </IonToolbar>

                                <IonItem lines={"none"} style={{ '--min-height': 'unset' }}>
                                    <IonLabel className={'ion-no-margin'}>
                                        <p>Date du devis <DateItem value={proposal?.date || 0} />, Fin de validité <DateItem value={proposal?.fin_validite || 0} /></p>
                                    </IonLabel>
                                </IonItem>

                                <StyledIonCard>
                                    <IonCardHeader>
                                        <IonCardSubtitle>Détails du devis</IonCardSubtitle>
                                    </IonCardHeader>

                                    <IonCardContent>
                                        <TableContainer>
                                            <Table sx={{ minWidth: 650 }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Produit</TableCell>
                                                        <TableCell align="right">Qté</TableCell>
                                                        <TableCell align="right">P.U HT</TableCell>
                                                        <TableCell align="right">Remise</TableCell>
                                                        <TableCell align="right">TVA</TableCell>
                                                        <TableCell align="right">Prix TTC</TableCell>
                                                        <TableCell align="right">Actions</TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {
                                                        (proposal?.lines || []).map((line) => (
                                                            <TableRow
                                                                key={line.ref}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <ProductCell line={line} />
                                                                <TableCell align="right">{line.qty}</TableCell>
                                                                <TableCell align="right">
                                                                    <FormattedNumber
                                                                        value={Number(line.subprice)}
                                                                        style="currency"
                                                                        currency={"EUR"}
                                                                    />
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <FormattedNumber
                                                                        style='percent'
                                                                        value={Number(line.remise_percent || 0) / 100}
                                                                        minimumFractionDigits={2}
                                                                    />
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <FormattedNumber
                                                                        style='percent'
                                                                        value={Number(line.tva_tx || 0) / 100}
                                                                        minimumFractionDigits={2}
                                                                    />
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <FormattedNumber
                                                                        value={Number(line.total_ttc)}
                                                                        style="currency"
                                                                        currency={"EUR"}
                                                                    />
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <EditLineButton
                                                                        proposalId={proposal?.id || ''}
                                                                        line={line}
                                                                    />

                                                                    <DeleteLineButton
                                                                        proposalId={proposal?.id || ''}
                                                                        lineId={line.id || ''}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        <Divider />

                                        <IonRow>
                                            <IonCol size={'6'}>
                                                <AddProposalLineButton id={proposal?.id || ''} />
                                            </IonCol>
                                            <IonCol size={'6'}>
                                                <IonList>
                                                    <IonItem>
                                                        <IonLabel>
                                                            <h2>
                                                                Total HT
                                                            </h2>
                                                        </IonLabel>
                                                        <IonNote slot="end">
                                                            <CurrencyItem value={proposal?.total_ht || 0} />
                                                        </IonNote>
                                                    </IonItem>

                                                    <IonItem>
                                                        <IonLabel>
                                                            <h2>
                                                                Montant TVA
                                                            </h2>
                                                        </IonLabel>
                                                        <IonNote slot="end">
                                                            <CurrencyItem value={proposal?.total_tva || 0} />
                                                        </IonNote>
                                                    </IonItem>

                                                    <IonItem>
                                                        <IonLabel>
                                                            <h2>
                                                                Total TTC
                                                            </h2>
                                                        </IonLabel>
                                                        <IonNote slot="end">
                                                            <CurrencyItem value={proposal?.total_ttc || 0} />
                                                        </IonNote>
                                                    </IonItem>
                                                </IonList>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </StyledIonCard>

                                <ProposalCommentsCard proposalId={proposal?.id as string} />
                            </IonCol>

                            <IonCol size={'3'}>
                                <StyledIonCard>
                                    <IonCardHeader>
                                        <IonCardSubtitle>Information client</IonCardSubtitle>
                                    </IonCardHeader>

                                    <IonList>
                                        <CustomerItems id={proposal?.socid as string} />
                                    </IonList>
                                </StyledIonCard>

                                <AttachmentsCard
                                    elementId={proposal?.id || ''}
                                    elementType={'proposal'}
                                    slots={{
                                        generateButton: <GenerateProposalPdf proposalId={proposal?.id || ''} />
                                    }}
                                />

                                <InvoiceDocuments orderId={orderId} />

                                <AddPaymentCard orderId={orderId} />

                                <ScheduleInterventionCard
                                    proposalId={proposal?.id || ''}
                                    thirdPartyId={proposal?.socid as string}
                                />

                                <ProposalEventsCard
                                    proposalId={proposal?.id || ''}
                                />
                            </IonCol>
                        </IonRow>
                    )
                }
            </IonContent>
        </Page>
    )
}

function ProductCell({ line }: { line: ProposalLine }) {
    const hasProduct = !!line.fk_product;

    return (
        <TableCell component="th" scope="row">
            {
                hasProduct ? (
                    <IonLabel>
                        <h2>
                            {line.ref}
                        </h2>
                        <h3>{line.libelle}</h3>
                    </IonLabel>
                ) : (
                    <IonLabel>
                        <h2>
                            {line.label}
                        </h2>
                        <h3>{line.description}</h3>
                    </IonLabel>
                )
            }
        </TableCell>
    )
}

const StyledIonCard = styled(IonCard)`
    --background: white;

    & ion-item, & ion-list {
        background: white;
        --background: white;
    }
`;

function useThirdPartyDetail(id: number | string) {
    return useQuery(['thirdParty', id], ({ queryKey }) => {
        return getThirdParty(queryKey[1] as string)
    });
}

/**
 * Pour get le product typologie
 * @param id
 */
function useProductDetail(id: number | string) {
    return useQuery(['product', id], ({ queryKey }) => {
        return getProduct(queryKey[1] as string)
    });
}

/**
 * Pour activer ou pas le bouton saisir un règlement
 * @param orderId
 */
function useOrderAndInvoiceDetail(orderId: number | string) {
    const { data: order, isLoading: orderLoading } = useQuery(['order', orderId], ({ queryKey }) => {
        return getOrder(queryKey[1] as string)
    }, {
        enabled: !!orderId,
    });

    const linkedInvoicesIds = Object.values(order?.linkedObjectsIds?.facture || {});

    const queries = useQueries({
        queries: linkedInvoicesIds.map(id => ({
            queryKey: ['invoice', id],
            queryFn: () => getInvoice(id),
            enabled: !!order?.id,
        })),
    });

    return {
        order,
        orderLoading,
        invoicesLoading: queries.some(q => q.isLoading),
        invoices: queries.map(q => q.data),
    }
}

function ProposalStatusChip({ status }: { status: number }) {
    if (!status) {
        return <></>;
    }

    if (status === ProposalStatus.NOT_SIGNED) {
        return <IonChip color="danger">Refusé</IonChip>
    }

    if ([ProposalStatus.VALIDATED, ProposalStatus.SIGNED].indexOf(status) !== -1) {
        return (
            <IonChip color="success">
                {{
                    [ProposalStatus.VALIDATED]: 'Validé',
                    [ProposalStatus.SIGNED]: 'Accepté et signé'
                }[status]}
            </IonChip>
        )
    }

    if (status === ProposalStatus.BILLED) {
        return <IonChip color="tertiary">Facturé</IonChip>
    }

    return <></>;
}

function ActionsButtons({ status, proposalId, proposalRef, orderId }: { status: number, proposalId: string, proposalRef: string, orderId: number }) {
    const qc = useQueryClient();

    return (
        <IonButtons slot="end">
            <AddAttachmentButton
                reference={proposalRef}
                modulepart={'proposal'}
                onUploaded={() => qc.invalidateQueries({ queryKey: ["attachments", proposalId] })}
            />

            {
                status === ProposalStatus.VALIDATED && (
                    <>
                        <RefuseButton proposalId={proposalId} />

                        <AcceptAndSignButton proposalId={proposalId} />
                    </>
                )
            }

            {
                // On facture la commande pour créer automatiquement la facture
                status === ProposalStatus.SIGNED && (
                    <>
                        <InvoicedButton
                            proposalId={proposalId}
                            orderId={orderId}
                        />
                    </>
                )
            }

            <TagButton proposalId={proposalId} />
        </IonButtons>
    )
}

function RefuseButton({ proposalId }: { proposalId: string }) {
    const [presentAlert] = useIonAlert();

    const { mutateAsync, isLoading } = useMutation(() => rejectProposal(proposalId));

    const invalidateProposal = useInvalidateProposalDetail(proposalId);

    return (
        <IonLoadingButton
            loading={isLoading}
            fill="solid"
            color="danger"
            onClick={() => {
                presentAlert({
                    header: 'Refuser devis',
                    message: 'Êtes-vous sûr de vouloir refuser ce devis ?',
                    buttons: [
                        {
                            text: 'Annuler',
                            role: 'cancel',
                        },
                        {
                            text: 'Refuser',
                            role: 'confirm',
                        },
                    ],
                    onDidDismiss: async (e: CustomEvent) => {
                        const isConfirmed = e.detail.role === 'confirm';

                        if (!isConfirmed) {
                            return;
                        }

                        await mutateAsync();
                        await invalidateProposal();
                    }
                })
            }}
        >
            <IonIcon slot="start" ios={removeCircleOutline} md={removeCircleSharp}></IonIcon>
            Refuser
        </IonLoadingButton>
    )
}

function AcceptAndSignButton({ proposalId }: { proposalId: string }) {
    const [presentAlert] = useIonAlert();

    const { mutateAsync: acceptProposalAsync, isLoading: acceptingProposal } = useMutation(() => acceptProposal(proposalId));
    const { mutateAsync: validateOrderAsync, isLoading: validatingOrder } = useMutation((orderId: number) => validateOrder(orderId));

    const invalidateProposal = useInvalidateProposalDetail(proposalId);

    return (
        <IonLoadingButton
            loading={acceptingProposal || validatingOrder}
            fill="solid"
            color="primary"
            onClick={() =>
                presentAlert({
                    header: 'Accepter et signer devis',
                    message: 'Êtes-vous sûr de vouloir accepter et signer ce devis ?',
                    buttons: [
                        {
                            text: 'Annuler',
                            role: 'cancel',
                        },
                        {
                            text: 'Accepter / Signer',
                            role: 'confirm',
                        },
                    ],
                    onDidDismiss: async (e: CustomEvent) => {
                        const isConfirmed = e.detail.role === 'confirm';

                        if (!isConfirmed) {
                            return;
                        }

                        const p = await acceptProposalAsync();

                        try {
                            // On part du principe pour le moment qu'un proposal n'est lié qu'à une seule commande
                            const newOrderId = Object.values(p?.linkedObjectsIds?.commande || {})[0];

                            await validateOrderAsync(newOrderId);
                        } catch (e) {
                            console.error(e);
                        }

                        await invalidateProposal();
                    }
                })
            }
        >
            <IonIcon slot="start" ios={checkmarkOutline} md={checkmarkSharp}></IonIcon>
            Accepter & Signer
        </IonLoadingButton>
    )
}

function InvoicedButton({ proposalId, orderId }: { proposalId: string, orderId: number }) {
    const [presentAlert] = useIonAlert();

    const { mutateAsync, isLoading } = useMutation(() => closeOrder(orderId));
    const { mutateAsync: validateInvoiceAsync, isLoading: validatingInvoice } = useMutation((orderId: number) => validateInvoice(orderId));

    const invalidateProposal = useInvalidateProposalDetail(proposalId);

    return (
        <IonLoadingButton
            loading={isLoading || validatingInvoice}
            fill="solid"
            color="success"
            onClick={() => {
                presentAlert({
                    header: 'Commencer facturation devis',
                    message: 'Êtes-vous sûr de vouloir commencer la facturation sur ce devis ?',
                    buttons: [
                        {
                            text: 'Annuler',
                            role: 'cancel',
                        },
                        {
                            text: 'Commencer',
                            role: 'confirm',
                        },
                    ],
                    onDidDismiss: async (e: CustomEvent) => {
                        const isConfirmed = e.detail.role === 'confirm';

                        if (!isConfirmed) {
                            return;
                        }

                        const o = await mutateAsync();

                        // On part du principe pour le moment qu'un ORDER n'est lié qu'à une seule facture
                        const newInvoiceId = Object.values(o?.linkedObjectsIds?.facture || {})[0];

                        // validation de la facture
                        await validateInvoiceAsync(newInvoiceId);

                        await invalidateProposal();
                    }
                })
            }}
        >
            <IonIcon slot="start" ios={readerOutline} md={readerSharp}></IonIcon>
            Facturer
        </IonLoadingButton>
    )
}

function TagButton({ proposalId }: { proposalId: string }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [presentAlert] = useIonAlert();

    const { mutateAsync: setStatusAsync, isLoading } = useSetCardStatus();

    const qc = useQueryClient();

    const handleMenuItemClick = async (label: string, status: CardStatus) => {
        await presentAlert({
            header: label,
            message: 'Êtes-vous sûr de vouloir appliquer cette action ?',
            buttons: [
                {
                    text: 'Annuler',
                    role: 'cancel',
                },
                {
                    text: 'Appliquer',
                    role: 'confirm',
                },
            ],
            onDidDismiss: async (e: CustomEvent) => {
                const isConfirmed = e.detail.role === 'confirm';

                if (!isConfirmed) {
                    return;
                }

                await setStatusAsync({
                    cardId: +proposalId,
                    status: status,
                });

                // refresh des events des proposals
                await qc.invalidateQueries({ queryKey: ['proposal', proposalId, 'events'] });
            }
        });

        handleClose();
    }

    const actions = useMemo(() => {
        return [
            {
                label: "En Attente VT",
                status: CardStatus.WAITING_VT,
            },
            {
                label: "VT Planifiée",
                status: CardStatus.VT_PLANIFIEES,
            },
            {
                label: "VT terminée",
                status: CardStatus.VT_DONE,
            },
            {
                label: "VT Annulée",
                status: CardStatus.VT_CANCELLED,
                divider: true,
            },
            {
                label: "Validation Bureau d'étude",
                status: CardStatus.VALIDATED_BE,
            },
            {
                label: "Dossier envoyé à la mairie",
                status: CardStatus.WAITING_MAIRIE,
            },
            {
                label: "Validation mairie",
                status: CardStatus.VALIDATED_MAIRIE,
            },
            {
                label: "Refus Bureau d'étude",
                status: CardStatus.DENIED_BE,
                divider: true,
            },
            {
                label: "Validation Service Commercial",
                status: CardStatus.VALIDATED_COMMERCIAL,
            },
            {
                label: "En attente accord banque",
                status: CardStatus.WAITING_BANK_AGREEMENT,
            },
            {
                label: "En attente accord client",
                status: CardStatus.WAITING_CUSTOMER_AGREEMENT,
            },
            {
                label: "En attente accord financement",
                status: CardStatus.WAITING_FINANCING_AGREEMENT,
            },
            {
                label: "Dossiers commissionnables",
                status: CardStatus.COMMISSIONABLE_CASE,
            },
            {
                label: "Demande d'annulation",
                status: CardStatus.CANCELLATION_REQUEST,
            },
            {
                label: "Dossiers annulés",
                status: CardStatus.CANCELLED_CASE,
                divider: true,
            },
            {
                label: "Installations à planifier",
                status: CardStatus.INSTALLATION_TO_BE_PLANNED,
            },
            {
                label: "Installations programmées",
                status: CardStatus.PLANNED_INSTALLATION,
            },
            {
                label: "Installations non faites",
                status: CardStatus.NO_INSTALLATION,
            },
            {
                label: 'Installations non terminées',
                status: CardStatus.UNCOMPLETED_INSTALLATION,
            }
        ]
    }, []);

    return (
        <>
            <IonLoadingButton
                loading={isLoading}
                fill="solid"
                color="light"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <IonIcon slot="start" ios={settingsOutline} md={settingsSharp} />
                Actions
            </IonLoadingButton>

            <Menu
                id="proposal-advaced-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {
                    actions.map(
                        a => ([
                            <MenuItem key={a.status} onClick={() => handleMenuItemClick(a.label, a.status)}>
                                {a.label}
                            </MenuItem>,
                            a?.divider && (<Divider />),
                        ]).filter(a => !!a)
                    )
                }
            </Menu>
        </>
    )
}

function CustomerItems({ id }: { id: string }) {
    const { isError, isFetched, isLoading, data: customer } = useThirdPartyDetail(id);

    if (isLoading) {
        return <LoadingDots />;
    }

    const address = [customer?.address, customer?.zip, customer?.town].filter(part => !!part).join(',');

    return (
        <>
            <IonItem lines={"none"}>
                <IonLabel>
                    <h2>{customer?.array_options?.options_civilite || ''} {customer?.firstname} {customer?.name}</h2>
                    <p>{address}</p>
                </IonLabel>

                <IonButton slot="end" fill="clear" routerLink={`/page/ThirdParties/${id}`} routerDirection={"root"}>
                    <IonIcon slot="icon-only" md={arrowForwardSharp} ios={arrowForwardOutline} />
                </IonButton>
            </IonItem>

            {/*<IonItem>*/}
            {/*    <IonLabel>*/}
            {/*        <h2>{customer?.phone}</h2>*/}
            {/*        <p>Téléphone</p>*/}
            {/*    </IonLabel>*/}
            {/*</IonItem>*/}

            {/*<IonItem>*/}
            {/*    <IonLabel>*/}
            {/*        <h2>{customer?.email}</h2>*/}
            {/*        <p>Email</p>*/}
            {/*    </IonLabel>*/}
            {/*</IonItem>*/}
        </>
    )
}

function DateItem({ value }: { value: number }) {
    const d = DateTime.fromSeconds(value, { zone: "Europe/Paris" }).setLocale('fr');
    return <>{d.toLocaleString(DateTime.DATE_SHORT)}</>
}

function CurrencyItem({ value }: { value: number | string }) {
    const c = currency(value);
    return <IonText color={'primary'}>
        <p><b>{c.value} €</b></p>
    </IonText>;
}
function ProposalCommentsCard({ proposalId }: { proposalId: string }) {
    const methods = useForm<{ comment: string }>();

    const { data, isLoading, isError, } = useSearchCardComments({ cardId: +proposalId });
    const totalCount = data?.pagination?.itemsCount || 0;
    const comments = data?.items || [];

    const queryClient = useQueryClient();
    const { mutateAsync, isLoading: addingComment } = useAddCardComment();

    const onSubmit = async ({ comment }: { comment: string }) => {
        await mutateAsync({ cardId: +proposalId, comment });
        await queryClient.invalidateQueries({ queryKey: kanbanKeys.cardComments(+proposalId) });
        methods.reset({ comment: "" });
    }

    return (
        <StyledIonCard>
            <IonCardHeader>
                <IonCardSubtitle>Commentaires / Notes ({totalCount})</IonCardSubtitle>
            </IonCardHeader>

            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {
                    (isLoading) && (
                        <>Chargement des commentaires ...</>
                    )
                }

                {
                    (isError) && (
                        <>Aucun commentaires</>
                    )
                }

                {
                    (!isLoading && !isError) && (
                        <>
                            {
                                comments.map(
                                    ({ authorFirstname, authorLastname, comment, createdAt }, index) => {
                                        const author = compact([authorFirstname, authorLastname]).join(' ');

                                        const fromFormat= 'yyyy-MM-dd HH:mm:ss';
                                        const toFormat= "dd/MM/yyyy 'à' HH:mm";
                                        const commentDate = DateTime.fromFormat(createdAt, fromFormat).toFormat(toFormat)

                                        return (
                                            <ListItem key={`Card-${proposalId}-Comment-${index}`} alignItems="flex-start" dense>
                                                <ListItemText
                                                    primary={
                                                        <React.Fragment>
                                                            <Typography
                                                                sx={{ display: 'inline', fontWeight: 'bold' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                {author}
                                                            </Typography>

                                                            <Typography
                                                                component="span"
                                                                variant="body2"
                                                            >
                                                                {` — ${commentDate}`}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography
                                                                component="span"
                                                                variant="body1"
                                                                color="text.primary"
                                                            >
                                                                {comment}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        )
                                    }
                                )
                            }
                        </>
                    )
                }

            </List>

            <Box
                sx={{
                    width: '100%',
                    pt: 3,
                    pb: 3,
                    pl: 2,
                    pr: 2,
                }}
            >
                <FormProvider {...methods}>
                    <CardCommentForm onSubmit={methods.handleSubmit(onSubmit)} loading={addingComment} />
                </FormProvider>
            </Box>
        </StyledIonCard>
    );
}

function ProposalEventsCard({ proposalId }: { proposalId: string }) {
    const { data, isLoading, isError } = useProposalEvents(proposalId);

    const items = data?.items || [];
    const itemsCount = data?.pagination?.itemsCount || 0;

    const fromFormat= 'yyyy-MM-dd HH:mm:ss';
    const toFormat= 'dd/MM/yyyy HH:mm:ss';

    return (
        <StyledIonCard>
            <IonCardHeader>
                <IonCardSubtitle>Évènements ({itemsCount})</IonCardSubtitle>
            </IonCardHeader>

            {
                isLoading ? (
                    <LoadingDots />
                ) : (
                    isError || !data || items.length === 0 ? (
                        <IonCardContent>
                            Aucun évènement pour cette affaire
                        </IonCardContent>
                    ) : (
                        <IonList>
                            {
                                items.map(
                                    (item, i) => {
                                        return (
                                            <IonItem
                                                key={`${item.label}-${i}`}
                                                lines={"none"}
                                            >
                                                <IonIcon color={"success"} md={checkmarkCircleSharp} ios={checkmarkOutline} slot="start"></IonIcon>
                                                <IonLabel>
                                                    <h3>{item.label}</h3>
                                                    <p>{item.author}</p>
                                                </IonLabel>
                                                <IonNote slot="end">
                                                    {DateTime.fromFormat(item.eventDate, fromFormat).toFormat(toFormat)}
                                                </IonNote>
                                            </IonItem>
                                        )
                                    }
                                )
                            }
                        </IonList>
                    )
                )
            }
        </StyledIonCard>
    );
}

const upRightFromSquareIcon = '/assets/icon/up-right-from-square.svg';

function ScheduleInterventionCard({ proposalId, thirdPartyId }: { proposalId: string, thirdPartyId: string }) {
    const { data, isLoading, isError } = useInterventionsQuery({
        customerId: String(thirdPartyId),
    });

    const items = data?.docs || [];
    const itemsCount = data?.totalDocs || 0;

    const { isOpen, selectInterventionId, show, hide } = useInterventionShowModalStore();

    return (
        <StyledIonCard>
            <IonCardHeader>
                <IonCardSubtitle>Interventions programmées ({itemsCount})</IonCardSubtitle>
            </IonCardHeader>

            {
                isLoading ? (
                    <LoadingDots />
                ) : (
                    isError || !data || items.length === 0 ? (
                        <IonCardContent>
                            Aucune intervention programmée pour cette affaire
                        </IonCardContent>
                    ) : (
                        <IonList>
                            {
                                items.map(
                                    item => {
                                        const description = interventionUI.typeTranslation[item.type as InterventionType];

                                        if (item.type === InterventionType.POSE) {
                                            const startDate = DateTime.fromISO(item.typeData.theoricalStartDate, { zone: "Europe/Paris" }).setLocale('fr');
                                            const endDate = DateTime.fromISO(item.typeData.theoricalEndDate, { zone: "Europe/Paris" }).setLocale('fr');

                                            return (
                                                <IonItem
                                                    key={item.id}
                                                    lines={"none"}
                                                >
                                                    <IonLabel>
                                                        <h3>{description}</h3>
                                                        <p>Début: {startDate.toFormat('dd/MM/yyyy HH:mm')}</p>
                                                        <p>Fin: {endDate.toFormat('dd/MM/yyyy HH:mm')}</p>
                                                    </IonLabel>

                                                    <IonButton slot="end" size="small"  fill="clear" onClick={() => show(item.id)}>
                                                        <IonIcon slot="icon-only" md={expandSharp} ios={expandOutline}></IonIcon>
                                                    </IonButton>

                                                    <IonButton slot="end" size="small"  fill="clear" routerLink={`/page/Interventions/${item.id}`} routerDirection={'root'}>
                                                        <IonIcon slot="icon-only" icon={upRightFromSquareIcon}></IonIcon>
                                                    </IonButton>
                                                </IonItem>
                                            )
                                        }

                                        const intervention = item as VisiteTechnique;

                                        const date = intervention.date
                                            ? DateTime.fromISO(intervention!.date, { zone: "Europe/Paris" }).setLocale('fr').toFormat('dd/MM/yyyy HH:mm')
                                            : 'Non planifiée'
                                        ;

                                        const duration = Duration.fromObject({ seconds:  Number(intervention.typeData.duration || 0) }).toFormat('hh:mm');

                                        return (
                                            <IonItem
                                                key={item.id}
                                                lines={"none"}
                                            >
                                                <IonLabel>
                                                    <h3>{description}</h3>
                                                    <p>Date: {date}</p>
                                                    <p>Durée: {duration}</p>
                                                </IonLabel>

                                                <IonButton slot="end" size="small"  fill="clear" onClick={() => show(item.id)}>
                                                    <IonIcon slot="icon-only" md={expandSharp} ios={expandOutline}></IonIcon>
                                                </IonButton>

                                                <IonButton slot="end" size="small"  fill="clear" routerLink={`/page/Interventions/${item.id}`} routerDirection={'root'}>
                                                    <IonIcon slot="icon-only" icon={upRightFromSquareIcon}></IonIcon>
                                                </IonButton>
                                            </IonItem>
                                        )
                                    }
                                )
                            }
                        </IonList>
                    )
                )
            }

            <InterventionShowModal
                id={selectInterventionId}
                isOpen={isOpen}
                handleClose={hide}
            />

            <ScheduleInterventionButton
                proposalId={proposalId}
                thirdPartyId={thirdPartyId}
            />
        </StyledIonCard>
    )
}

function ScheduleInterventionButton({ proposalId, thirdPartyId }: { proposalId: string, thirdPartyId: string }) {
    const { isLoading: proposalLoading, data: proposal } = useProposalDetail(proposalId);
    const { isLoading: thirdPartyLoading, data: thirdParty } = useThirdPartyDetail(thirdPartyId);

    const projectId = thirdParty?.array_options?.options_related_project || '0';

    const typologies: ProductTypology[] = (proposal?.lines || [])
        .map(line => line.array_options?.options_product_typology as ProductTypology)
        .filter(e => !!e)
    ;

    const coords = [
        Number(thirdParty?.array_options?.options_lat || '0'),
        Number(thirdParty?.array_options?.options_lng || '0'),
    ];

    const baseProps = {
        proposal: {
            id: +proposalId,
            origin: proposal?.array_options?.options_origine_affaire,
            originId: proposal?.array_options?.options_foire_origine,
            productTypology: typologies[0],
        },
        thirdParty: {
            id: +thirdPartyId,
            name: thirdParty?.name || '',
        },
        place: {
            address: thirdParty?.address || '',
            town: thirdParty?.town || '',
            zipCode: thirdParty?.zip || '',
            coords
        },
        projectId: +projectId,
    }

    return (
        <>
            <IonButton id="schedule-intervention" fill="clear" expand={"block"} disabled={thirdPartyLoading}>
                <IonIcon slot="start" ios={addOutline} md={addSharp}></IonIcon>
                Programmer intervention
            </IonButton>

            <IonPopover trigger="schedule-intervention" dismissOnSelect={false}>
                <IonContent>
                    <IonList>
                        <IonItem button={true} lines={"none"} id="schedule-intervention-vt">
                            Programmer une VT
                        </IonItem>

                        <InterventionFormModal
                            {...baseProps}
                            trigger={"schedule-intervention-vt"}
                            defaultInterventionType={InterventionType.VISITE_TECHNIQUE}
                        />

                        <IonItem button={true} lines={"none"} id="schedule-intervention-pose">
                            Programmer une Pose
                        </IonItem>

                        <InterventionFormModal
                            {...baseProps}
                            trigger={"schedule-intervention-pose"}
                            defaultInterventionType={InterventionType.POSE}
                        />
                    </IonList>
                </IonContent>
            </IonPopover>
        </>
    )
}

function ModePaiementCard({ opts }: { opts: ProposalArrayOptions }) {
    return (
        <StyledIonCard>
            <IonCardHeader>
                <IonCardSubtitle>Mode de paiement</IonCardSubtitle>
            </IonCardHeader>
        </StyledIonCard>
    )
}

function GenerateProposalPdf({ proposalId }: { proposalId: string }) {
    const { data: proposal } = useProposalDetail(proposalId);

    const qc = useQueryClient();

    const { mutateAsync: buildDoc, isLoading } = useMutation(async () => {
        await buildDocument({
            modulepart: 'propal',
            langcode: 'fr_FR',
            original_file: proposal!.ref,
        });

        await qc.invalidateQueries({ queryKey: ["attachments", proposalId] });
    });

    return (
        <IonLoadingButton
            size="small"
            fill="outline"
            style={{ marginLeft: 'auto' }}
            disabled={!proposal}
            onClick={() => buildDoc()}
            loading={isLoading}
        >
            Générer
        </IonLoadingButton>
    )
}

function ProposalDocuments({ proposalId }: { proposalId: string }) {
    const { data: proposal } = useProposalDetail(proposalId);

    const { data: templates, isLoading, error } = useQuery(['docTemplates'], listProposalDocumentTemplates, {
        enabled: !!proposalId,
    });

    const { data, refetch: refetchDoc } = useQuery(["proposal", proposalId, "documents"], () => fetchElementDocuments({ id: proposalId }, 'proposal', {
        sortfield: "date",
        sortorder: "DESC",
    }), {
        enabled: !!proposalId,
    });

    const { mutateAsync: buildDoc } = useMutation(async () => {
        await buildDocument({
            modulepart: 'propal',
            langcode: 'fr_FR',
            original_file: proposal!.ref,
        });

        await refetchDoc();
    });

    const companyId = useActiveCompanyId();

    const { open, show, hide } = useToggleOpen();
    const [pdfData, setPdfData] = useState("");
    const handleClose = () => {
        hide();
        setPdfData("");
    }

    const { mutateAsync: download } = useMutation(async ({ originalFile }: { originalFile: string }) => {
        const { content } = await downloadDocument({
            modulepart: 'propal',
            original_file: originalFile,
            entity: +companyId,
            attachment: 0,
        });

        setPdfData(content);
        show();
    })

    const documents = data || [];

    return (
        <>
            <StyledIonCard>
                <IonCardHeader style={{ display: 'flex' }} className={"ion-align-items-center"}>
                    <IonCardSubtitle>Documents ({ documents.length })</IonCardSubtitle>

                    <IonButton fill="outline" style={{ marginLeft: 'auto' }} disabled={!proposal} onClick={() => buildDoc()}>
                        Générer
                    </IonButton>
                </IonCardHeader>

                {
                    isLoading ? (
                        <IonCardContent>
                            <Skeleton animation="wave" />

                            <Skeleton animation="wave" width="80%" />
                        </IonCardContent>
                    ) :  (
                        <IonList>
                            {
                                documents.map(
                                    d => {
                                        const size = filesize(d.size);

                                        const dateText = d.date ? DateTime
                                            .fromSeconds(d.date, { zone: "Europe/Paris" })
                                            .setLocale('fr')
                                            .toFormat('dd/MM/yyyy HH:mm') : "";

                                        const originalFile = `${d.level1name}/${d.name}`;

                                        return (
                                            <IonItem key={d.name} button={true} lines={"none"} id="schedule-intervention-vt" onClick={() => download({ originalFile })}>
                                                <IonIcon aria-hidden="true" md={downloadSharp} ios={downloadOutline} slot="start" />

                                                <IonLabel>
                                                    <h3>{d.name}</h3>
                                                    <p>{dateText}</p>
                                                </IonLabel>

                                                <IonNote slot="end">{size}</IonNote>
                                            </IonItem>
                                        )
                                    }
                                )
                            }
                        </IonList>
                    )
                }
            </StyledIonCard>
            <Dialog fullScreen={true} onClose={handleClose} open={open}>
                <AppBar color={"inherit"} sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Aperçu
                        </Typography>

                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                {
                    pdfData && (
                        <iframe
                            style={{
                                height: 'calc(100vh - 64px)'
                            }}
                            src={`data:application/pdf;base64,${pdfData}`}
                        />
                    )
                }
            </Dialog>
        </>
    )
}

function GenerateInvoicePdf({ orderId }: { orderId: string }) {
    const {invoices } = useOrderAndInvoiceDetail(orderId);

    // On récupère le premier invoice
    const invoice = invoices[0];
    const hasInvoice = !!invoice;
    const invoiceId = invoice?.id || '0';

    const qc = useQueryClient();

    const { mutateAsync: buildDoc, isLoading } = useMutation(async () => {
        await buildDocument({
            modulepart: 'invoice',
            langcode: 'fr_FR',
            original_file: invoice?.ref || '',
        });

        await qc.invalidateQueries({ queryKey: ["attachments", invoiceId] });
    });

    return (
        <IonLoadingButton
            size="small"
            fill="outline"
            style={{ marginLeft: 'auto' }}
            disabled={!hasInvoice}
            onClick={() => buildDoc()}
            loading={isLoading}
        >
            Générer
        </IonLoadingButton>
    )
}

function InvoiceDocuments({ orderId }: { orderId: number }) {
    const { orderLoading, invoicesLoading, invoices } = useOrderAndInvoiceDetail(orderId);

    // On récupère le premier invoice
    const invoice = invoices[0];
    const hasInvoice = !!invoice;
    const invoiceId = invoice?.id || '0';

    return (
        <AttachmentsCard
            label={'Facture'}
            elementId={invoiceId}
            elementType={'invoice'}
            slots={{
                generateButton: <GenerateInvoicePdf orderId={orderId.toString()} />
            }}
        />
    )
}

function AddPaymentCard({ orderId }: { orderId: number }) {
    const { orderLoading, invoicesLoading, invoices } = useOrderAndInvoiceDetail(orderId);

    // On récupère le premier invoice
    const invoice = invoices[0];
    const hasInvoice = !!invoice;
    const invoiceId = invoice?.id || '0';

    const remainToPay = invoice?.remaintopay || '0';

    const { data, isLoading: paymentsIsLoading } = useQuery(['invoice', invoiceId, 'payments'], () => listInvoicePayments(invoiceId), {
        enabled: !!invoiceId,
    });

    // On disabled le bouton si
    // - chargement des commandes
    // - chargement des invoice
    // - si pas d'invoice
    // - si multi invoice detecté (pour le moment on ne gère pas)
    // - si plus rien à payer simplement
    const disabled = orderLoading || invoicesLoading || invoices.length === 0 || invoices.length > 1 || Number(remainToPay) === 0;

    const payments = data || [];

    return (
        <StyledIonCard>
            <IonCardHeader>
                <IonCardSubtitle>Règlements - Reste à payer: {remainToPay}</IonCardSubtitle>
            </IonCardHeader>

            {
                // pas de facture ou si facture mais que list des paiements vides
                !hasInvoice || (!paymentsIsLoading && payments.length === 0) ? (
                    <IonCardContent>
                        Aucun règlement associé
                    </IonCardContent>
                ) : (
                    <IonList>
                        {
                            payments.map(
                                p => {
                                    const fromFormat= 'yyyy-MM-dd HH:mm:ss';
                                    const toFormat= 'dd/MM/yyyy HH:mm:ss';
                                    const date = DateTime.fromFormat(p.date, fromFormat);

                                    const amount = currency(p.amount || 0);

                                    return (
                                        <IonItem key={p.ref} detail={false} lines={"none"}>
                                            <IonLabel>
                                                <h3>
                                                    {{
                                                        [PaymentMethodCode.CB]: 'Carte bancaire',
                                                        [PaymentMethodCode.CHQ]: 'Chèque',
                                                        [PaymentMethodCode.LIQ]: 'Espèce',
                                                        [PaymentMethodCode.PRE]: 'Prélèvement',
                                                        [PaymentMethodCode.VIR]: 'Virement',
                                                    }[p.type]}
                                                </h3>
                                                <p>{date.toFormat(toFormat)}</p>
                                            </IonLabel>
                                            <IonBadge slot="end">
                                                {amount.value} €
                                            </IonBadge>
                                        </IonItem>
                                    )
                                }
                            )
                        }
                    </IonList>
                )
            }

            <AddPaymentButton
                remainToPay={remainToPay}
                invoiceId={invoiceId}
                disabled={disabled}
            />
        </StyledIonCard>
    )
}

function AddPaymentButton({ remainToPay, invoiceId, disabled }: { remainToPay: string, invoiceId: string, disabled: boolean }) {
    return (
        <>
            <IonButton id="open-modal" fill="clear" expand={"block"} disabled={disabled}>
                <IonIcon slot={"start"} ios={addOutline} md={addSharp} />
                Saisir règlement
            </IonButton>

            <PaymentFormModal
                invoiceId={invoiceId}
                remainToPay={remainToPay}
            />
        </>
    )
}

function AddProposalLineButton({ id }: { id: string }) {
    const { refetch } = useProposalDetail(id);

    const [present, dismiss] = useIonModal(AddNewProposalLineModal, {
        proposalId: id,
        onDismiss: (data: ProposalLineFormModel, role: string) => {
            (role === 'confirm') && refetch();
            dismiss(data, role);
        },
    });

    return (
        <IonButton fill={"clear"} expand="block" onClick={() => present()}>
            <IonIcon slot="start" md={addSharp} ios={addOutline}></IonIcon>
            Ajouter une ligne
        </IonButton>
    )
}

const solarPanel = '/assets/icon/pen-to-square.svg';

function EditLineButton({ proposalId, line }: { proposalId: string, line: ProposalLine }) {
    const { refetch } = useProposalDetail(proposalId);

    const [present, dismiss] = useIonModal(EditProposalLineModal, {
        proposalId,
        line,
        onDismiss: (data: string, role: string) => {
            (role === 'confirm') && refetch();
            dismiss(data, role);
        },
    });

    return (
        <IonButton fill={"clear"} color={"medium"} onClick={() => present()}>
            <IonIcon slot="icon-only" size="small" icon={solarPanel}></IonIcon>
        </IonButton>
    )
}

function DeleteLineButton({ proposalId, lineId }: { proposalId: string, lineId: string }) {
    const [presentAlert] = useIonAlert();

    const { refetch } = useProposalDetail(proposalId);
    const { mutateAsync, isLoading } = useMutation(() => deleteProposalLine(proposalId, lineId));

    return (
        <>
            <IonButton fill={"clear"} color={"danger"}
                       onClick={() =>
                           presentAlert({
                               header: 'Suppression ligne',
                               message: 'Êtes-vous sûr de vouloir supprimer cette ligne ?',
                               buttons: [
                                   {
                                       text: 'Retour',
                                       role: 'cancel',
                                   },
                                   {
                                       text: 'OK',
                                       role: 'confirm',
                                       handler: async () => {
                                           await mutateAsync();

                                           refetch();
                                       },
                                   },
                               ],
                           })
                       }
            >
                <IonIcon slot="icon-only" size="small" md={trashSharp} ios={trashOutline}></IonIcon>
            </IonButton>
        </>
    );
}