import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";

export async function generateProposalCustomDocument(proposalId: string, documentId: string) {
    const url = Endpoint.generateProposalCustomDocument
        .replace(":proposalId", proposalId)
        .replace(":documentId", documentId)
    ;

    const { data } = await http.post(url, {}, { responseType: "blob" });

    return data;
}