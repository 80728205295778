import {Product} from "../../../models/product.model";
import {ActionComm} from "../../../models/action-comm.model";
import {User} from "../../../models/user.model";

export type PacAirEauFormModel = {
    marqueAncienneChaudiere?: string;
    product: Product;
    surfaceLogement?: string;
    totalHT: string | number;
    totalTTC: string | number;
    tvaTx: string | number;
    typeLogement: string | number;
    actions?: 'RELEVE' | 'SUPPRESSION';
    typeAncienneChaudiere?: string; // type_ancienne_chaudiere
}

export type PacAirAirFormModel = {
    products: Array<{
        product: Product,
        totalHT: string | number;
        totalTTC: string | number;
        qty: number | string,
        tvaTx: number | string,
    }>
}

export type BallonThermodynamiqueFormModel = {
    product: Product;
    totalHT: number | string;
    totalTTC: string | number;
    tvaTx: number | string;
}

export type PanneauxPhotovoltaiqueFormModel = {
    product: Product;
    qty: number | string,
    sourcePuissanceKit: string
    totalHT: number | string;
    totalTTC: string | number;
    tvaTx: number | string;
}

export type AutreMaterielFormModel = {
    product: Product;
    totalHT: number | string;
    totalTTC: string | number;
    tvaTx: number | string;
}

export enum InstallationProductType {
    pacAirAir = 'pacAirAir',
    pacAirEau = 'pacAirEau',
    ballonThermodynamique = 'ballonThermodynamique',
    panneauxPhotovoltaique = 'panneauxPhotovoltaique',
    autreMateriel = 'autreMateriel',
}

export type InstallationFormModel = {
    delaiMaximalInstallation?: number,
    products: Array<{
        type: InstallationProductType;
        totalHT: number | string,
        tvaTx: number | string,
        index: number,
        poseId: number;
    }>
}

export type ModePaiementModel = {
    montant_cee: number,
    montant_maprimerenov: number,
    vente_credit: number,
    nb_differe: number,
    prix_comptant: number,
    teag: number,
    versement_initial: number,
    montant_mensualite: number,
    nb_mensualite: number,
    cout_total: number,
    autre_moyen_paiement: string,
    acompte: number,
    montant_acompte: number;
    solde: number,
    montant_solde: number;
}

export type ContractFormModel = {
    existingCustomer?: {
        thirdPartyId: string, // Id d'un tiers existant
        thirdPartyName: string,
        city: string,
        projectId: string, // Id project si utilisation d'un tiers existant
    },
    customer: {
        civility: string,
        email: string,
        firstName: string,
        name: string,
        phone: string,
        billingAddressIsSameAsDefaultAddress: boolean; // adresse de livraison = adresse du chantier
        address: {
            country: string;
            city: string;
            postalCode: string;
            route: string;
            street: string;
            lat: number;
            lng: number;
        },
        billingAddress?: {
            country: string;
            city: string;
            postalCode: string;
            route: string;
            street: string;
            lat: number;
            lng: number;
        }
        personalMobilePhone: string;
        businessMobilePhone: string;
        origine: number,
        foireOrigine: ActionComm,
        dateProposition: string,
        docTemplate: {
            id: string,
            filename: string,
        },
        dureeValidite: number,
    },
    salesForce: {
        responsables?: User[],
        vendeurs?: User[],
        rabatteurs?: User[],
    }
    pacAirEau: PacAirEauFormModel,
    pacAirAir: PacAirAirFormModel,
    ballonThermodynamique: BallonThermodynamiqueFormModel,
    panneauxPhotovoltaique: PanneauxPhotovoltaiqueFormModel,
    autreMateriel: AutreMaterielFormModel,
    installation: InstallationFormModel,
    modePaiement: ModePaiementModel,
    observations: string,
}

