import {useProposalDetail} from "../AffaireDetail/useProposalDetail";
import {IonItem, IonLabel, IonList} from "@ionic/react";
import React from "react";

export function ProductList({proposalId}: { proposalId: string }) {
    const {isLoading, data: proposal} = useProposalDetail(proposalId);

    if (isLoading) {
        return (
            <></>
        )
    }

    const products = (proposal?.lines || []).filter(p => p.fk_product_type === '0'); // On ne filtre que les produits dans les lignes du proposal

    return (
        <IonList>
            {
                products.map(
                    (product, index) => (
                        <IonItem key={product.id}>
                            <IonLabel className="ion-text-wrap">
                                <h3>{product.product_label}</h3>
                                <p>Produit {products.length === 1 ? '' : `${(index + 1)} / ${products.length}`}</p>
                            </IonLabel>
                        </IonItem>
                    )
                )
            }
        </IonList>
    )
}