import {useInterventionDetail} from "./useInterventionDetail";
import {
    AllInterventionStatus,
    InterventionType,
    interventionUI,
    PoseTypeData,
    VisiteTechnique
} from "../../models/intervention.model";
import {
    IonBackButton,
    IonButtons,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonItem,
    IonLabel,
    IonRow,
    IonSkeletonText,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import LoadingDots from "../LoadingDots";
import InterventionStatusChip from "../Intervention/InterventionStatusChip";
import {DownloadReportButton} from "./DownloadReportButton";
import {timeOutline, timeSharp, warning} from "ionicons/icons";
import {FullWhiteCard} from "./FullWhiteCard";
import {ProductList} from "./ProductList";
import {InterventionPhotos} from "./InterventionPhotos";
import {InterventionPdfDocuments} from "./InterventionPdfDocuments";
import Page from "../../pages/Page";
import React from "react";
import {DateTime} from "luxon";
import InterventionScheduleFormModal from "../Intervention/InterventionSceduleFormModal";

interface InterventionShowProps {
    id: string;
}

export default function InterventionShow({ id }: InterventionShowProps) {
    const { isError, isFetched, isLoading, data: intervention } = useInterventionDetail(id);

    const description = isLoading ? '' : interventionUI.typeTranslation[intervention!.type as InterventionType];

    const addressText = [
        intervention?.place?.address,
        intervention?.place?.zipCode,
        intervention?.place?.town,
    ].filter(i => !!i).join(', ');

    return (
        <Page>
            <IonContent className="ion-padding">
                {
                    isLoading && (
                        <IonItem lines={"none"} color={"light"}>
                            <IonText color={'medium'} style={{ marginRight: 5 }}>
                                <span>Veuillez patienter</span>
                            </IonText>
                            <LoadingDots />
                        </IonItem>
                    )
                }

                {
                    !isLoading && (
                        <IonRow>
                            <IonCol size={'9'}>
                                <IonToolbar>
                                    <IonButtons slot="start">
                                        <IonBackButton />
                                    </IonButtons>

                                    <IonTitle className={'ion-no-padding ion-align-items-center'}>
                                        {
                                            isLoading
                                                ? (<IonSkeletonText animated={true} style={{ 'width': '80px' }} />)
                                                : <>{description} {intervention?.reference} <InterventionStatusChip status={intervention?.status} /></>
                                        }
                                    </IonTitle>

                                    <IonButtons slot="end">
                                        <DownloadReportButton
                                            interventionId={intervention!.id}
                                            reference={intervention!.reference}
                                        />
                                    </IonButtons>
                                </IonToolbar>

                                {
                                    (intervention?.status === AllInterventionStatus.CANCELLED && intervention.type === InterventionType.VISITE_TECHNIQUE) && (
                                        <IonToolbar>
                                            <IonText color="danger" style={{ display: 'flex' }} className={"ion-align-items-center`"}>
                                                <IonIcon icon={warning}></IonIcon>
                                                {(intervention as VisiteTechnique).typeData.cancellationReason}
                                            </IonText>
                                        </IonToolbar>
                                    )
                                }

                                <FullWhiteCard>
                                    <IonCardContent>
                                        <IonItem>
                                            <IonLabel className="ion-text-wrap">
                                                <h3>{intervention!.customer.name}</h3>
                                                <p>{addressText}</p>
                                                <p>Client</p>
                                            </IonLabel>
                                        </IonItem>

                                        <ScheduleItem id={id} />

                                        {
                                            intervention!.type === InterventionType.POSE && (
                                                <>
                                                    <IonGrid className= "ion-no-padding">
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonItem>
                                                                    <IonLabel>
                                                                        <h3>Début Pose</h3>
                                                                        <p>{formatDate((intervention!.typeData as PoseTypeData).theoricalStartDate)}</p>
                                                                    </IonLabel>
                                                                </IonItem>
                                                            </IonCol>
                                                            <IonCol>
                                                                <IonItem>
                                                                    <IonLabel>
                                                                        <h3>Fin Pose</h3>
                                                                        <p>{formatDate((intervention!.typeData as PoseTypeData).theoricalEndDate)}</p>
                                                                    </IonLabel>
                                                                </IonItem>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonGrid>
                                                </>
                                            )
                                        }

                                        <ProductList proposalId={intervention!.proposal.id as string}/>
                                    </IonCardContent>
                                </FullWhiteCard>

                                <InterventionPhotos
                                    id={id}
                                    customerName={intervention!.customer.name}
                                    proposalId={intervention!.proposal.id as string}
                                />

                                <InterventionPdfDocuments
                                    id={id}
                                    customerName={intervention!.customer.name}
                                    proposalId={intervention!.proposal.id as string}
                                />
                            </IonCol>
                        </IonRow>
                    )
                }
            </IonContent>
        </Page>
    )
}

const formatDate = (date: string) => DateTime.fromISO(date, { zone: "Europe/Paris" }).setLocale('fr').toFormat('dd/MM/yyyy HH:mm');

function ScheduleItem({ id }: InterventionShowProps) {
    const { isError, isFetched, isLoading, data: intervention, refetch } = useInterventionDetail(id);

    const description = isLoading ? '' : interventionUI.typeTranslation[intervention!.type as InterventionType];

    const toSchedule = intervention?.status === AllInterventionStatus.TO_SCHEDULE;

    if (isLoading) {
        return <></>;
    }

    return (
        <>
            {
                toSchedule
                    ? (
                        <>
                            <IonItem button>
                                <IonLabel className="ion-text-wrap" color={"primary"} id={`reschedule-intervention-vt-${id}`}>
                                    <h3>Assigner un technicien et choisir une date</h3>
                                    <p>Planifier l'intervention</p>
                                </IonLabel>
                                <IonIcon md={timeSharp} ios={timeOutline} slot="end" color={"primary"} />
                            </IonItem>
                            
                            <InterventionScheduleFormModal
                                id={id}
                                trigger={`reschedule-intervention-vt-${id}`}
                                onWillDismiss={() => refetch()}
                            />
                        </>
                    )
                    : (
                        <>
                            <IonItem>
                                <IonLabel className="ion-text-wrap">
                                    <h3>{intervention!.technician?.name}</h3>
                                    <p>Technicien</p>
                                </IonLabel>
                            </IonItem>

                            <IonItem>
                                <IonLabel>
                                    <h3>Date {description}</h3>
                                    <p>{formatDate(intervention!.date as string)}</p>
                                </IonLabel>
                            </IonItem>
                        </>
                    )
            }
        </>
    )
}