import {Endpoint} from "../endpoint.enum";
import {http} from "../../http";
import {Proposal} from "../../models/proposal.model";

export type ProposalLineDto = {
    label: string;
    desc: string;
    qty: number;
    subprice: number;
    remise_percent: number;
    tva_tx: number;
    product_type: '0' | '1';
    fk_product?: string;
}

export async function addProposalLine (id: number | string, dto: ProposalLineDto) {
    const url = Endpoint.proposalLines.replace(':id', `${id}`);
    const { data } = await http.post<Proposal>(url, dto);

    return data;
}