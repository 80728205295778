import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonNote
} from "@ionic/react";
import React, {useState} from "react";
import {Skeleton, styled, Typography} from "@mui/material";
import {useMutation, useQuery} from "@tanstack/react-query";
import {fetchElementDocuments} from "../../calls/Documents/fetchElementDocuments";
import {filesize} from "filesize";
import {DateTime} from "luxon";
import {downloadOutline, downloadSharp} from "ionicons/icons";
import {useActiveCompanyId} from "../Company/company.store";
import useToggleOpen from "../Common/useToggleOpen";
import {downloadDocument} from "../../calls/Documents/downloadDocument";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";

interface AttachmentsCardProps {
    elementId: string;
    elementType: 'proposal' | 'invoice', // on ne gère que ces deux là pour le moment
    label?: string;
    slots?: {
        generateButton?: React.ReactNode,
    }
}

export default function AttachmentsCard({ elementId, elementType, label = 'Documents', slots }: AttachmentsCardProps) {
    const { isLoading, data } = useQuery(["attachments", elementId], () => fetchElementDocuments({ id: elementId }, elementType, {
        sortfield: "date",
        sortorder: "DESC",
    }), {
        enabled: !!elementId,
    });

    const companyId = useActiveCompanyId();

    const { open, show, hide } = useToggleOpen();
    const [pdfData, setPdfData] = useState("");
    const handleClose = () => {
        hide();
        setPdfData("");
    }

    const { mutateAsync: download } = useMutation(async ({ originalFile }: { originalFile: string }) => {
        const { content } = await downloadDocument({
            modulepart: elementType,
            original_file: originalFile,
            entity: +companyId,
            attachment: 0,
        });

        setPdfData(content);
        show();
    });

    const documents = data || [];

    return (
        <>
            <StyledIonCard>
                <IonCardHeader style={{ display: 'flex' }} className={"ion-align-items-center"}>
                    <IonCardSubtitle>{label} ({ documents.length })</IonCardSubtitle>

                    {slots?.generateButton ? slots.generateButton : null}
                </IonCardHeader>

                <IonList>
                    {
                        isLoading ? (
                            <IonCardContent>
                                <Skeleton animation="wave" />

                                <Skeleton animation="wave" width="80%" />
                            </IonCardContent>
                        ) :  (
                            <IonList>
                                {
                                    documents.map(
                                        d => {
                                            const size = filesize(d.size);

                                            const dateText = d.date ? DateTime
                                                .fromSeconds(d.date, { zone: "Europe/Paris" })
                                                .setLocale('fr')
                                                .toFormat('dd/MM/yyyy HH:mm') : "";

                                            const originalFile = `${d.level1name}/${d.name}`;

                                            return (
                                                <IonItem key={d.name} button={true} lines={"none"} id="schedule-intervention-vt" onClick={() => download({ originalFile })}>
                                                    <IonIcon aria-hidden="true" md={downloadSharp} ios={downloadOutline} slot="start" />

                                                    <IonLabel>
                                                        <h3>{d.name}</h3>
                                                        <p>{dateText}</p>
                                                    </IonLabel>

                                                    <IonNote slot="end">{size}</IonNote>
                                                </IonItem>
                                            )
                                        }
                                    )
                                }
                            </IonList>
                        )
                    }
                </IonList>
            </StyledIonCard>

            <Dialog fullScreen={true} onClose={handleClose} open={open}>
                <AppBar color={"inherit"} sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Aperçu
                        </Typography>

                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                {
                    pdfData && (
                        <iframe
                            style={{
                                height: 'calc(100vh - 64px)'
                            }}
                            src={`data:application/pdf;base64,${pdfData}`}
                        />
                    )
                }
            </Dialog>
        </>
    )
}

const StyledIonCard = styled(IonCard)`
    --background: white;

    & ion-item, & ion-list {
        background: white;
        --background: white;
    }
`;