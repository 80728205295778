import {ThirdParty} from "../../models/third-party.model";
import {Proposal} from "../../models/proposal.model";
import {ProductTypology} from "./types";
import {useActiveCompanyId} from "../Company/company.store";
import {CreateInterventionDto} from "../../calls/Interventions/types";

export function useBuildCreateInterventionBaseDto() {
    const activeCompanyId = useActiveCompanyId();

    return (thirdParty: ThirdParty, proposal: Proposal): CreateInterventionDto => {
        const proposalId = proposal.id || '0';
        const thirdPartyId = thirdParty.id || '0';

        const typologies: ProductTypology[] = (proposal?.lines || [])
            .map(line => line.array_options?.options_product_typology as ProductTypology)
            .filter(e => !!e)
        ;

        const coords = [
            Number(thirdParty?.array_options?.options_lng || '0'), // Lng
            Number(thirdParty?.array_options?.options_lat || '0'), // Lat
        ];

        return {
            proposal: {
                id: proposalId,
                origin: proposal?.array_options?.options_origine_affaire,
                originId: proposal?.array_options?.options_foire_origine,
                productTypology: typologies[0],
            },
            customer: {
                id: thirdPartyId,
                name: thirdParty?.name || '',
            },
            place: {
                address: thirdParty?.address || '',
                town: thirdParty?.town || '',
                zipCode: thirdParty?.zip || '',
                coords
            },
            entity: activeCompanyId,
        }
    }
}

interface AddressOption {
    country: string;
    city: string;
    postalCode: string;
    route: string;
    street: string;
    lat: number;
    lng: number;
}

export function useBuildCreateInterventionBaseDtoNoThirdParty() {
    const activeCompanyId = useActiveCompanyId();

    return ({ name, address }: {
        name: string;
        address: AddressOption,
    }): any => {
        const {
            city,
            postalCode,
            street,
            lng, lat
        } = address;

        const coords = [
            Number(lng || '0'), // Lng
            Number(lat || '0'), // Lat
        ];

        return {
            customer: {
                name: name || '',
            },
            place: {
                address: street || '',
                town: city || '',
                zipCode: postalCode || '',
                coords
            },
            entity: activeCompanyId,
        }
    }
}